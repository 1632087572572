import Vue from "vue";
import VueRouter from "vue-router";
import VueMaterial from "vue-material";
import VueAnalytics from "vue-analytics";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

Vue.config.productionTip = false;
/* eslint-disable no-unused-vars */
Vue.config.errorHandler = (err, vm, info) => {
	if (process.env.NODE_ENV !== 'production') {
		// Show any error but this one
		if (err.message !== "Cannot read properties of undefined (reading 'badInput')") {
			/* eslint-disable no-console */
			console.error(err);
			/* eslint-enable no-console */
		}
	}
};

Vue.use(VueRouter);
Vue.use(VueMaterial);

import axios from "axios";

import application from "./application.vue";
import welcome from "./component/welcome.vue";

import administratorNavigation from "./component/administratorNavigation.vue";
import administratorDashboard from "./component/administratorDashboard.vue";
import administratorCompanyProfileEdit from "./component/administratorCompanyProfileEdit.vue";
import administratorBrand from "./component/administratorBrand.vue";
import administratorStaff from "./component/administratorStaff.vue";
import administratorSupplier from "./component/administratorSupplier.vue";
import administratorCustomer from "./component/administratorCustomer.vue";
import administratorMerchandise from "./component/administratorMerchandise.vue";
import administratorMerchandiseAdd from "./component/administratorMerchandiseAdd.vue";
import administratorMerchandiseEdit from "./component/administratorMerchandiseEdit.vue";
import administratorPointOfSale from "./component/administratorPointOfSale.vue";
import administratorPointOfSaleAdd from "./component/administratorPointOfSaleAdd.vue";
import administratorPointOfSaleEdit from "./component/administratorPointOfSaleEdit.vue";
import administratorLimitedRelease from "./component/administratorLimitedRelease.vue";
import administratorLimitedReleaseAdd from "./component/administratorLimitedReleaseAdd.vue";
import administratorLimitedReleaseEdit from "./component/administratorLimitedReleaseEdit.vue";
import administratorPackFormat from "./component/administratorPackFormat.vue";
import administratorAsset from "./component/administratorAsset.vue";
import administratorAssetAdd from "./component/administratorAssetAdd.vue";
import administratorAssetEdit from "./component/administratorAssetEdit.vue";
import administratorEvent from "./component/administratorEvent.vue";
import administratorEventAdd from "./component/administratorEventAdd.vue";
import administratorEventEdit from "./component/administratorEventEdit.vue";
import administratorEventSearch from "./component/administratorEventSearch.vue";
import administratorBookingAsset from "./component/administratorBookingAsset.vue";
import administratorBookingResource from "./component/administratorBookingResource.vue";
import administratorDrive from "./component/administratorDrive.vue";
import administratorDriveSearch from "./component/administratorDriveSearch.vue";
import administratorMyDrive from "./component/administratorMyDrive.vue";
import administratorMyDriveSearch from "./component/administratorMyDriveSearch.vue";
import administratorSelfService from "./component/administratorSelfService.vue";
import administratorOrderHistory from "./component/administratorOrderHistory.vue";
import administratorReport from "./component/administratorReport.vue";
import administratorNotification from "./component/administratorNotification.vue";
import administratorRegion from "./component/administratorRegion.vue";
import administratorProductView from "./component/administratorProductView.vue"; 
import administratorInventoryReport from "./component/administratorInventoryReport.vue";
import administratorUserGroup from "./component/administratorUserGroup.vue";

import administratorCategory from "./component/administratorCategory.vue";
import administratorPriority from "./component/administratorPriority.vue";
import administratorPointOfSaleAddSW from "./component/administratorPointOfSaleAddSW.vue";
import administratorPointOfSaleEditSW from "./component/administratorPointOfSaleEditSW.vue";
import administratorMerchandiseAddSW from "./component/administratorMerchandiseAddSW.vue";
import administratorMerchandiseEditSW from "./component/administratorMerchandiseEditSW.vue";

import administratorCocktail from "./component/administratorCocktail.vue";
import administratorCocktailAdd from "./component/administratorCocktailAdd.vue";
import administratorCocktailEdit from "./component/administratorCocktailEdit.vue";

import administratorMenuImage from "./component/administratorMenuImage.vue";
import administratorMenuImageAdd from "./component/administratorMenuImageAdd.vue";
import administratorMenuImageEdit from "./component/administratorMenuImageEdit.vue";

import administratorVenue from "./component/administratorVenue.vue";

import salesMenuBuilder from "./component/salesMenuBuilder.vue";

import salesNavigation from "./component/salesNavigation.vue";
import salesDashboard from "./component/salesDashboard.vue";
import salesBrand from "./component/salesBrand.vue";
import salesCustomer from "./component/salesCustomer.vue";
import salesProductBrand from "./component/salesProductBrand.vue";
import salesProductBrandCategory from "./component/salesProductBrandCategory.vue";
import salesProductView from "./component/salesProductView.vue";
import salesEvent from "./component/salesEvent.vue";
import salesEventAdd from "./component/salesEventAdd";
import salesEventEdit from "./component/salesEventEdit";
import salesEventView from "./component/salesEventView.vue";
import salesEventSearch from "./component/salesEventSearch.vue";
import salesAsset from "./component/salesAsset.vue";
import salesAssetView from "./component/salesAssetView.vue";
import salesSelfService from "./component/salesSelfService.vue";
import salesBookingAsset from "./component/salesBookingAsset.vue";
import salesBookingResource from "./component/salesBookingResource.vue";
import salesDrive from "./component/salesDrive.vue";
import salesDriveSW from "./component/salesDriveSW.vue";
import salesDriveSearch from "./component/salesDriveSearch.vue";
import salesDriveSearchSW from "./component/salesDriveSearchSW.vue";
import salesMyDrive from "./component/salesMyDrive.vue";
import salesMyDriveSearch from "./component/salesMyDriveSearch.vue";
import salesCart from "./component/salesCart.vue";
import salesOrderHistory from "./component/salesOrderHistory.vue";
import salesNotification from "./component/salesNotification.vue";
import salesDraft from "./component/salesDraft.vue";
import salesDraftBrand from "./component/salesDraftBrand.vue";
import salesCategory from "./component/salesCategory.vue";

import salesVenue from "./component/salesVenue.vue";

import store from './plugin/store'


const router = new VueRouter({
	routes: [
		{ path: "/", component: welcome, props: true },
		{
			path: "",
			component: administratorNavigation,
			props: true,
			children: [
				{ path: "/administrator/dashboard", component: administratorDashboard, props: true },
				{ path: "/administrator/company/profile/edit", component: administratorCompanyProfileEdit, props: true },
				{ path: "/administrator/company/:entityId/brand", component: administratorBrand, props: true },
				{ path: "/administrator/company/:entityId/staff", component: administratorStaff, props: true },
				{ path: "/administrator/supplier", component: administratorSupplier, props: true },
				{ path: "/administrator/customer", component: administratorCustomer, props: true },
				{ path: "/administrator/merchandise", component: administratorMerchandise, props: true },
				{ path: "/administrator/merchandise/add", component: administratorMerchandiseAdd, props: true },
				{ path: "/administrator/merchandise/edit/:entityId", component: administratorMerchandiseEdit, props: true },
				{ path: "/administrator/pointofsale", component: administratorPointOfSale, props: true },
				{ path: "/administrator/pointofsale/add", component: administratorPointOfSaleAdd, props: true },
				{ path: "/administrator/pointofsale/edit/:entityId", component: administratorPointOfSaleEdit, props: true },
				{ path: "/administrator/limitedrelease", component: administratorLimitedRelease, props: true },
				{ path: "/administrator/limitedrelease/add", component: administratorLimitedReleaseAdd, props: true },
				{ path: "/administrator/limitedrelease/edit/:entityId", component: administratorLimitedReleaseEdit, props: true },
				{ path: "/administrator/usergroup", component: administratorUserGroup, props: true },

				{ path: "/administrator/cocktail", component: administratorCocktail, props: true },
				{ path: "/administrator/cocktail/add", component: administratorCocktailAdd, props: true },
				{ path: "/administrator/cocktail/edit/:entityId", component: administratorCocktailEdit, props: true },

				{ path: "/administrator/menuimage", component: administratorMenuImage, props: true },
				{ path: "/administrator/menuimage/add", component: administratorMenuImageAdd, props: true },
				{ path: "/administrator/menuimage/edit/:entityId", component: administratorMenuImageEdit, props: true },

				{ path: "/administrator/packformat", component: administratorPackFormat, props: true },
				{ path: "/administrator/asset", component: administratorAsset, props: true },
				{ path: "/administrator/asset/add", component: administratorAssetAdd, props: true },
				{ path: "/administrator/asset/edit/:entityId", component: administratorAssetEdit, props: true },
				{ path: "/administrator/event", component: administratorEvent, props: true },
				{ path: "/administrator/event/add", component: administratorEventAdd, props: true },
				{ path: "/administrator/event/edit/:entityId", component: administratorEventEdit, props: true },
				{ path: "/administrator/event/search", component: administratorEventSearch, props: true },
				{ path: "/administrator/booking/asset", component: administratorBookingAsset, props: true },
				{ path: "/administrator/booking/resource", component: administratorBookingResource, props: true },
				{ path: "/administrator/drive/:path", component: administratorDrive, props: true },
				{ path: "/administrator/mydrive/:path", component: administratorMyDrive, props: true },
				{ path: "/administrator/search/drive", component: administratorDriveSearch, props: true },
				{ path: "/administrator/search/mydrive", component: administratorMyDriveSearch, props: true },
				{ path: "/administrator/order", component: administratorOrderHistory, props: true },
				{ path: "/administrator/selfservice", component: administratorSelfService, props: true },
				{ path: "/administrator/report", component: administratorReport, props: true },
				{ path: "/administrator/notification", component: administratorNotification, props: true },
				{ path: "/administrator/region", component: administratorRegion, props: true }, 
				{ path: "/administrator/:producttype/view/:entityId", component: administratorProductView, props: true }, 
				{ path: "/administrator/inventoryreport/:entityId", component: administratorInventoryReport, props: true }, 
				{ path: "/administrator/category", component: administratorCategory, props: true },
				{ path: "/administrator/priority", component: administratorPriority, props: true },
				{ path: "/administrator/venue", component: administratorVenue, props: true},
				// Stone and Wood Custom Page
				{ path: "/administrator/pointofsale/sw/add", component: administratorPointOfSaleAddSW, props: true }, 
				{ path: "/administrator/pointofsale/sw/edit/:entityId", component: administratorPointOfSaleEditSW, props: true },   
				{ path: "/administrator/merchandise/sw/add", component: administratorMerchandiseAddSW, props: true }, 
				{ path: "/administrator/merchandise/sw/edit/:entityId", component: administratorMerchandiseEditSW, props: true },   
			]
		},
		{
			path: "",
			component: salesNavigation,
			props: true,
			children: [
				{ path: "/sales/dashboard", component: salesDashboard, props: true },
				{ path: "/sales/brand/:productType", component: salesBrand, props: true },
				{ path: "/sales/customer", component: salesCustomer, props: true },
				{ path: "/sales/product/:productType/brand/:brandId", component: salesProductBrand, props: true },
				{ path: "/sales/product/:productType/brand/:brandId/category/:categoryId", component: salesProductBrandCategory, props: true },
				{ path: "/sales/merchandise/view/:entityId", component: salesProductView, props: true },
				{ path: "/sales/limitedrelease/view/:entityId", component: salesProductView, props: true },
				{ path: "/sales/pointofsale/view/:entityId", component: salesProductView, props: true },
				{ path: "/sales/event", component: salesEvent, props: true },
				{ path: "/sales/event/add", component: salesEventAdd, props: true },
				{ path: "/sales/event/edit/:entityId", component: salesEventEdit, props: true },
				{ path: "/sales/event/view/:entityId", component: salesEventView, props: true },
				{ path: "/sales/event/search", component: salesEventSearch, props: true },
				{ path: "/sales/asset", component: salesAsset, props: true },
				{ path: "/sales/asset/view/:entityId", component: salesAssetView, props: true },
				{ path: "/sales/menuimage/view/:entityId", component: salesMenuBuilder, props: true },
				{ path: "/sales/booking/asset", component: salesBookingAsset, props: true },
				{ path: "/sales/booking/resource", component: salesBookingResource, props: true },
				{ path: "/sales/drive/:path", component: salesDrive, props: true },
				{ path: "/sales/mydrive/:path", component: salesMyDrive, props: true },
				{ path: "/sales/search/drive", component: salesDriveSearch, props: true },
				{ path: "/sales/search/mydrive", component: salesMyDriveSearch, props: true },
				{ path: "/sales/cart", component: salesCart, props: true },
				{ path: "/sales/order", component: salesOrderHistory, props: true },
				{ path: "/sales/selfservice", component: salesSelfService, props: true },
				{ path: "/sales/draft", component: salesDraft, props: true },
				{ path: "/sales/draft/:productType/brand/:brandId", component: salesDraftBrand, props: true },
				{ path: "/sales/menubuilder", component: salesMenuBuilder, props: true },
				{ path: "/sales/notification", component: salesNotification, props: true },
				{ path: "/sales/category/:productType/brand/:brandId", component: salesCategory, props: true},
				{ path: "/sales/venue", component: salesVenue, props: true},
				// Stone and Wood Custom Page
				{ path: "/sales/drive/sw/:brandId/:path", component: salesDriveSW, props: true },
				{ path: "/sales/search/drive/sw/:brandId", component: salesDriveSearchSW, props: true }
			]
		}
	]
});

var sentryKey = "https://a969403deaae4437bccfcf55c4c6b9ba@sentry.io/1864501";
var sendHitTask = false;
if (window.location.origin.includes("reptool.stoneandwood.com.au") || window.location.origin.includes("stonewood.pontoonx.io")) {
	sentryKey = "https://fb1e613549cd4ad18a49805164d08153@sentry.io/1864524";
	sendHitTask = true;
}

Vue.use(VueAnalytics, {
	id: "UA-151634809-3",
	router,
	debug: {
		sendHitTask: sendHitTask
	}
});

axios.defaults.baseURL = "";

if (window.location.origin.includes("localhost")) {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = "http://localhost:8900/pontoonx-sw";
}

Vue.prototype.$window = window;
Vue.material.locale.dateFormat = "dd/MM/yyyy";

if (!window.location.origin.includes("localhost")) {
	Sentry.init({
		dsn: sentryKey,
		integrations: [new Integrations.Vue({ Vue, attachProps: true })]
	});
}

/* eslint-disable */
new Vue({
	router,
	store,
	render: display => display(application),
	data() {
		return {
			loader: { count: 0, stroke: 7, diameter: 50, value: false },
			colorList: [
				"rgb(255, 99, 132)",
				"rgb(255, 159, 64)",
				"rgb(255, 205, 86)",
				"rgb(75, 192, 192)",
				"rgb(54, 162, 235)",
				"rgb(153, 102, 255)",
				"rgb(201, 203, 207)",
				"#49b7e5",
				"#AC193D",
				"#FFEC47",
				"#82BA00",
				"#8C0095",
				"#FF8F32",
				"#DC4FAD",
				"#03B3B2",
				"#570000",
				"#aaa",
				"#D24726",
				"#004B8B",
				"#008A17",
				"#FFFF51",
				"#9E5EFF"
			],
			monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			paperList: ["A0", "A1", "A2", "A3", "A4", "A5", "DL", "TentCard"]
		};
	},
	created: function() {
		axios.interceptors.request.use(
			request => {
				this.load();
				return request;
			},
			error => {
				this.unload();
				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			response => {
				this.unload();
				return response;
			},
			error => {
				this.unload();
				return Promise.reject(error);
			}
		);
	},
	methods: {
		logSuccess: function(o) {
			// console.log('>> Success: ' + JSON.stringify(o.data));
		},
		logError: function(o) {
			// console.log('>> Error: ' + JSON.stringify(o.response.data));
			Sentry.captureMessage(JSON.stringify(o.response.data));
		},
		showSnack: function(snack, content) {
			snack.content = content;
			snack.show = true;
		},
		showNotification: function(dialog, response) {
			if (response) {
				dialog.title = "Notification (" + response.status + ")";
				dialog.content = response.data;
			} else {
				dialog.title = "Unknown error";
			}
			dialog.show = true;
		},
		existInList(list, property, id) {
			for (let i in list) {
				let item = list[i];
				if (item[property] === id) {
					return true;
				}
			}
			return false;
		},
		encodeUrl(path) {
			let array = path.split("/");
			for (let i in array) {
				array[i] = encodeURIComponent(array[i]);
			}
			return array.join("/");
		},
		encodePathName(path) {
			let encodedPath = "";
			let codecCharacter = "^";
			for (let q of path) {
				if ((q >= "a" && q <= "z") || (q >= "A" && q <= "Z") || (q >= "0" && q <= "9") || q == " " || q == "_" || q == "." || q == "-" || q == ":") {
					encodedPath += q;
				} else {
					encodedPath += codecCharacter + q.charCodeAt(0).toString(16) + codecCharacter;
				}
			}
			return encodedPath;
		},
		load() {
			this.loader.count++;
			this.loader.value = true;
		},
		unload() {
			this.loader.count--;
			if (this.loader.count < 0) {
				this.loader.count = 0;
			}
			this.loader.value = this.loader.count > 0;
		},
		downloadImage(name) {
			location.href = axios.defaults.baseURL + "/asset/file/" + name + "?download&time=" + new Date();
		},
		ellipsify(text, limit) {
			if (text && text.length > limit) {
				return text.substring(0, limit) + "...";
			}
			return text;
		},
		tenantId() {
			let origin = window.location.origin;
			// stonewood-test.pontoonx.io
			// reptool.stoneandwood.com.au
			// stonewood.pontoonx.io
			if (origin.includes("stonewood") || origin.includes("stoneandwood")) {
				return 1;
			}
			// campari-test.pontoonx.io
			// cmapari.pontoonx.io
			if (origin.includes("campari")) {
				return 2;
			}
			// demo.pontoonx.io
			if (origin.includes("demo")) {
				return 3;
			}
			// duo-test.pontoonx.io
			// duo.pontoonx.io
			if (origin.includes("duo")) {
				return 5;
			}
			// fizz-test.pontoonx.io
			if (origin.includes("fizz")) {
				return 6;
			}
			// cub-test.pontoonx.io
			if (origin.includes("cub")) {
				return 7;
			}
			// set this to the one you are testing in local
			if (origin.includes("localhost")) {
				return 1;
			}
			return 1;
		},
		tenantLogoFile(page) {
			let id = this.tenantId();
			if (id == 2) {
				if (page == "menu") {
					return "logo-menu-tenant-2.png";
				}
				return "logo-tenant-2.png";
			} else if (id == 6 || id == 7) {
				return "logo-tenant-" + id + ".jpg";
			}
			return "logo-tenant-" + id + ".svg";
		},
		tenantCustomisation(customisation) {
			let id = this.tenantId();
			if (id == 1) {
				if (
					customisation == "editable-slug" || //
					customisation == "slug-replication" || //
					customisation == "inventory-dl" || //
					customisation == "inventory-tent-card" || //
					customisation == "draft" || //
					customisation == "print-2-sided" || //
					customisation == "resubmission" || //
					customisation == "priority-product" || //
					customisation == "low-stock-alert" || //
					customisation == "custom-pos-type" || //
					customisation == "auto-approve-switch" || //
					customisation == "lion-charge-code" || //
					customisation == "volume-tracker"
				) {
					return true;
				}
			} else if (id == 2) {
				if (
					customisation == "restrict-download" || //
					customisation == "preferred-printer-email" || //
					customisation == "editable-slug" || //
					customisation == "slug-replication" || //
					customisation == "inventory-dl" || //
					customisation == "inventory-tent-card" || //
					customisation == "product-region" || //
					customisation == "draft" || //
					customisation == "print-2-sided" || //
					customisation == "menu-image" || //
					customisation == "resubmission" || //
					customisation == "sales-note"
				) {
					return true;
				}
			} else if (id == 3 || id == 5) {
				if (
					customisation == "restrict-download" || //
					customisation == "preferred-printer-email" || //
					customisation == "editable-slug" || //
					customisation == "slug-replication" || //
					customisation == "inventory-dl" || //
					customisation == "inventory-tent-card" || //
					customisation == "product-region" || //
					customisation == "draft" || //
					customisation == "resubmission" //
				) {
					return true;
				}
			} else if (id == 6 || id == 7) {
				if (
					customisation == "editable-slug" || //
					customisation == "inventory-dl" || //
					customisation == "inventory-tent-card" || //
					customisation == "draft" || //
					customisation == "resubmission" //
				) {
					return true;
				}
			}
			return false;
		},
		clone(o) {
			return JSON.parse(JSON.stringify(o));
		},
		zeroPad(number) {
			if (number < 10) {
				number = "0" + number;
			}
			return number;
		},
		formatJavaScriptDateTime(instant) {
			return (
				instant.getDate() +
				" " + //
				this.monthList[instant.getMonth()] +
				" " + //
				instant.getFullYear() +
				" " + //
				this.zeroPad(instant.getHours()) +
				":" + //
				this.zeroPad(instant.getMinutes()) +
				":" + //
				this.zeroPad(instant.getSeconds())
			);
		},
		coalesce(a, b) {
			if (a) {
				return a;
			}
			return b;
		},
		coalescify(value, prefix, suffix) {
			if (value) {
				return prefix + value + suffix;
			}
			return '';
		},
		isPaperVariant(variant) {
			return this.paperList.includes(variant);
		}
	}
}).$mount("#application");
