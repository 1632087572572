<template>
	<div>
		<merchandise-add>
			<template #category="slotProps">
				<md-field>
					<label for="selectCategory">Asset</label>
					<md-select v-model="slotProps.entity.categoryId" md-dense id="selectCategory">
						<template v-for="item in categoryList">
							<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
						</template>
					</md-select>
				</md-field>
			</template>
		</merchandise-add>
	</div>
</template>

<script>
import ajax from "axios";
import merchandiseAdd from "./administratorMerchandiseAdd.vue";

export default {
	components: {
		merchandiseAdd
	},
	data() {
		return {
			categoryList: []
		};
	},
	mounted: function () {
		this.listCategory();
	},
	methods: {
		listCategory() {
			let vm = this;
			ajax.get("/system/category/list")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.categoryList = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>