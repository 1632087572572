import { render, staticRenderFns } from "./administratorMyDrive.vue?vue&type=template&id=c51a087a&scoped=true"
import script from "./administratorMyDrive.vue?vue&type=script&lang=js"
export * from "./administratorMyDrive.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c51a087a",
  null
  
)

export default component.exports