<template>
	<div>
		<div class="md-title">Create new Limited Release</div>

		<md-field>
			<label>Offer name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Offer description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<md-field>
			<label>Brand</label>
			<md-select v-model="entity.brandId" md-dense id="selectBrand">
				<template v-for="item in limitedReleaseBrandList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-datepicker v-model="notifyDate" md-immediately>
			<label>Notify date</label>
		</md-datepicker>

		<md-datepicker v-model="dueDate" md-immediately>
			<label>Due date</label>
		</md-datepicker>

		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 1</label>
			<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 2</label>
			<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Inventory Type</label>
			<md-select v-model="entity.inventoryType" md-dense id="selectInventoryType" disabled>
				<template v-for="item in inventoryTypeList">
					<md-option :key="item" :value="item">{{item}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-field>
			<label>Pack Format</label>
			<md-select v-model="entity.packFormat" md-dense id="selectPackFormat">
				<template v-for="item in packFormatList">
					<md-option :key="item.id" :value="item.unitVolume + 'ml × ' + item.cartonSize + ' (Cluster: ' + item.clusterSize + ')'">{{item.unitVolume}}ml × {{item.cartonSize}} (Cluster: {{item.clusterSize}})</md-option>
				</template>
			</md-select>
		</md-field>

		<template v-if="entity.inventoryType == 'KegCarton'">
			<md-field>
				<label>Keg</label>
				<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityKeg"></md-input>
			</md-field>

			<md-field>
				<label>Carton</label>
				<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityCarton"></md-input>
			</md-field>
		</template>

		<br />
		<div class="md-subheading">Allocation</div>

		<md-table>
			<md-table-row>
				<md-table-head>Name</md-table-head>
				<md-table-head>
					Role
					<select v-model="selectedRole" id="inputSelectRole" style="margin:0 10px;">
						<option value>All</option>
						<option v-for="role in personRoleList" :value="role" :key="role">{{role.replace(/_/g,' ')}}</option>
					</select>
				</md-table-head>
				<md-table-head>Manager</md-table-head>
				<md-table-head>Keg</md-table-head>
				<md-table-head>Carton</md-table-head>
			</md-table-row>
			<md-table-row v-for="entity in filteredPersonList()" :key="entity.id">
				<md-table-cell>{{entity.name}}</md-table-cell>
				<md-table-cell>{{entity.role && entity.role.replace(/_/g, ' ')}}</md-table-cell>
				<md-table-cell>{{entity.dataMap.managerName}}</md-table-cell>
				<md-table-cell>
					<input type="text" v-model="entity.dataMap.keg" size="3" class="box" />
				</md-table-cell>
				<md-table-cell>
					<input type="text" v-model="entity.dataMap.carton" size="3" class="box" />
				</md-table-cell>
			</md-table-row>
			<md-table-row>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell>Total/Remaining</md-table-cell>
				<md-table-cell>{{totalKeg}}</md-table-cell>
				<md-table-cell>{{totalCarton}}</md-table-cell>
			</md-table-row>
		</md-table>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/limitedrelease">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileAdd()">Add</md-button>
		</md-dialog-actions>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
select {
	padding: 2px;
	font-size: 12px;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entity: {
				type: "LimitedRelease",
				imageDataList: [],
				inventoryType: null,
				inventoryDataList: [],
				dataMap: {
					inventory: [{}, {}, {}, {}, {}, {}],
					packFormatId: null
				}
			},
			dueDate: null,
			notifyDate: null,
			inventoryTypeList: [],
			packFormatList: [],
			personList: [],
			brandList: [],
			attachment: null,
			attachment1: null,
			attachment2: null,
			selectedRole: "",
			personRoleList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	computed: {
		totalKeg: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.keg;
				if (e) {
					total += Number(e);
				}
			}
			if (this.entity.dataMap.inventory[0].quantity) {
				total = total + "/" + (this.entity.dataMap.inventory[0].quantity - total);
			}
			return total;
		},
		totalCarton: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.carton;
				if (e) {
					total += Number(e);
				}
			}
			if (this.entity.dataMap.inventory[1].quantity) {
				total = total + "/" + (this.entity.dataMap.inventory[1].quantity - total);
			}
			return total;
		},
		limitedReleaseBrandList: function() {
			var vm = this;
			return vm.brandList.filter(function(brand) {
				return brand.limitedReleaseBrand;
			});
		}
	},
	mounted: function() {
		this.listBrand();
		this.listInventoryType();
		this.listPackFormat();
		this.listPerson();
		this.$material.locale.dateFormat = "dd/MM/yyyy";
		this.listPersonRole();
	},
	methods: {
		listBrand() {
			let vm = this;
			ajax.get("/system/brand/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listInventoryType() {
			let vm = this;
			ajax.get("/system/enumeration/inventorytype/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.inventoryTypeList = response.data;

					vm.entity.inventoryType = "KegCarton";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPackFormat() {
			let vm = this;
			ajax.get("/system/packformat/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.packFormatList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPerson() {
			let vm = this;
			ajax.get("/system/person/list?action=allocatable")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		submitFileAdd() {
			let vm = this;
			let formData = new FormData();

			if (vm.notifyDate) {
				vm.entity.dataMap.notifyDate = vm.notifyDate.getFullYear() + "-" + (vm.notifyDate.getMonth() + 1) + "-" + vm.notifyDate.getDate();
			}
			if (vm.dueDate) {
				vm.entity.dataMap.dueDate = vm.dueDate.getFullYear() + "-" + (vm.dueDate.getMonth() + 1) + "-" + vm.dueDate.getDate();
			}

			formData.append("entity", JSON.stringify(vm.entity));
			formData.append("allocation", JSON.stringify(vm.personList));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			ajax.post("/system/product/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/limitedrelease" });
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPersonRole() {
			let vm = this;
			ajax.get("/system/enumeration/personrole/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personRoleList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		filteredPersonList() {
			if (this.selectedRole && this.selectedRole != "") {
				let list = [];
				for (let i in this.personList) {
					let person = this.personList[i];
					if (person.role == this.selectedRole) {
						list.push(person);
					}
				}
				return list;
			} else {
				return this.personList;
			}
		}
	}
};
</script>
