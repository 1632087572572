<template>
	<div>
		<div class="md-title">Notification</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-table md-card>
			<md-table-toolbar>
				<div style="margin-right:10px;">Notification limit: {{!maximumNotification ? "Unlimited" : maximumNotification}}</div>
				<md-field style="width:100px;">
					<label>New limit</label>
					<md-input v-model="newMaximumNotification" id="inputMaximumNotification" v-on:keyup.enter="submitMaximumNotification()" type="number"></md-input>
				</md-field>
				<md-button class="md-raised" @click="submitMaximumNotification()">Set new limit</md-button>
			</md-table-toolbar>

			<md-table-row>
				<md-table-head>Content</md-table-head>
				<md-table-head>Seen</md-table-head>
				<md-table-head></md-table-head>
			</md-table-row>

			<md-table-row v-for="item in entityList" :key="item.id">
				<md-table-cell :class="item.viewed != null ? 'Inactive' : 'Active'">{{ item.content }}</md-table-cell>
				<md-table-cell md-label="Seen" :class="item.viewed != null ? 'Inactive' : 'Active'">{{ item.dataMap.viewedText }}</md-table-cell>
				<md-table-cell md-label>
					<md-button class="md-icon-button md-raised md-dense" @click="deleteNotification(item)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<!-- <div v-if="!$root.loader.value && entityList.length == 0">Empty list</div> -->

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			entity: { active: false, dataMap: {} },
			entityList: [],
			maximumNotification: null,
			newMaximumNotification: null,
			pagination: { page: 1, pageSize: 100, pageLast: 1 },
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		update() {
			setTimeout(() => {
				let vm = this;
				let listNotification = [];
				for (let notification of vm.entityList) {
					if (notification.viewed == null) {
						listNotification.push(notification);
					}
				}
				if (listNotification.length > 0) {
					ajax.post("/system/notification/person/update", {
						entityList: listNotification.reverse()
					})
						.then(response => {
							vm.$root.logSuccess(response);
							// vm.list();
						})
						.catch(error => {
							vm.$root.logError(error);
							vm.$root.showNotification(vm.notification, error.response);
						});
				}
			}, 12000);
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/notification/person/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data.notificationList;
					vm.maximumNotification = response.data.maximumNotification;
					vm.$forceUpdate();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/notification/person/search", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		deleteNotification(item) {
			let vm = this;
			ajax.post("/system/notification/person/delete", {
				id: item.id
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		submitMaximumNotification() {
			let vm = this;
			if (vm.newMaximumNotification > 0) {
				ajax.post("/system/notification/person/maximumnotification/edit", {
					maximumNotification: vm.newMaximumNotification
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.list();
						vm.newMaximumNotification = "";
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else {
				vm.$root.showSnack(vm.snack, "Maximum notification limit must be greater than 0.");
			}
		}
	},
	created() {
		this.update();
	}
};
</script>