<template>
	<div>
		<div class="md-title">Customer</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense md-primary" @click="entityUpload.result = null;dialogUpload.show = true" title="Upload customer file" v-if="$root.tenantCustomisation('lion-charge-code')">
				<md-icon>file_upload</md-icon>
			</md-button>
		</md-field>

		<table style="margin:10px 0 5px 0;">
			<tr>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageFirst()">
						<md-icon>first_page</md-icon>
					</md-button>
				</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pagePrevious()">
						<md-icon>chevron_left</md-icon>
					</md-button>
				</td>
				<td>Page {{pagination.page}}</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageNext()">
						<md-icon>chevron_right</md-icon>
					</md-button>
				</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageLast()">
						<md-icon>last_page</md-icon>
					</md-button>
				</td>
			</tr>
		</table>

		<md-list>
			<md-list-item v-for="entity in paginatedEntityList()" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span :class="entity.status">
						{{entity.name}}
						<template v-if="entity.accountId">
							<small> - {{entity.accountId}}</small>
						</template>
						<template v-if="(entity.addressDataList && entity.addressDataList.length > 0)">
							<small> - {{entity.addressDataList[0].city}} {{entity.addressDataList[0].state}}</small>
						</template>
						<template v-if="entity.segment">
							<small>({{entity.segment}})</small>
						</template>
					</span>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
			</md-field>

			<table>
				<tr>
					<td>
						<md-field>
							<label>Sales Representative Name</label>
							<md-input v-model="entityAdd.dataMap.salesPersonName" id="inputAddSalesPersonName"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Customer Number</label>
							<md-input v-model="entityAdd.accountId" id="inputAddCustomerNumber"></md-input>
						</md-field>
					</td>
				</tr>
			</table>

			<md-field>
				<label>Segment</label>
				<md-input v-model="entityAdd.segment" id="inputAddSegment"></md-input>
				<span class="md-helper-text">e.g. Pointy End, Quality and Contemporary, Mainly Mainstream</span>
			</md-field>

			<md-field>
				<label>Phone Number</label>
				<md-input v-model="entityAdd.phone" id="inputAddPhone" v-on:keypress="isPhone($event, entityAdd.phone)"></md-input>
			</md-field>

			<md-list>
				<md-list-item v-for="entity in entityAdd.addressDataList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<div class="md-list-item-text">
						<span>
							{{entity.address}}
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							{{entity.note}}
						</span>
					</div>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityAdd, entity.id)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>

			<md-card style="padding:0px 10px">
				<table class="layout">
					<tr>
						<td>
							<md-field>
								<label>Address</label>
								<md-input v-model="newAddress.address" id="inputAddAddressAddress"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>City</label>
								<md-input v-model="newAddress.city" id="inputAddAddressCity"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>State</label>
								<md-input v-model="newAddress.state" id="inputAddAddressState"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Postcode</label>
								<md-input v-model="newAddress.postcode" id="inputAddAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4">
							<md-field>
								<label>Address note</label>
								<md-textarea v-model="newAddress.note" id="inputAddAddressNote" md-autogrow></md-textarea>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4" align="right">
							<md-button class="md-raised md-dense md-primary" @click="addAddress(entityAdd)">Add address</md-button>
						</td>
					</tr>
				</table>
			</md-card>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
			</md-field>

			<md-switch v-model="entityEdit.active" class="md-primary" id="inputEditStatus">Active</md-switch>

			<md-field>
				<label>Sales Representative Name</label>
				<md-input v-model="entityEdit.dataMap.salesPersonName" id="inputEditSalesPersonName"></md-input>
			</md-field>

			<md-field>
				<label>Customer Number</label>
				<md-input v-model="entityEdit.accountId" id="inputEditCustomerNumber"></md-input>
			</md-field>

			<md-field>
				<label>Segment</label>
				<md-input v-model="entityEdit.segment" id="inputEditSegment"></md-input>
				<span class="md-helper-text">e.g. Pointy End, Quality and Contemporary, Mainly Mainstream</span>
			</md-field>

			<md-field>
				<label>Phone Number</label>
				<md-input v-model="entityEdit.phone" id="inputEditPhone" v-on:keypress="isPhone($event, entityEdit.phone)"></md-input>
			</md-field>

			<md-list>
				<md-list-item v-for="entity in entityEdit.addressDataList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<div class="md-list-item-text">
						<span>
							{{entity.address}}
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							{{entity.note}}
						</span>
					</div>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityEdit, entity.id)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>

			<md-card style="padding:0px 10px">
				<table class="layout">
					<tr>
						<td>
							<md-field>
								<label>Address</label>
								<md-input v-model="newAddress.address" id="inputEditAddressAddress"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>City</label>
								<md-input v-model="newAddress.city" id="inputEditAddressCity"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>State</label>
								<md-input v-model="newAddress.state" id="inputEditAddressState"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Postcode</label>
								<md-input v-model="newAddress.postcode" id="inputEditAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4">
							<md-field>
								<label>Address note</label>
								<md-textarea v-model="newAddress.note" id="inputEditAddressNote" md-autogrow></md-textarea>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4" align="right">
							<md-button class="md-raised md-dense md-primary" @click="addAddress(entityEdit)">Add address</md-button>
						</td>
					</tr>
				</table>
			</md-card>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<md-dialog :md-active.sync="dialogAddAddress.show">
			<md-dialog-title>{{dialogAddAddress.title}}</md-dialog-title>

			<md-content>{{dialogAddAddress.missing}}</md-content>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAddAddress.show = false; dialogAddAddress.missing =''">Close</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogUpload.show" style="min-width:600px;">
			<md-dialog-title>{{dialogUpload.title}}</md-dialog-title>

			<md-field>
				<label>CSV customer file</label>
				<md-file @md-change="handleFileUpload" id="inputAttachment" accept="text/csv" />
			</md-field>

			<md-switch v-model="entityUpload.doInsert" class="md-primary" id="inputUploadInsert">Insert if doesn't exist in database</md-switch>
			<md-switch v-model="entityUpload.doUpdate" class="md-primary" id="inputUploadUpdate">Update if name is different</md-switch>
			<md-switch v-model="entityUpload.doUpdateAddress" class="md-primary" id="inputUploadUpdateAddress">Update address if address is different</md-switch>
			<md-switch v-model="entityUpload.doDelete" class="md-primary" id="inputUploadDelete">Delete if doesn't exist in file (are you sure?)</md-switch>

			<div>{{ entityUpload.result }}</div>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogUpload.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="uploadFile()">Upload</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new customer" },
			dialogEdit: { show: false, title: "Edit customer" },
			dialogDelete: {
				show: false,
				title: "Delete customer",
				content: "Are you sure you want to delete this customer?"
			},
			entityAdd: {
				dataMap: {},
				addressDataList: []
			},
			entityEdit: { active: false, dataMap: {} },
			entityList: [],
			id: 1,
			newAddress: {},
			pagination: { page: 1, pageSize: 100, pageLast: 1 },
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			dialogAddAddress: { show: false, title: "Address is not complete", missing: "" },
			dialogUpload: {
				show: false,
				title: "Upload customer file"
			},
			entityUpload: {
				doInsert: true,
				doUpdate: true,
				doUpdateAddress: true,
				doDelete: false,
				result: ''
			},
			attachment: null
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/company/list?type=Customer&action=lean")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
					vm.pageCount();
					vm.pageReset();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/company/search?type=Customer", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
						vm.pageCount();
						vm.pageReset();
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.entityAdd = {
				dataMap: {},
				addressDataList: []
			};
			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit = {
				id: item.id,
				name: item.name,
				active: "Active" === item.status,
				notifiable: item.notifiable,
				addressDataList: item.addressDataList,
				accountId: item.accountId,
				segment: item.segment,
				phone: item.phone,
				dataMap: {
					salesPersonName: item.dataMap && item.dataMap.salesPersonName
				}
			};
			if (!vm.entityEdit.addressDataList) {
				vm.entityEdit.addressDataList = [];
			} else {
				for (let i in vm.entityEdit.addressDataList) {
					vm.entityEdit.addressDataList[i].id = vm.id++;
				}
			}
			vm.dialogEdit.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/company/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			vm.entityAdd.type = "Customer";
			ajax.post("/system/company/add", {
				entity: vm.entityAdd
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			vm.entityEdit.status = vm.entityEdit.active ? "Active" : "Inactive";
			ajax.post("/system/company/edit", {
				entity: vm.entityEdit
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addAddress(entity) {
			let vm = this;
			vm.dialogAddAddress.missing = "";

			if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1 ||
				!vm.newAddress.city || vm.newAddress.city.trim().length < 1 ||
				!vm.newAddress.state || vm.newAddress.state.trim().length < 1 ||
				!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) {
					
					if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1) vm.dialogAddAddress.missing += "Address, ";
					if (!vm.newAddress.city || vm.newAddress.city.trim().length < 1) vm.dialogAddAddress.missing += "City, ";
					if (!vm.newAddress.state || vm.newAddress.state.trim().length < 1) vm.dialogAddAddress.missing += "State, ";
					if (!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) vm.dialogAddAddress.missing += "Postcode, ";
					
					vm.dialogAddAddress.missing = vm.dialogAddAddress.missing.slice(0, -2) + " required.";
					vm.dialogAddAddress.show = true;

			} else {

				vm.newAddress.id = vm.id++;
				entity.addressDataList.push(vm.newAddress);
				vm.newAddress = {};
			}
		},
		deleteAddress(entity, id) {
			let list = entity.addressDataList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					return;
				}
			}
		},
		pageFirst() {
			this.pagination.page = 1;
		},
		pagePrevious() {
			if (this.pagination.page > 1) {
				this.pagination.page--;
			}
		},
		pageNext() {
			if (this.pagination.page < this.pagination.pageLast) {
				this.pagination.page++;
			}
		},
		pageLast() {
			this.pagination.page = this.pagination.pageLast;
		},
		pageReset() {
			this.pagination.page = 1;
		},
		pageCount() {
			this.pagination.pageLast = Math.ceil(this.entityList.length / this.pagination.pageSize);
		},
		paginatedEntityList() {
			let start = (this.pagination.page - 1) * this.pagination.pageSize;
			let end = start + this.pagination.pageSize;
			return this.entityList.slice(start, end);
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		uploadFile() {
			let vm = this;
			let formData = new FormData();

			formData.append("doInsert", vm.entityUpload.doInsert);
			formData.append("doUpdate", vm.entityUpload.doUpdate);
			formData.append("doUpdateAddress", vm.entityUpload.doUpdateAddress);
			formData.append("doDelete", vm.entityUpload.doDelete);

			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}

			vm.entityUpload.result = '';

			ajax.post("/system/company/customer/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					// vm.$root.logSuccess(response);
					vm.entityUpload.result = JSON.stringify(response.data);
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>
