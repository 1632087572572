<template>
	<div>
		<md-button class="md-icon-button md-raised md-dense" @click="$router.go(-1)">
			<md-icon>keyboard_backspace</md-icon>
		</md-button>

		<md-field>
			<label>Event name</label>
			<md-input v-model="entityEdit.name" id="inputName" disabled></md-input>
		</md-field>

		<div v-html="entityEdit.description" class="desc"></div>

		<md-field>
			<label>
				Start date:
				<span style="color:#000;" v-if="entityEdit.begin" disabled>{{entityEdit.begin.date.year}}-{{entityEdit.begin.date.month}}-{{entityEdit.begin.date.day}}</span>
			</label>
		</md-field>

		<md-field>
			<label>
				End date:
				<span style="color:#000;" v-if="entityEdit.end" disabled>{{entityEdit.end.date.year}}-{{entityEdit.end.date.month}}-{{entityEdit.end.date.day}}</span>
			</label>
		</md-field>

		<br />

		<md-list>
			<label>Asset List</label>
			<md-list-item v-for="item in entityEdit.assetList" :key="item.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">{{item.name}} {{item.regionName ? ' - ' + item.regionName : ""}}</div>
				<div class="md-list-item-text">
					<table>
						<tr>
							<td>{{item.eventBookingStatus === 'None' ? '' : item.eventBookingStatus}}</td>
						</tr>
					</table>
				</div>
			</md-list-item>
		</md-list>

		<md-list>
			<label>Resource List</label>
			<md-list-item v-for="item in entityEdit.resourceList" :key="item.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">{{item.name}}</div>
				<div class="md-list-item-text">
					<table>
						<tr>
							<td>{{item.eventResourceBookingStatus === 'None' ? '' : item.eventResourceBookingStatus}}</td>
							<td>
								<template v-if="me.id === item.id">
									<table>
										<tr>
											<td>
												<md-button class="md-raised md-dense" @click="editStatus(item, 'Approved')">
													<md-icon>done</md-icon>
												</md-button>
											</td>
											<td>
												<md-button class="md-raised md-dense" @click="editStatus(item, 'Declined')">
													<md-icon>block</md-icon>
												</md-button>
											</td>
											<td>
												<md-button class="md-raised md-dense" @click="editStatus(item, 'None')">
													<md-icon>clear</md-icon>
												</md-button>
											</td>
										</tr>
									</table>
								</template>
							</td>
						</tr>
					</table>
				</div>
			</md-list-item>
		</md-list>

		<md-list>
			<label>Associated Customer</label>
			<md-list-item class="md-primary" v-for="item in entityEdit.customerList" :key="item.id">
				<div class="md-list-item-text">{{item.name}}</div>
			</md-list-item>
		</md-list>

		<md-list>
			<label>Associated Contact</label>
			<md-list-item class="md-primary" v-for="item in entityEdit.staffList" :key="item.id">
				<div class="md-list-item-text">{{item.name}}</div>
			</md-list-item>
		</md-list>

		<md-list>
			<label>Associated Manager</label>
			<md-list-item class="md-primary" v-for="item in entityEdit.managerList" :key="item.id">
				<div class="md-list-item-text">{{item.name}}</div>
			</md-list-item>
		</md-list>

		<md-list>
			<label>Event Address</label>
			<md-list-item v-for="entity in entityEdit.addressDataList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span>
						{{entity.address}}
						{{entity.city}}
						{{entity.state}}
						{{entity.postcode}}
						{{entity.note}}
					</span>
				</div>
			</md-list-item>
		</md-list>

		<md-card v-if="entityEdit.imageName">
			<media :name="entityEdit.imageName" />

			<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entityEdit.imageName)" style="position:absolute;right:0px;bottom:10px;">
				<md-icon>get_app</md-icon>
			</md-button>
		</md-card>

		<div v-if="entityEdit.imageDataList.length > 0">
			<div v-for="item in entityEdit.imageDataList" :key="item">
				<md-card style="margin-bottom:5px;">
					<media :name="item" />

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(item)" style="position:absolute;right:0px;bottom:10px;">
						<md-icon>get_app</md-icon>
					</md-button>
				</md-card>
			</div>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			entityEdit: {
				imageDataList: [],
				brandList: [],
				categoryList: [],
				dataMap: {},
				customerList: [],
				staffList: [],
				managerList: []
			},
			baseLocation: ajax.defaults.baseURL,
			attachment: null,
			attachment1: null,
			attachment2: null,
			attachment3: null,
			attachment4: null,
			attachment5: null,
			brandList: [],
			selectedBrandId: null,
			customerList: [],
			selectedCustomerId: null,
			personList: [],
			id: 1,
			newAddress: {},
			selectedStaffId: null,
			selectedManagerId: null,
			assetList: [],
			selectedAssetId: null,
			resourceList: [],
			me: {},
			selectedResourceId: null,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		ajax.all([this.profile()]).then(() => {
			this.find();
		});
	},
	methods: {
		find() {
			let vm = this;
			ajax.get("/system/event?id=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityEdit = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.me = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		editStatus(item, status) {
			let vm = this;
			ajax.post("/system/event/edit/booking/resource/status", {
				id: item.eventResourceBookingId,
				status: status
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.find();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>