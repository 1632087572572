import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* eslint-disable */

export default new Vuex.Store({
	state: {
		searchKeyword: null,
		searchOrderId: null,
		searchSalesOrderId: null
	},
	mutations: {
		fillSearchKeyword(state, keyword) {
			state.searchKeyword = keyword;
		},
		fillSearchOrderId(state, keyword) {
			state.searchOrderId = keyword;
		},
		fillSearchSalesOrderId(state, keyword) {
			state.searchSalesOrderId = keyword;
		}
	}
})