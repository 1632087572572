<template>
	<div>
		<div class="md-title">Region</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span>{{entity.name}}</span>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<md-field>
				<label>Region name</label>
				<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Region name</label>
				<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification" />
		<snackbar :snack="snack" />
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new Region" },
			dialogEdit: { show: false, title: "Edit Region" },
			dialogDelete: {
				show: false,
				title: "Delete Region",
				content: "Are you sure you want to delete this Region?"
			},
			entityAdd: {},
			entityEdit: { active: false },
			selectedId: null,
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: {
				show: false,
				title: "",
				content: ""
			},
			snack: {
				show: false,
				content: ""
			}
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/region/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/region/search", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.entityAdd.name = "";
			vm.dialogAdd.show = true;
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			ajax.post("/system/region/add", { entity: vm.entityAdd })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit.id = item.id;
			vm.entityEdit.name = item.name;
			vm.dialogEdit.show = true;
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			ajax.post("/system/region/edit", { entity: vm.entityEdit })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/region/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>