<template>
	<md-snackbar :md-position="'center'" :md-duration="5000" :md-active.sync="snack.show" style="background-color:#FFF3CD;">
		<span style="color:#533F03;">{{snack.content}}</span>
		<md-button @click="snack.show = false">Close</md-button>
	</md-snackbar>
</template>

<style scoped>
</style>

<script>
export default {
	props: {
		snack: {}
	}
};
</script>