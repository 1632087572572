<template>
	<div>
		<point-of-sale-edit :categoryIsDeleted="categoryIsDeleted">
			<template #category="slotProps">
				<md-field>
					<label for="selectCategory">Asset</label>
					<md-select v-model="slotProps.entity.categoryId" md-dense id="selectCategory">
						<template v-for="item in categoryList">
							<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
						</template>
					</md-select>
					<md-button class="md-icon-button md-raised md-dense" @click="{dialogDelete.show = true}">
						<md-icon>close</md-icon>
					</md-button>
				</md-field>
			</template>
		</point-of-sale-edit>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />
	</div>
</template>

<script>
import ajax from "axios";
import pointOfSaleEdit from "./administratorPointOfSaleEdit.vue";

export default {
	components: {
		pointOfSaleEdit
	},
	data() {
		return {
			dialogDelete: {
				show: false,
				title: "Delete asset from product",
				content: "Are you sure to delete this asset from the product?"
			},
			categoryIsDeleted: false,
			categoryList: [],
		};
	},
	mounted: function () {
		this.listCategory();
	},
	methods: {
		listCategory() {
			let vm = this;
			ajax.get("/system/category/list")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.categoryList = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.categoryIsDeleted = true;
		},
	}
};
</script>