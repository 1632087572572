import { render, staticRenderFns } from "./administratorCocktailEdit.vue?vue&type=template&id=2d161bed&scoped=true"
import script from "./administratorCocktailEdit.vue?vue&type=script&lang=js"
export * from "./administratorCocktailEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d161bed",
  null
  
)

export default component.exports