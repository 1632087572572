<template>
	<md-dialog :md-active.sync="notification.show">
		<md-dialog-title>{{notification.title}}</md-dialog-title>
		<md-divider></md-divider>
		<br />
		<template v-if="notification.content.type == 'NOTIFICATION'">
			<div style="color:#C42126" :key="item" v-for="item in notification.content.fieldErrorList">{{item}}</div>
			<div style="color:#ED5025" :key="item" v-for="item in notification.content.errorList">{{item}}</div>
			<div style="color:#E26026" :key="item" v-for="item in notification.content.noticeList">{{item}}</div>
		</template>
		<md-dialog-actions>
			<md-button class="md-primary" @click="notification.show = false">Close</md-button>
		</md-dialog-actions>
	</md-dialog>
</template>

<style scoped>
</style>

<script>
export default {
	props: {
		notification: {}
	}
};
</script>