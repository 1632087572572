<template>
	<div>
		<div class="md-title">{{company.name}} - Inventory Report</div>

		<md-table v-if="entityList && entityList.length > 0" v-model="entityList" md-sort="id,name" md-sort-order="asc" md-model-id="key">
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell md-label="Product Id" md-sort-by="id">
					<div class="md-subheading">{{ item.id }}</div>
				</md-table-cell>
				<md-table-cell md-label="Product Image">
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + item.imageName" />
					</md-avatar>
				</md-table-cell>
				<md-table-cell md-label="Brand" md-sort-by="brandName">{{ item.brandName }}</md-table-cell>
				<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
				<md-table-cell v-if="item.inventoryDataList.length > 0" md-label="Unit" md-sort-by="dataMap.variant">{{ item.dataMap.variant }}</md-table-cell>
				<md-table-cell v-if="item.inventoryDataList.length > 0" md-label="Quantity" md-sort-by="dataMap.quantity">{{ item.dataMap.quantity }}</md-table-cell>
				<md-table-cell v-if="item.inventoryDataList.length > 0" md-label="Price" md-sort-by="dataMap.price">{{ item.dataMap.price ? '$' + item.dataMap.price : "" }}</md-table-cell>
				<md-table-cell md-label="Status" md-sort-by="status">{{ item.status }}</md-table-cell>
			</md-table-row>
		</md-table>
	</div>
</template>

<script>
import ajax from "axios";

export default {
	data() {
		return {
			entityList: [],
			company: {},
			baseLocation: ajax.defaults.baseURL
		};
	},
	mounted() {
		this.listInventoryProduct();
		this.findCompany();
	},
	methods: {
		findCompany() {
			ajax.get("/system/company?id=" + this.$route.params.entityId)
				.then((response) => {
					this.$root.logSuccess(response);
					this.company = response.data;
				})
				.catch((error) => {
					this.$root.logError(error);
					this.$root.showNotification(this.notification, error.response);
				});
		},
		listInventoryProduct() {
			ajax.post("/system/product/inventory", {
				supplierId: this.$route.params.entityId
			})
				.then((response) => {
					this.$root.logSuccess(response);
					this.entityList = response.data;
					// Get unique key
					this.entityList.forEach(item => item.key = item.id + "." + item.dataMap.variant); 
				})
				.catch((error) => {
					this.$root.logError(error);
					this.$root.showNotification(this.notification, error.response);
				});
		},
		getColumn(name, index) {
			if (index > 0) {
				name += " " + index;
			}
			return name;
		}
	}
};
</script>

<style scoped>
</style>