<template>
	<div>
		<div class="md-title">Asset</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id">
				<div>
					<span @click="up(entity)">
						<md-icon class="pointer">arrow_drop_up</md-icon>
					</span>
					<br />
					<span @click="down(entity)">
						<md-icon class="pointer">arrow_drop_down</md-icon>
					</span>
				</div>
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text" style="padding-left: 10px">{{entity.name}}</div>
				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<md-dialog :md-active.sync="dialogAdd.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogAdd.title }}</md-dialog-title>

				<md-field>
					<label for="inputAddName">Name</label>
					<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
				</md-field>

				<div v-html="entityAdd.description" class="desc"></div>

				<md-field>
					<label for="inputAddDescription">Description</label>
					<md-textarea v-model="entityAdd.description" id="inputAddDescription" md-autogrow></md-textarea>
				</md-field>

				<md-field>
					<label for="inputAddImage">Image</label>
					<md-file @md-change="handleFileUpload" id="inputAddImage" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
				</md-field>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="add()">Add</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogEdit.title }}</md-dialog-title>

				<md-field>
					<label for="inputEditName">Name</label>
					<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
				</md-field>

				<div v-html="entityEdit.description" class="desc"></div>

				<md-field>
					<label for="inputEditDescription">Description</label>
					<md-textarea v-model="entityEdit.description" id="inputEditDescription" md-autogrow></md-textarea>
				</md-field>

				<md-card v-if="entityEdit.imageName">
					<media :name="entityEdit.imageName" />

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entityEdit.imageName)" style="position:absolute;right:0px;bottom:10px;">
						<md-icon>get_app</md-icon>
					</md-button>
				</md-card>

				<md-field>
					<label for="inputEditImage">Image</label>
					<md-file @md-change="handleFileUpload" id="inputEditImage" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
				</md-field>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<script>
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			keyword: "",
			dialogDelete: {
				show: false,
				title: "Delete Asset",
				content: "Are you sure you want to delete this asset?"
			},
			dialogAdd: {
				show: false,
				title: "Add new Asset"
			},
			dialogEdit: {
				show: false,
				title: "Edit Asset"
			},
			entityAdd: {
				name: "",
				description: ""
			},
			attachment: null,
			entityEdit: {
				id: "",
				name: "",
				description: "",
				imageName : null
			},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted() {
		this.list();
	},
	methods: {
		swapLine(a, b) {
			let vm = this;
			ajax.post("/system/category/swap", {
				entity1: vm.entityList[a],
				entity2: vm.entityList[b]
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		up(entity) {
			let vm = this;
			let index = vm.entityList.indexOf(entity);
			if (index > 0) {
				this.swapLine(index - 1, index);
			}
		},
		down(entity) {
			let vm = this;
			let index = vm.entityList.indexOf(entity);
			if (index + 1 < this.entityList.length) {
				this.swapLine(index, index + 1);
			}
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/category/list")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/category/search", {
					keyword: vm.keyword
				})
					.then((response) => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch((error) => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/category/delete", { id: vm.selectedId })
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;

			let formData = new FormData();
			formData.append("entity", JSON.stringify(vm.entityAdd));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}

			ajax.post("/system/category/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;

			let formData = new FormData();
			formData.append("entity", JSON.stringify(vm.entityEdit));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}

			ajax.post("/system/category/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showAdd() {
			let vm = this;

			vm.entityAdd.name = "";
			vm.entityAdd.description = "";

			vm.attachment = null;

			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;

			vm.entityEdit.id = item.id;
			vm.entityEdit.name = item.name;
			vm.entityEdit.description = item.description;
			vm.entityEdit.imageName = item.imageName;

			vm.attachment = null;

			vm.dialogEdit.show = true;
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		}
	}
};
</script>

<style scoped>
.dialog-container {
	width: 600px;
}

.dialog-container > * {
	margin: 30px 0;
}
</style>