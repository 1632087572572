<template>
	<div>
		<div class="md-title" style="margin:20px 0;">Order History</div>

		<md-field>
			<label>Search by Order ID</label>
			<md-input v-model="search.keyword" required id="inputSearch" v-on:keyup.enter="list(chosenPerson)" v-on:keypress="isPhone($event, search.keyword)"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="searchReset()">
				<md-icon>close</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="list(chosenPerson)">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<div style="text-align:right;margin-bottom:20px;">
			<md-field>
				<md-select v-model="chosenPerson" name="chosenPerson" id="chosenPerson" placeholder="Person">
					<md-option value="0">Myself</md-option>

					<md-optgroup label="Subordinate">
						<template v-for="sub in subordinateList">
							<md-option :key="sub.name" :value="sub.id">{{sub.name}}</md-option>
						</template>
					</md-optgroup>
				</md-select>
				<md-button class="md-raised md-primary" @click="list(chosenPerson)">Show</md-button>
				<md-button class="md-raised md-primary" @click="report()">
					<md-icon>save_alt</md-icon>Download report
				</md-button>
			</md-field>
		</div>

		<div align="center">
			<div style="text-align:right;padding-right:10px;margin:10px 0;">
				<div style="padding-top:5px;display:inline-block;">
					{{(listSize == 0 ? 0 : pageNumber * pageSize + 1)}} - {{(pageNumber * pageSize + pageSize > listSize ? listSize : pageNumber * pageSize + pageSize)}} of {{listSize}}
					(Page {{pageNumber+1}})
				</div>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('first')" :disabled="pageNumber == 0">
					<md-icon>first_page</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('dec')" :disabled="pageNumber == 0">
					<md-icon>chevron_left</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('inc')" :disabled="pageNumber >= maxPage-1">
					<md-icon>chevron_right</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('last')" :disabled="pageNumber >= maxPage-1">
					<md-icon>last_page</md-icon>
				</md-button>
			</div>
		</div>

		<md-table md-card>
			<md-table-row>
				<md-table-head>Order Id</md-table-head>
				<md-table-head>Date</md-table-head>
				<md-table-head>Name</md-table-head>
				<md-table-head>Customer</md-table-head>
				<md-table-head>Address</md-table-head>
				<md-table-head>City</md-table-head>
				<md-table-head>State</md-table-head>
				<md-table-head>Postcode</md-table-head>
				<md-table-head>Phone</md-table-head>
				<md-table-head>Attention To</md-table-head>
				<md-table-head>Note</md-table-head>
				<md-table-head>Total</md-table-head>
				<md-table-head>Status</md-table-head>
			</md-table-row>
			<md-table-row v-for="entity in entityList" :key="entity.id" @click="selectedEntity = entity" class="pointer">
				<template v-if="entity === selectedEntity">
					<md-table-cell colspan="8">
						<md-table md-card v-if="selectedEntity" style="margin-bottom:20px;">
							<md-table-row>
								<md-table-head>Order Id</md-table-head>
								<md-table-head>Date</md-table-head>
								<md-table-head>Name</md-table-head>
								<md-table-head>Customer</md-table-head>
								<md-table-head colspan="2">Address</md-table-head>
								<md-table-head>City</md-table-head>
								<md-table-head>State</md-table-head>
								<md-table-head>Postcode</md-table-head>
								<md-table-head>Phone</md-table-head>
								<md-table-head>Attention To</md-table-head>
								<md-table-head>Note</md-table-head>
								<md-table-head>Total</md-table-head>
								<md-table-head>Status</md-table-head>
								<md-table-head></md-table-head>
							</md-table-row>

							<md-table-row>
								<md-table-cell>{{selectedEntity.id}}</md-table-cell>
								<md-table-cell>{{selectedEntity.dataMap.createdText}}</md-table-cell>
								<md-table-cell>{{selectedEntity.dataMap.ownerName}}</md-table-cell>
								<md-table-cell>{{entity.dataMap.deliveryCompanyName}}</md-table-cell>
								<md-table-cell colspan="2">{{selectedEntity.deliveryAddress}}</md-table-cell>
								<md-table-cell>{{selectedEntity.deliveryCity}}</md-table-cell>
								<md-table-cell>{{selectedEntity.deliveryState}}</md-table-cell>
								<md-table-cell>{{selectedEntity.deliveryPostcode}}</md-table-cell>
								<md-table-cell>{{selectedEntity.deliveryPhone}}</md-table-cell>
								<md-table-cell>{{selectedEntity.attentionTo}}</md-table-cell>
								<md-table-cell>{{selectedEntity.note}}</md-table-cell>
								<md-table-cell>$ {{selectedEntity.total}}</md-table-cell>
								<md-table-cell>{{selectedEntity.status}}</md-table-cell>
								<md-table-cell></md-table-cell>
							</md-table-row>

							<md-table-row v-if="$root.tenantCustomisation('lion-charge-code')">
								<md-table-head></md-table-head>
								<md-table-head>Type: {{ entity.chargeItemType }}</md-table-head>
								<md-table-head>CCID: {{ entity.chargeCcid }}</md-table-head>
								<md-table-head>Customer Number: {{ entity.chargeReceiverCode }}</md-table-head>
								<md-table-head>Freight: ${{ entity.freightCost }}</md-table-head>
								<md-table-head>Despatch Date: {{ entity.transitMap.despatchDate }}</md-table-head>
								<md-table-head>Done: {{ entity.done ? 'Yes' : 'No' }}</md-table-head>
								<md-table-head>Charge Posted: {{ entity.transitMap.chargePosted }}</md-table-head>
							</md-table-row>

							<md-table-row>
								<md-table-head>Order Id {{selectedEntity.id}}</md-table-head>
								<md-table-head>Name</md-table-head>
								<md-table-head>Variant</md-table-head>
								<md-table-head>Quantity</md-table-head>
								<md-table-head>Price</md-table-head>
								<md-table-head>Subtotal</md-table-head>
								<md-table-head>Note</md-table-head>
								<md-table-head>Status</md-table-head>
								<md-table-head></md-table-head>
							</md-table-row>

							<md-table-row v-for="line in selectedEntity.orderLineList" :key="line.id" :class="line.productType">
								<md-table-cell>
									<md-avatar>
										<img :src="baseLocation + '/asset/file/medium/' + line.imageName
												+ (line.productType == 'MenuImage' ? '?randomId=' + randomId : '')" />
									</md-avatar>
								</md-table-cell>
								<md-table-cell>
									<router-link v-if="line.productId" :to="'/sales/' + (line.productType && line.productType.toLowerCase()) + '/view/' + line.productId
											+ (line.productType == 'MenuImage' ? '?variant=' + line.variant : '')">{{line.productName}}</router-link>
									<span v-else>{{line.productName}}</span>
								</md-table-cell>
								<md-table-cell>{{line.variant}}</md-table-cell>
								<md-table-cell>{{line.quantity}}</md-table-cell>
								<md-table-cell>$ {{line.price}}</md-table-cell>
								<md-table-cell>$ {{line.total}}</md-table-cell>
								<md-table-cell>{{line.note}}</md-table-cell>
								<md-table-cell>{{line.status && line.status.replace(/_/g, ' ')}}</md-table-cell>
								<md-table-cell style="min-width:80px;">
									<!--
									<md-button class="md-icon-button md-raised md-dense" @click="selectedId = line.id;dialogDelete.show = true;">
										<md-icon title="Delete">delete</md-icon>
									</md-button>
									-->
									<template v-if="line.status == 'Declined' && $root.tenantCustomisation('resubmission')">
										<md-button class="md-icon-button md-raised md-dense" @click="$router.push('/sales/' + (line.productType && line.productType.toLowerCase()) + '/view/' + line.productId + '?orderLineId=' + line.id)">
											<md-icon title="Resubmit">brush</md-icon>
										</md-button>
									</template>
									<template v-if="line.status == 'Approved' || !$root.tenantCustomisation('restrict-download')">
										<md-button class="md-icon-button md-raised md-dense" @click="$root.downloadImage(line.imageName)">
											<md-icon title="Download">get_app</md-icon>
										</md-button>
									</template>
								</md-table-cell>
							</md-table-row>
						</md-table>
					</md-table-cell>
				</template>
				<template v-else>
					<md-table-cell>{{entity.id}}</md-table-cell>
					<md-table-cell>{{entity.dataMap.createdText}}</md-table-cell>
					<md-table-cell>{{entity.dataMap.ownerName}}</md-table-cell>
					<md-table-cell>{{entity.dataMap.deliveryCompanyName}}</md-table-cell>
					<md-table-cell>{{entity.deliveryAddress}}</md-table-cell>
					<md-table-cell>{{entity.deliveryCity}}</md-table-cell>
					<md-table-cell>{{entity.deliveryState}}</md-table-cell>
					<md-table-cell>{{entity.deliveryPostcode}}</md-table-cell>
					<md-table-cell>{{entity.deliveryPhone}}</md-table-cell>
					<md-table-cell>{{entity.attentionTo}}</md-table-cell>
					<md-table-cell>{{entity.note}}</md-table-cell>
					<md-table-cell>$ {{entity.total}}</md-table-cell>
					<md-table-cell>{{entity.status}}</md-table-cell>
				</template>
			</md-table-row>
		</md-table>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
tr.LimitedRelease td {
	color: #03b3b2;
}
.md-table-row:hover:not(.md-header-row) .md-table-cell {
	background: unset !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			dialogDelete: {
				show: false,
				title: "Delete",
				content: "Are you sure you want to delete this?"
			},
			selectedId: null,
			selectedEntity: null,
			entityList: [],
			pageNumber: 0,
			pageSize: 50,
			listSize: 0,
			maxPage: 0,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			subordinateList: [],
			chosenPerson: 0,
			randomId: new Date().valueOf(),
			search: { show: false, keyword: ""},
		};
	},
	mounted: function() {
		this.list(0);
		this.listSubordinate();
	},
	methods: {
		searchReset() {
			let vm = this;
			vm.search.show = false;
			vm.search.keyword = "";
			this.$store.commit("fillSearchSalesOrderId", null);
			// this.listCompanyOrder();
			this.list(0);
		},
		pagination(mode) {
			let vm = this;
			vm.pageNumber = mode == "inc" ? vm.pageNumber + 1 : mode == "dec" ? vm.pageNumber - 1 : mode == "last" ? vm.maxPage - 1 : 0;
			vm.list(vm.chosenPerson);
		},
		listSubordinate() {
			let vm = this;
			ajax.get("/system/person/subordinate/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.subordinateList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list(personId) {
			let vm = this;
			ajax.get("/system/productorder/list?action=mine" + "&pageNumber=" + vm.pageNumber + 
				"&keyword=" + vm.search.keyword +
				"&pageSize=" + vm.pageSize + (personId == 0 ? "" : "&personId=" + personId))
				.then(response => {
					vm.$root.logSuccess(response);
					vm.selectedEntity = null;
					vm.listSize = response.data.size;
					vm.entityList = response.data.list;
					if (vm.pageNumber == 0) {
						vm.maxPage = Math.floor(vm.listSize / ((vm.pageNumber + 1) * vm.pageSize) + (vm.listSize % vm.pageSize > 0 ? 1 : 0));
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		report() {
			let vm = this;
			location.href = vm.baseLocation + "/system/productorder/list?action=mine!report" + "&keyword=" + vm.search.keyword;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/orderline/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list(vm.chosenPerson);
					vm.$emit("profile-update");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		}
	}
};
</script>