import { render, staticRenderFns } from "./mediasize.vue?vue&type=template&id=1ff4f193&scoped=true"
import script from "./mediasize.vue?vue&type=script&lang=js"
export * from "./mediasize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff4f193",
  null
  
)

export default component.exports