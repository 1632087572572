<template>
	<div>
		<div class="md-title">Pack Format</div>

		<br />

		<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
			<md-icon>add</md-icon>
		</md-button>

		<md-table>
			<md-table-row>
				<md-table-head>Unit Volume</md-table-head>
				<md-table-head>Cluster Size</md-table-head>
				<md-table-head>Carton Size</md-table-head>
			</md-table-row>

			<md-table-row v-for="entity in entityList" :key="entity.id">
				<md-table-cell>{{entity.unitVolume}}</md-table-cell>
				<md-table-cell>{{entity.clusterSize}}</md-table-cell>
				<md-table-cell>{{entity.cartonSize}}</md-table-cell>

				<md-table-cell>
					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id;dialogDelete.show = true">
						<md-icon>delete</md-icon>
					</md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<md-field>
				<label>Unit Volume (ml)</label>
				<md-input v-model="entityAdd.unitVolume" id="inputUnitVolume"></md-input>
			</md-field>

			<md-field>
				<label>Cluster Size</label>
				<md-input v-model="entityAdd.clusterSize" id="inputClusterSize"></md-input>
			</md-field>

			<md-field>
				<label>Carton Size</label>
				<md-input v-model="entityAdd.cartonSize" id="inputCartonSize"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new pack format" },
			dialogDelete: {
				show: false,
				title: "Delete pack format",
				content: "Are you sure you want to delete this pack format?"
			},
			selectedId: null,
			entityAdd: {},
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			ajax.get("/system/packformat/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showAdd() {
			let vm = this;
			vm.entityAdd = {};
			vm.dialogAdd.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/packformat/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			ajax.post("/system/packformat/add", {
				entity: vm.entityAdd
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>