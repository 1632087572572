<template>
	<div>
		<div class="md-title">Resource Booking</div>
		{{data.currentMonth}}
		<br />
		<br />

		<table class="calendar" v-if="data.calendar">
			<tr>
				<th style="width:14%;">Mon</th>
				<th style="width:14%;">Tue</th>
				<th style="width:14%;">Wed</th>
				<th style="width:14%;">Thu</th>
				<th style="width:14%;">Fri</th>
				<th style="width:14%;">Sat</th>
				<th style="width:14%;">Sun</th>
			</tr>
			<tr v-for="row in data.calendar" :key="row[0].id">
				<td v-for="entry in row" :key="entry.id">
					<div style="text-align:center;font-size:18px;margin:10px;">{{entry.view}}</div>
					<template v-for="event in entry.eventList">
						<template v-if="event.id">
							<template v-if="event.name">
								<div
									:style="{ backgroundColor: event.color, height: '20px', marginBottom: '5px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }"
									:key="event.id"
									:class="{ approved: event.approved, unapproved: !event.approved, begin: event.begin, end: event.end }"
								>{{$root.ellipsify(event.name, 25)}}</div>
							</template>
							<template v-else>
								<div :style="{ backgroundColor: event.color, color: event.color, height: '20px', marginBottom: '5px' }" :key="event.id" :class="{ begin: event.begin, end: event.end }"></div>
							</template>
						</template>
						<template v-else>
							<div style="height:20px;margin-bottom:5px;" :key="event.id" :class="{ begin: event.begin, end: event.end }"></div>
						</template>
					</template>
				</td>
			</tr>
		</table>

		<fieldset>
			<legend>Legend</legend>Black text: Booked and approved
			<br />White text: Booked but not yet approved
			<br />
		</fieldset>
		<br />

		<div>
			<md-button class="md-icon-button md-raised md-dense" @click="list(data.previousMonth)">
				<md-icon>keyboard_arrow_left</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="list(data.nextMonth)">
				<md-icon>keyboard_arrow_right</md-icon>
			</md-button>
		</div>

		<div v-for="entity in data.list" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
			<div>
				<table>
					<tr>
						<td>
							<div class="circle" :style="{ backgroundColor: entity.dataMap.color }"></div>
						</td>
						<td>{{entity.name}}</td>
					</tr>
				</table>
				<table>
					<tr v-for="item in entity.dataMap.eventList" :key="item.id">
						<td>
							<router-link :to="{path:'/administrator/event/edit/' + item.dataMap.eventId}">{{item.dataMap.time}} : {{item.dataMap.name}}</router-link>
						</td>

						<td>
							<table>
								<tr>
									<td></td>
									<td>
										<md-button class="md-raised md-dense" @click="editStatus(item, 'Approved')">
											<md-icon>done</md-icon>
										</md-button>
									</td>
									<td>
										<md-button class="md-raised md-dense" @click="editStatus(item, 'Declined')">
											<md-icon>block</md-icon>
										</md-button>
									</td>
									<td>
										<md-button class="md-raised md-dense" @click="editStatus(item, 'None')">
											<md-icon>clear</md-icon>
										</md-button>
									</td>
								</tr>
							</table>
						</td>

						<td style="padding-right:15px;padding-top:5px;">
							<md-chip class="chip-product-type" style="text-align: center; line-height: 30px;" v-if="item.eventResourceBookingStatus === 'Approved'">{{item.eventResourceBookingStatus}}</md-chip>
							<md-chip class="md-accent" style="text-align: center; line-height: 30px;" v-if="item.eventResourceBookingStatus === 'Declined'">{{item.eventResourceBookingStatus}}</md-chip>
							<md-chip class="chip-inventory-type" style="text-align: center; line-height: 30px;" v-if="item.eventResourceBookingStatus === 'None'">{{item.eventResourceBookingStatus}}</md-chip>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
.approved {
	font-weight: bold;
	color: black;
	padding-left: 5px;
}
.unapproved {
	color: white;
	padding-left: 5px;
}
.begin {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.end {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			data: {},
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list("");
	},
	methods: {
		list(month) {
			let vm = this;
			ajax.get("/system/booking/resource/calendar?month=" + month)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.data = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		editStatus(item, status) {
			let vm = this;
			ajax.post("/system/event/edit/booking/resource/status", {
				id: item.eventResourceBookingId,
				status: status
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list("");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>