<template>
	<div>
		<md-app>
			<md-app-toolbar md-elevation="0" class="pontoonx-theme">
				<md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible">
					<md-icon class="navigation-icon">menu</md-icon>
				</md-button>
			</md-app-toolbar>

			<md-app-drawer :md-active.sync="menuVisible" md-persistent="mini" class="pontoonx-theme">
				<md-toolbar class="md-transparent" md-elevation="0">
					<img :src="baseLocation + '/resource/tenant/' + $root.tenantLogoFile('menu')" style="margin-left:20px;margin-top:10px;max-height:100px;max-width:160px;" />

					<div class="md-toolbar-section-end">
						<md-button class="md-icon-button md-dense" @click="toggleMenu">
							<md-icon class="navigation-icon">keyboard_arrow_left</md-icon>
						</md-button>
					</div>
				</md-toolbar>

				<md-list class="pontoonx-theme">
					<md-list-item v-if="menuVisible">
						<span style="font-weight:bold;">{{me.name}}</span>
					</md-list-item>

					<md-list-item to="/sales/dashboard">
						<md-icon class="navigation-icon">home</md-icon>
						<span class="md-list-item-text">{{ $root.tenantCustomisation('priority-product') ? "New Items" : "Dashboard" }}</span>
					</md-list-item>

					<template v-if="$root.tenantId() != 1 && $root.tenantId() != 2">
						<md-list-item to="/sales/notification">
							<md-icon class="navigation-icon">feedback</md-icon>
							<span class="md-list-item-text">Notification</span>
						</md-list-item>
					</template>

					<md-list-item to="/sales/selfservice">
						<md-icon class="navigation-icon">face</md-icon>
						<span class="md-list-item-text">Profile</span>
					</md-list-item>

					<md-list-item to="/administrator/dashboard" v-if="me.role === 'Administrator'">
						<md-icon class="navigation-icon">supervised_user_circle</md-icon>
						<span class="md-list-item-text" style="color:red;">Administration</span>
					</md-list-item>

					<template v-if="$root.tenantCustomisation('volume-tracker')">
						<md-list-item to="/sales/venue">
							<md-icon class="navigation-icon">store</md-icon>
							<span class="md-list-item-text">Volume Tracker</span>
						</md-list-item>
					</template>

					<md-list-item to="/sales/customer" v-if="$root.tenantId() != 1">
						<md-icon class="navigation-icon">store</md-icon>
						<span class="md-list-item-text">Customer</span>
					</md-list-item>

					<md-list-item v-if="menuVisible">
						<span class="separator">Shop</span>
					</md-list-item>

					<template v-if="$root.tenantId() != 2">
						<md-list-item to="/sales/brand/merchandise">
							<md-icon class="navigation-icon">local_offer</md-icon>
							<span class="md-list-item-text">Customer/Trade Spend</span>
						</md-list-item>
					</template>

					<template v-if="$root.tenantCustomisation('menu-image')">
						<md-list-item to="/sales/menubuilder">
							<md-icon class="navigation-icon">local_bar</md-icon>
							<span class="md-list-item-text">Menu Builder</span>
						</md-list-item>
					</template>

					<md-list-item to="/sales/brand/pointofsale">
						<md-icon class="navigation-icon">devices</md-icon>
						<span class="md-list-item-text">Point Of Sale</span>
					</md-list-item>

					<template v-if="$root.tenantCustomisation('draft') && $root.tenantId() != 1">
						<md-list-item to="/sales/draft">
							<md-icon class="navigation-icon">book</md-icon>
							<span class="md-list-item-text">Draft</span>
						</md-list-item>
					</template>

					<md-list-item>
						<md-icon class="navigation-icon">local_atm</md-icon>
						<span class="md-list-item-text">
							<strong>{{budgetInformation}}</strong>
						</span>
					</md-list-item>

					<md-list-item to="/sales/cart">
						<md-icon class="navigation-icon">shopping_cart</md-icon>
						<span class="md-list-item-text">Cart</span>
					</md-list-item>

					<md-list-item to="/sales/order">
						<md-icon class="navigation-icon">receipt</md-icon>
						<span class="md-list-item-text">Order History</span>
					</md-list-item>

					<template v-if="$root.tenantId() != 1 && $root.tenantId() != 2">
						<md-list-item v-if="menuVisible">
							<span class="separator">Limited Release</span>
						</md-list-item>

						<md-list-item to="/sales/brand/limitedrelease">
							<md-icon class="navigation-icon">stars</md-icon>
							<span class="md-list-item-text">Limited Release</span>
						</md-list-item>
					</template>

					<template v-if="$root.tenantId() != 1">
						<md-list-item v-if="menuVisible">
							<span class="separator">Resource Library</span>
						</md-list-item>

						<template v-if="$root.tenantId() != 2">
							<md-list-item to="/sales/drive/:">
								<md-icon class="navigation-icon">storage</md-icon>
								<span class="md-list-item-text">Media</span>
							</md-list-item>
						</template>

						<md-list-item to="/sales/mydrive/:">
							<md-icon class="navigation-icon">sd_storage</md-icon>
							<span class="md-list-item-text">My Drive</span>
						</md-list-item>
					</template>

					<template v-if="$root.tenantId() != 1 && $root.tenantId() != 2">
						<md-list-item v-if="menuVisible">
							<span class="separator">Sponsorship Calendar</span>
						</md-list-item>

						<md-list-item to="/sales/event">
							<md-icon class="navigation-icon">event</md-icon>
							<span class="md-list-item-text">Event</span>
						</md-list-item>

						<md-list-item to="/sales/asset">
							<md-icon class="navigation-icon">event_seat</md-icon>
							<span class="md-list-item-text">Asset</span>
						</md-list-item>

						<md-list-item to="/sales/booking/asset">
							<md-icon class="navigation-icon">event_note</md-icon>
							<span class="md-list-item-text">Asset Booking</span>
						</md-list-item>

						<md-list-item to="/sales/booking/resource">
							<md-icon class="navigation-icon">portrait</md-icon>
							<span class="md-list-item-text">Resource Booking</span>
						</md-list-item>
					</template>

					<md-list-item @click="signOut()">
						<md-icon class="navigation-icon">directions_run</md-icon>
						<span class="md-list-item-text">Sign out</span>
					</md-list-item>
				</md-list>
			</md-app-drawer>

			<md-app-content>
				<router-view @profile-update="profile()"></router-view>
			</md-app-content>
		</md-app>

		<img v-show="$root.loader.value" :src="baseLocation + '/resource/image/loader.svg'" class="loader" />
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
.md-app {
	min-height: 350px;
	border: 1px solid rgba(#000, 0.12);
}
.md-drawer {
	width: 230px;
	max-width: calc(100vw - 125px);
}
span.separator {
	font-weight: bold;
	text-transform: uppercase;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			me: {},
			menuVisible: true,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
		};
	},
	computed: {
		budgetInformation: function () {
			if (this.me) {
				if (this.me.budgetLimit) {
					return "$ " + this.me.budgetAvailable + " ($ " + this.me.budgetLimit + ")";
				} else {
					return this.me.budgetAvailable;
				}
			}
			return "";
		}
	},
	mounted: function () {
		this.profile();
	},
	methods: {
		toggleMenu() {
			this.menuVisible = !this.menuVisible;
		},
		signOut() {
			let vm = this;
			ajax.post("/system/authentication/logout", {})
				.then((response) => {
					vm.$root.logSuccess(response);

					ajax.defaults.headers.common["Authorization"] = "";
					window.location.href = "/";
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.me = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>