<template>
	<div>
		<div class="md-title">Draft</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<router-link :to="{path:'/sales/' + (entity.productType && entity.productType.toLowerCase()) + '/view/' + entity.productId + '?draftId=' + entity.id}">{{entity.name}}</router-link>
				</div>
				<div>
					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
						<md-icon>edit</md-icon>
					</md-button>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
						<md-icon>delete</md-icon>
					</md-button>
				</div>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogEdit.show" style="min-width:500px;">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogEdit: { show: false, title: "Edit draft" },
			dialogDelete: {
				show: false,
				title: "Delete draft",
				content: "Are you sure you want to delete this draft?"
			},
			entityEdit: {
				id: null,
				name: null
			},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/draft/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/draft/search", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit = {
				id: item.id,
				name: item.name
			};
			vm.dialogEdit.show = true;
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			ajax.post("/system/draft/edit", {
				id: vm.entityEdit.id,
				name: vm.entityEdit.name
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/draft/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>
