<template>
	<div>
		<div class="md-title">Volume Tracker</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAddBulk()">
				Bulk
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id">
				<div class="md-list-item-text" style="padding-left: 10px">{{ entity.name }}</div>
				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<md-dialog :md-active.sync="dialogAdd.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogAdd.title }}</md-dialog-title>

				<md-field>
					<label for="inputAddName">Venue Name</label>
					<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
				</md-field>

				<div v-html="entityAdd.description" class="desc"></div>

				<md-field>
					<label for="inputAddDescription">Venue Description</label>
					<md-textarea v-model="entityAdd.description" id="inputAddDescription" md-autogrow></md-textarea>
				</md-field>

				<md-field>
					<label>Upload Volume Tracker</label>
					<md-file @md-change="handleFileUpload" id="inputAttachmentAdd" accept=".csv" />
					<span class="md-helper-text">(File types: .csv)</span>
				</md-field>

				<div style="text-align:left;margin-top:40px;">
					<label>Download Sample : </label>
					<md-button class="md-icon-button md-raised md-dense" @click="downloadFileUpload($root.tenantId() + '/sample_volume_tracker.csv')">
						<md-icon>get_app</md-icon>
					</md-button>
				</div>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="add()">Add</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<md-dialog :md-active.sync="dialogAddBulk.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogAddBulk.title }}</md-dialog-title>

				<md-field>
					<label>Upload Bulk Volume Tracker</label>
					<md-file @md-change="handleFileUpload" id="inputAttachmentBulk" accept=".csv" />
					<span class="md-helper-text">(File types: .csv)</span>
				</md-field>

				<div style="text-align:left;margin-top:40px;">
					<label>Download Sample : </label>
					<md-button class="md-icon-button md-raised md-dense" @click="downloadFileUpload($root.tenantId() + '/sample_volume_tracker.csv')">
						<md-icon>get_app</md-icon>
					</md-button>
				</div>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogAddBulk.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="addBulk()">Add</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogEdit.title }}</md-dialog-title>

				<md-field>
					<label for="inputEditName">Venue Name</label>
					<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
				</md-field>

				<div v-html="entityEdit.description" class="desc"></div>

				<md-field>
					<label for="inputEditDescription">Venue Description</label>
					<md-textarea v-model="entityEdit.description" id="inputEditDescription" md-autogrow></md-textarea>
				</md-field>

				<div style="cursor: pointer;" v-html="entityEdit.uploadName" @click="downloadFileUpload(entityEdit.uploadName)"></div>

				<md-field>
					<label>Upload Volume Tracker</label>
					<md-file @md-change="handleFileUpload" id="inputAttachmentEdit" accept=".csv" />
					<span class="md-helper-text">(File types: .csv)</span>
				</md-field>

				<div style="text-align:left;margin-top:40px;">
					<label>Download Sample : </label>
					<md-button class="md-icon-button md-raised md-dense" @click="downloadFileUpload($root.tenantId() + '/sample_volume_tracker.csv')">
						<md-icon>get_app</md-icon>
					</md-button>
				</div>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<script>
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogDelete: {
				show: false,
				title: "Delete Volume Tracker",
				content: "Are you sure you want to delete this Volume Tracker?"
			},
			dialogAdd: {
				show: false,
				title: "Add New Volume Tracker"
			},
			dialogAddBulk: {
				show: false,
				title: "Add Bulk Volume Tracker"
			},
			dialogEdit: {
				show: false,
				title: "Edit Volume Tracker"
			},
			entityAdd: {
				name: "",
				description: ""
			},
			entityEdit: {
				id: "",
				name: "",
				description: "",
				uploadName: null
			},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		}
	},
	mounted() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/venue/list")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/venue/search", {
					keyword: vm.keyword
				})
					.then((response) => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch((error) => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/venue/delete", { id: vm.selectedId })
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;

			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			formData.append("entity", JSON.stringify(vm.entityAdd));

			ajax.post("/system/venue/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addBulk() {
			let vm = this;
			vm.dialogAddBulk.show = false;

			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			// formData.append("entity", JSON.stringify(vm.entityAdd));

			ajax.post("/system/venue/addbulk", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;

			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			formData.append("entity", JSON.stringify(vm.entityEdit));

			ajax.post("/system/venue/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showAdd() {
			let vm = this;

			vm.entityAdd.name = "";
			vm.entityAdd.description = "";

			vm.attachment = null;

			vm.dialogAdd.show = true;
		},
		showAddBulk() {
			let vm = this;
			vm.attachment = null;
			vm.dialogAddBulk.show = true;
		},
		showEdit(item) {
			let vm = this;

			vm.entityEdit.id = item.id;
			vm.entityEdit.name = item.name;
			vm.entityEdit.description = item.description;
			vm.entityEdit.uploadName = item.uploadName;

			vm.attachment = null;

			vm.dialogEdit.show = true;
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		downloadFileUpload(name) {
			location.href = ajax.defaults.baseURL + "/asset/file/" + name + "?download&time=" + new Date();
		}
	}
}

</script>

<style scoped>
.dialog-container {
	width: 400px;
	padding: 20px;
}
</style>