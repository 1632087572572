<template>
	<div>
		<div class="md-title">Merchandise</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" :to="$root.tenantId() == 1 ? '/administrator/merchandise/sw/add' : '/administrator/merchandise/add'">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-toolbar md-elevation="1" style="margin-bottom: 10px; width: 100%">
			<div style="text-align: right; margin-bottom: 20px; padding: 5px 20px 5px 0px; flex: 1 1 0%" class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="chosenBrand">Brand</label>
						<md-select v-model="chosenBrand" name="chosenBrand" id="chosenBrand" placeholder="Brand">
							<md-option value="-1">All</md-option>
							<template v-for="brand in brandList">
								<md-option :key="brand.name" :value="brand.id">{{brand.name}}</md-option>
							</template>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="chosenCategory">Asset</label>
						<md-select v-model="chosenCategory" name="chosenCategory" id="chosenCategory" placeholder="Asset">
							<md-option value="-1">All</md-option>
							<template v-for="category in categoryList">
								<md-option :key="category.name" :value="category.id">{{category.name}}</md-option>
							</template>
						</md-select>
					</md-field>
				</div>
				<md-button class="md-raised md-primary" @click="search()">Apply Filter</md-button>
			</div>
		</md-toolbar>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<router-link :to="{path: ($root.tenantId() == 1 ? '/administrator/merchandise/sw/edit/' : '/administrator/merchandise/edit/') + entity.id}" :class="entity.status">{{entity.name}}</router-link>
					<template v-for="qty in entity.showQty">
						<div :key="qty" style="width:100%;font-size: 16px;">{{ qty }}</div> 
					</template>
					<div style="width:100%;font-size: 16px;">Product ID : {{ entity.id }}</div> 
					<div>
						<md-chip class="chip-inventory-type" style="width:auto; padding-top: 5px;" v-if="entity.customizable">Customizable</md-chip>
						<md-chip class="chip-auto-approve"  style="width:auto; padding-top: 5px;" v-if="$root.tenantCustomisation('auto-approve-switch') && entity.autoApprove">Auto approve</md-chip>
					</div>
				</div>

				<md-button v-if="$root.tenantCustomisation('priority-product')" :class="entity.corepos!= null ? 'md-raised md-accent': 'md-raised md-primary'" @click="updateCorepos(entity)">{{ entity.corepos!= null ? "Remove from core point of sales" : "Add to core point of sales" }}</md-button>
				<md-button v-if="$root.tenantCustomisation('priority-product')" class="md-raised md-primary" :disabled="entity.priority != null" @click="addPriority(entity)">{{ entity.priority ? "In new list" : "Add to new list" }}</md-button>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogDelete: {
				show: false,
				title: "Delete merchandise",
				content: "Are you sure you want to delete this merchandise?"
			},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			brandList: [],
			categoryList: [],
			chosenBrand: -1,
			chosenCategory: -1,
		};
	},
	mounted: function() {
		this.listCategory();
		this.listBrand();
		this.list();
	},
	methods: {
		listCategory() {
			let vm = this;
			ajax.get("/system/category/list") 
				.then(response => {
					vm.$root.logSuccess(response);
					vm.categoryList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listBrand() {
			let vm = this;
			ajax.get("/system/brand/list") 
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/product/list?type=Merchandise")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				vm.chosenBrand = vm.chosenBrand == -1 ? null : vm.chosenBrand;
				vm.chosenCategory = vm.chosenCategory == -1 ? null : vm.chosenCategory;
				ajax.post("/system/product/search?type=Merchandise" + 
					"&brandId=" + vm.chosenBrand + 
					"&categoryId=" + vm.chosenCategory, {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
						vm.chosenBrand = vm.chosenBrand == null ? -1 : vm.chosenBrand;
						vm.chosenCategory = vm.chosenCategory == null ? -1 : vm.chosenCategory;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/product/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addPriority(product) {
			let vm = this;
			ajax.post("/system/product/priority/add", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		updateCorepos(product) {
			let vm = this;
			if (product.corepos) {

				ajax.post("/system/product/corepos/remove", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			} else {
				ajax.post("/system/product/corepos/add", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			}
			
		}
	}
};
</script>
