<template>
	<div>
		<div class="md-title">My Drive Search</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar
					v-if="!entity.folder && entity.name && (entity.name.toLowerCase().endsWith('.jpg') || entity.name.toLowerCase().endsWith('.jpeg') || entity.name.toLowerCase().endsWith('.png') || entity.name.toLowerCase().endsWith('.gif') || entity.name.toLowerCase().endsWith('.bmp') || entity.name.toLowerCase().endsWith('.svg'))"
				>
					<img :src="baseLocation + '/asset' + $root.encodeUrl(entity.pathName) + '?cache&size=medium'" />
				</md-avatar>
				<md-avatar class="square" v-else-if="entity.folder">
					<md-icon>folder</md-icon>
				</md-avatar>
				<md-avatar class="square" v-else>
					<md-icon>attachment</md-icon>
				</md-avatar>

				<div class="md-list-item-text">
					<p>{{entity.name}}</p>
				</div>

				<div class="md-list-item-text" v-if="!entity.folder">
					<mediasize :size="entity.size" />
					<p>{{ entity.modifiedText }}</p>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="download(entity.pathName)" v-if="!entity.folder">
					<md-icon>get_app</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import mediasize from "./mediasize.vue";

export default {
	components: {
		notification,
		snackbar,
		mediasize
	},
	data() {
		return {
			keyword: "",
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {},
	methods: {
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				// do nothing
			} else {
				ajax.post("/system/mydrive/search", { keyword: vm.keyword })
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		download(name) {
			let vm = this;
			location.href = vm.baseLocation + "/asset/" + vm.$root.encodeUrl(name) + "?download&time=" + new Date();
		}
	}
};
</script>
