<template>
	<div>
		<div class="md-title">{{productType}} - {{entity.name}}</div>

		<md-field>
			<label>Search {{productType}}</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<template v-if="entityList != null">
			<div class="card-container">
				<md-card class="md-primary md-with-hover" v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<div @click="detail(entity)">
						<md-card-media>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" style="max-height:300px;" />
						</md-card-media>

						<md-card-header>
							<div class="md-subheading" :class="entity.status">{{ entity.name }}</div>
							<template v-if="entity.description != null && entity.description.trim() != ''">
								<div class="md-body-1" :class="entity.status">{{ limitText(entity.description, 75)}}</div>
							</template>
						</md-card-header>

						<div class="tag">
							<md-chip class="chip-inventory-type" v-if="entity.customizable">Customizable</md-chip>
						</div>
					</div>
				</md-card>
			</div>
		</template>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}

.md-card {
	width: 200px;
	max-height: 600px;
	margin: 4px;
	display: inline-block;
	vertical-align: top;
	background-color: white !important;
	color: black !important;
}

.tag {
	bottom: 0;
	padding: 0 25% 0;
}

.card-container {
	display: flex;
	flex-wrap: wrap;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			entity: {},
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function () {
		this.find();
		this.list();
	},
	watch: {
		"$route.params.productType": function () {
			this.list();
		}
	},
	computed: {
		productType: function () {
			let type = this.$route.params.productType;
			return type == "limitedrelease" ? "Limited Release" : type == "pointofsale" ? "Point Of Sale" : type == "merchandise" ? "Merchandise" : "";
		},
		categoryId: function () {
			let categoryId = this.$route.params.categoryId;
			return categoryId == undefined ? null : categoryId;
		}
	},
	methods: {
		find() {
			let vm = this;
			ajax.get("/system/brand?id=" + this.$route.params.brandId)
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/product/list?type=" + vm.productType.replace(/ /g, "") + "&brandId=" + this.$route.params.brandId + "&categoryId=" + this.categoryId)
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/product/search?type=" + vm.productType.replace(/ /g, "") + "&brandId=" + this.$route.params.brandId + "&categoryId=" + this.categoryId, {
					keyword: vm.keyword
				})
					.then((response) => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch((error) => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
			vm.keyword = "";
		},
		detail(entity) {
			let vm = this;
			vm.$router.push({ path: "/sales/" + this.$route.params.productType + "/view/" + entity.id });
		},
		limitText(text, size) {
			if (text.length > size) {
				return text.substring(0, size) + "...";
			} else {
				return text;
			}
		}
	}
};
</script>
