<template>
	<div class="application">
		<div v-if="!$window.location.origin.includes('stoneandwood.com.au') && !$window.location.origin.includes('stonewood.pontoonx.io')">
			<div v-if="$window.location.origin.includes('test')" class="banner" style="background-color:#03B3B2;color:white;">TEST</div>
			<div v-if="$window.location.origin.includes('localhost')" class="banner" style="background-color:#82BA00;color:white;">DEVELOPMENT</div>
		</div>

		<router-view></router-view>
		<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro|PT+Sans|Open+Sans|Roboto|Material+Icons" rel="stylesheet" />
		<link :href="baseLocation + '/resource/style/font-awesome.min.css'" rel="stylesheet" />
		<link :href="baseLocation + '/resource/tenant/style/' + $root.tenantId() + '/style.css'" rel="stylesheet" />
	</div>
</template>

<style>
@font-face {
	font-family: "B612-Regular";
	src: url(/resource/tenant/font/1/B612-Regular.ttf);
}
@font-face {
	font-family: "Knewave-Regular";
	src: url(/resource/tenant/font/1/Knewave-Regular.ttf);
}
@font-face {
	font-family: "Kodchasan-Regular";
	src: url(/resource/tenant/font/1/Kodchasan-Regular.ttf);
}
/* the font-family name should be the same as the file name minus the extension */
html {
	overflow-y: scroll;
}
div.application {
	font-family: "Source Sans Pro", Helvetica, Arial, monospace, sans-serif;
	margin: 0px;
}
.confirm .md-button {
	border-radius: 0px !important;
}
.md-dialog-container {
	overflow: auto;
	max-width: 100%;
	padding: 15px 20px;
}
.md-dialog-title {
	padding: 10px 0 0 0;
}
.md-overlay {
	display: none;
}
.md-field > .md-icon-button {
	margin: 6px 4px 10px 4px;
}
::selection {
	background: #65bd0091 !important;
}
table.layout {
	width: 100%;
}
table.position {
}
.pointer {
	cursor: pointer;
}
select {
	padding: 9px 5px;
	font-size: 16px;
	border-radius: 5px;
}
input.box {
	padding: 7px 5px;
}
.md-avatar img {
	width: auto !important;
	height: auto !important;
}
.md-avatar {
	border-radius: 0 !important;
}
html.md-theme-default {
	background-color: #fff !important;
}
.md-app {
	position: static !important;
}
.md-chip {
	margin-right: 5px;
	margin-bottom: 5px;
}
.chip-brand {
	background: #0087ff !important;
	color: white !important;
}
.chip-product-type {
	background-color: #007f0e !important;
	color: white !important;
}
.chip-inventory-type {
	background-color: #ff8f32 !important;
	color: white !important;
}
.chip-auto-approve {
	background-color: #45c025 !important;
	color: white !important;
}
.md-textarea {
	min-height: none !important;
}
.square {
	width: 40px !important;
	height: 40px !important;
}
.circle {
	height: 10px;
	width: 10px;
	border-radius: 5px;
}
.small-circle {
	height: 8px;
	width: 8px;
	border-radius: 4px;
	margin-bottom: 2px;
}
.small-rectangle {
	height: 8px;
	width: 8px;
	margin-bottom: 2px;
}
table.calendar {
	width: 100%;
	empty-cells: hide;
	border-spacing: 0px;
	border-collapse: collapse;
}
.calendar th {
	text-align: center;
}
.calendar td {
	vertical-align: top;
	color: #000;
	padding: 0;
}
div.banner {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-family: "PT Sans", sans-serif;
}
.md-field.md-theme-default.md-disabled:after {
	background-image: none !important;
}
.md-avatar {
	width: 100px;
	max-height: 100px;
	height: auto;
}
fieldset {
	border-radius: 5px;
	display: inline-block;
	margin-bottom: 15px;
}
.loader {
	position: fixed;
	z-index: 88;
	top: 30%;
	left: 50%;
	transform: translateX(-50%);
}
@media (max-width: 600px) {
	.md-drawer.md-active {
		box-shadow: none !important;
	}
}
div.desc {
	margin-bottom:10px;
	white-space: pre;
}
</style>

<script>
import ajax from "axios";
export default {
	data() {
		return {
			baseLocation: ajax.defaults.baseURL
		};
	}
};
</script>