<template>
	<div>
		<template v-if="$root.tenantCustomisation('priority-product')">

			<md-field>
				<label>Search</label>
				<md-input v-model="search.keyword" required id="inputSearch" v-on:keyup.enter="searchItem()"></md-input>

				<md-button class="md-icon-button md-raised md-dense" @click="searchReset()">
					<md-icon>close</md-icon>
				</md-button>
				<md-button class="md-icon-button md-raised md-dense" @click="searchItem()">
					<md-icon>search</md-icon>
				</md-button>
			</md-field>

			<div class="md-title" id="pageDashboard" v-if="search.show">Search Result</div>
			<br />
			<div v-if="!$root.loader.value && searchList.length == 0 && search.show">Empty List</div>
			<div class="card" v-for="entity in searchList" :key="entity.id" @click="selectProduct(entity.type, entity)">
				<table width="100%">
					<tr>
						<td>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</td>
					</tr>
					<tr>
						<td>
							<div class="text">{{entity.name}}</div>
						</td>
					</tr>
				</table>
			</div>

			<div class="md-title" id="pageDashboard" v-if="!search.show">New Items</div>
			<br />
			<div v-if="!$root.loader.value && priorityList.length == 0 && !search.show">Empty List</div>
			<div class="card" v-for="entity in priorityList" :key="entity.id" @click="selectProduct(entity.type, entity)">
				<table width="100%">
					<tr>
						<td>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</td>
					</tr>
					<tr>
						<td>
							<div class="text">{{entity.name}}</div>
						</td>
					</tr>
				</table>
			</div>
			<br /><br />

			<div class="md-title" id="pageDashboard" v-if="!search.show">Core Point of Sales</div>
			<br />
			<div v-if="!$root.loader.value && coreList.length == 0 && !search.show">Empty List</div>
			<div class="card" v-for="entity in coreList" :key="entity.id" @click="selectProduct(entity.type, entity)">
				<table width="100%">
					<tr>
						<td>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</td>
					</tr>
					<tr>
						<td>
							<div class="text">{{entity.name}}</div>
						</td>
					</tr>
				</table>
			</div>
		</template>

		<template v-else>
			<template v-if="$root.tenantId() != 2">
				<div class="md-title" id="pageDashboard">Recent Merchandise</div>
				<br />
				<div class="card" v-for="entity in merchandiseList" :key="entity.id" @click="selectProduct('merchandise', entity)">
					<table width="100%">
						<tr>
							<td>
								<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
							</td>
						</tr>
						<tr>
							<td>
								<div class="text">{{entity.name}}</div>
							</td>
						</tr>
					</table>
				</div>
				<br />
			</template>

			<div class="md-title" id="pageDashboard">Recent Point Of Sale</div>
			<br />
			<div class="card" v-for="entity in posList" :key="entity.id" @click="selectProduct('pointofsale', entity)">
				<table width="100%">
					<tr>
						<td>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</td>
					</tr>
					<tr>
						<td>
							<div class="text">{{entity.name}}</div>
						</td>
					</tr>
				</table>
			</div>
			<br />

			<template v-if="$root.tenantId() != 2">
				<div class="md-title" id="pageDashboard">Recent Limited Release</div>
				<br />
				<div class="card" v-for="entity in limitedList" :key="entity.id" @click="selectProduct('limitedrelease', entity)">
					<table width="100%">
						<tr>
							<td>
								<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
							</td>
						</tr>
						<tr>
							<td>
								<div class="text">{{entity.name}}</div>
							</td>
						</tr>
					</table>
				</div>
				<br />
			</template>
		</template>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
.md-card {
	margin: 10px;
	vertical-align: center;
	display: inline-block;
	text-align: center;
}
.md-card img {
	max-height: 230px;
	width: auto;
	max-width: 230px;
	padding: 15px;
}
div.card {
	text-align: center;
	border-radius: 5px;
	margin: 5px;
	width: 300px;
	height: 300px;
	display: inline-block;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	will-change: background-color, box-shadow;
}
div.card img {
	padding: 5px;
	max-height: 250px;
}
div.text {
	font-size: 24px;
	letter-spacing: 0;
	line-height: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 260px;
	padding: 0 15px;
}
div.card:hover {
	cursor: pointer;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			merchandiseList: [],
			limitedList: [],
			posList: [],
			priorityList: [],
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			baseLocation: ajax.defaults.baseURL,
			search: { show: false, keyword: ""},
			coreList: [],
			searchList: [],
		};
	},
	mounted: function() {
		let vm = this;

		if (this.$store.state.searchKeyword === null) {

			if (vm.$root.tenantCustomisation('priority-product')) {
				this.listPriority();
				this.listCorepos();
			} else {
				this.list("Merchandise");
				this.list("LimitedRelease");
				this.list("PointOfSale");
			}
		} else {
			vm.search.keyword = this.$store.state.searchKeyword;
			this.searchItem(this.$store.state.searchKeyword);
		}
	},
	methods: {
		list(productType) {
			let vm = this;
			ajax.get("/system/product/recent/list?limit=5&type=" + productType)
				.then(response => {
					vm.$root.logSuccess(response);

					if (productType == "Merchandise") {
						vm.merchandiseList = response.data;
					} else if (productType == "LimitedRelease") {
						vm.limitedList = response.data;
					} else if (productType == "PointOfSale") {
						vm.posList = response.data;
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectProduct(type, entity) {
			let vm = this;
			type = type.toLowerCase();
			vm.$router.push({
				path: "/sales/" + type + "/view/" + entity.id
			});
		},
		listPriority() {
			let vm = this;
			ajax.get("/system/product/priority/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.priorityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listCorepos() {
			let vm = this;
			ajax.get("/system/product/corepos/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.coreList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		searchItem(stateKeyword) {
			let vm = this;
			let keyword = stateKeyword != null ? stateKeyword : vm.search.keyword;

			vm.searchList = []
			if (keyword.length < 1 || (keyword && keyword.trim() == "")) {
				vm.search.show = false;
				this.$store.commit("fillSearchKeyword", null);
				if (vm.$root.tenantCustomisation('priority-product')) {
					this.listPriority();
					this.listCorepos();
				} else {
					this.list("ProductOrder");
					this.list("Merchandise");
					this.list("PointOfSale");
					this.list("LimitedRelease");
					this.list("Event");
					this.list("Asset");
				}
			} else {
				vm.search.show = true;
				this.$store.commit("fillSearchKeyword", keyword);
				// search all product type
				ajax.post("/system/product/search", {
					keyword: keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.searchList = response.data;
						vm.priorityList = [];
						vm.coreList = [];
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		searchReset() {
			let vm = this;
			vm.search.show = false;
			vm.search.keyword = "";
			this.searchItem();
		},
	}
};
</script>