<template>
	<div>
		<div class="md-title">{{ company.name }} Brand</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom: solid #d9d9d9 1px">
				<div>
					<span @click="up(entity)">
						<md-icon class="pointer">arrow_drop_up</md-icon>
					</span>
					<br />
					<span @click="down(entity)">
						<md-icon class="pointer">arrow_drop_down</md-icon>
					</span>
				</div>
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<span :class="entity.status" style="padding-left: 10px">{{ entity.name }}</span>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{ dialogAdd.title }}</md-dialog-title>

			<md-field>
				<label>Brand name</label>
				<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
			</md-field>

			<md-field>
				<label>Image</label>
				<md-file @md-change="handleFileUpload" id="inputAttachmentAdd" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
			</md-field>

			<md-field>
				<label>Font</label>
				<md-select v-model="entityAdd.fontName" md-dense id="selectFont">
					<template v-for="item in fontList">
						<md-option :key="item" :value="item">{{ item }}</md-option>
					</template>
				</md-select>
			</md-field>

			<md-field>
				<label>Additional Font List</label>
				<md-select v-model="entityAdd.fontList" md-dense id="selectFont" multiple>
					<template v-for="item in fontList">
						<md-option :key="item" :value="item">{{ item }}</md-option>
					</template>
				</md-select>
			</md-field>

			<label>Color List</label>
			<div style="line-height: 40px" v-for="item in colorList" :key="item.value">
				<label>{{ item.name }} - {{ item.value }}</label>
				<md-button class="md-icon-button" @click="deleteColor(item)">
					<md-icon>delete</md-icon>
				</md-button>
			</div>

			<div style="display: flex; flex-direction: row">
				<md-field style="margin-right: 10px">
					<label>Color Name</label>
					<md-input style="width: 100px" v-model="color.name" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Red</label>
					<md-input style="width: 50px" v-model="colorValue.red" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Green</label>
					<md-input style="width: 50px" v-model="colorValue.green" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Blue</label>
					<md-input style="width: 50px" v-model="colorValue.blue" id="inputEditColor"></md-input>
				</md-field>
				<md-button style="margin-top: 15px" class="md-raised md-primary" @click="addColor()">Add</md-button>
			</div>

			<md-switch v-model="entityAdd.limitedReleaseBrand" class="md-primary" id="inputAddLimitedReleaseBrand">Limited Release Brand</md-switch>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="submitFileAdd()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{ dialogEdit.title }}</md-dialog-title>

			<md-field>
				<label>Brand name</label>
				<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
			</md-field>

			<md-switch v-model="entityEdit.active" class="md-primary" id="inputStatus">Active</md-switch>

			<md-field>
				<label>Image</label>
				<md-file @md-change="handleFileUpload" id="inputAttachmentEdit" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
			</md-field>

			<md-field>
				<label>Font</label>
				<md-select v-model="entityEdit.fontName" md-dense id="selectFont">
					<template v-for="item in fontList">
						<md-option :key="item" :value="item">{{ item }}</md-option>
					</template>
				</md-select>
			</md-field>

			<md-field>
				<label>Additional Font List</label>
				<md-select v-model="entityEdit.fontList" md-dense id="selectFont" multiple>
					<template v-for="item in fontList">
						<md-option :key="item" :value="item">{{ item }}</md-option>
					</template>
				</md-select>
			</md-field>

			<label>Color List</label>
			<div style="line-height: 40px" v-for="item in colorList" :key="item.value">
				<label>{{ item.name }} - {{ item.value }}</label>
				<md-button class="md-icon-button" @click="deleteColor(item)">
					<md-icon>delete</md-icon>
				</md-button>
			</div>

			<div style="display: flex; flex-direction: row">
				<md-field style="margin-right: 10px">
					<label>Color Name</label>
					<md-input style="width: 100px" v-model="color.name" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Red</label>
					<md-input style="width: 50px" v-model="colorValue.red" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Green</label>
					<md-input style="width: 50px" v-model="colorValue.green" id="inputEditColor"></md-input>
				</md-field>
				<md-field style="margin-right: 10px">
					<label>Blue</label>
					<md-input style="width: 50px" v-model="colorValue.blue" id="inputEditColor"></md-input>
				</md-field>
				<md-button style="margin-top: 15px" class="md-raised md-primary" @click="addColor()">Add</md-button>
			</div>

			<md-switch v-model="entityEdit.limitedReleaseBrand" class="md-primary" id="inputEditLimitedReleaseBrand">Limited Release Brand</md-switch>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="submitFileEdit()">Save</md-button>
			</md-dialog-actions>

			<media :name="entityEdit.imageName" />
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new Brand" },
			dialogEdit: { show: false, title: "Edit Brand" },
			dialogDelete: {
				show: false,
				title: "Delete Brand",
				content: "Are you sure you want to delete this Brand?"
			},
			entityAdd: { limitedReleaseBrand: null },
			entityEdit: { active: false, limitedReleaseBrand: null },
			entityList: [],
			fontList: [],
			company: {},
			baseLocation: ajax.defaults.baseURL,
			attachment: null,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			color: { name: null, value: null },
			colorValue: { red: null, green: null, blue: null },
			colorList: []
		};
	},
	mounted: function() {
		this.findCompany();
		this.list();
		this.listFont();
	},
	methods: {
		swapLine(a, b) {
			let vm = this;
			ajax.post("/system/brand/swap", {
				entity1: vm.entityList[a],
				entity2: vm.entityList[b]
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		up(entity) {
			let vm = this;
			let index = vm.entityList.indexOf(entity);
			if (index > 0) {
				this.swapLine(index - 1, index);
			}
		},
		down(entity) {
			let vm = this;
			let index = vm.entityList.indexOf(entity);
			if (index + 1 < this.entityList.length) {
				this.swapLine(index, index + 1);
			}
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/brand/list?companyId=" + vm.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listFont() {
			let vm = this;
			ajax.get("/system/enumeration/font/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.fontList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/brand/search?companyId=" + vm.$route.params.entityId, { keyword: vm.keyword })
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.attachment = null;
			vm.entityAdd.name = "";
			vm.entityAdd.limitedReleaseBrand = false;
			vm.entityAdd.colorList = [];
			vm.entityAdd.fontList = [];
			vm.colorList = [];
			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.attachment = null;
			vm.entityEdit.id = item.id;
			vm.entityEdit.name = item.name;
			vm.entityEdit.imageName = item.imageName;
			vm.entityEdit.fontName = item.fontName;
			vm.entityEdit.limitedReleaseBrand = item.limitedReleaseBrand;
			vm.entityEdit.active = "Active" === item.status;
			vm.colorList = [];
			if (item.colorList) {
				vm.colorList = item.colorList;
			}
			vm.entityEdit.fontList = [];
			if (item.fontList) {
				vm.entityEdit.fontList = item.fontList;
			}
			vm.dialogEdit.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/brand/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		submitFileAdd() {
			let vm = this;
			vm.dialogAdd.show = false;
			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			vm.entityAdd.companyId = vm.$route.params.entityId;
			vm.entityAdd.colorList = vm.colorList;
			formData.append("entity", JSON.stringify(vm.entityAdd));
			ajax.post("/system/brand/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		submitFileEdit() {
			let vm = this;
			vm.dialogEdit.show = false;
			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			vm.entityEdit.status = vm.entityEdit.active ? "Active" : "Inactive";
			vm.entityEdit.colorList = vm.colorList;
			formData.append("entity", JSON.stringify(vm.entityEdit));
			ajax.post("/system/brand/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		findCompany() {
			let vm = this;
			ajax.get("/system/company?id=" + vm.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.company = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addColor() {
			let vm = this;
			let colorValue = "rgb(" + vm.colorValue.red + "," + vm.colorValue.green + "," + vm.colorValue.blue + ")";
			vm.color.value = colorValue;
			vm.colorList.push(vm.color);
			vm.color = { name: null, value: null };
			vm.colorValue = { red: null, green: null, blue: null };
		},
		deleteColor(item) {
			let vm = this;
			vm.colorList = vm.colorList.filter(x => x != item);
		}
	}
};
</script>