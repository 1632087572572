<template>
	<div>
		<div class="md-title">Create new Event</div>

		<md-field>
			<label>Event name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Event description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<md-datepicker v-model="beginDate" md-immediately>
			<label>Start date</label>
		</md-datepicker>

		<md-datepicker v-model="endDate" md-immediately>
			<label>End date</label>
		</md-datepicker>

		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 1</label>
			<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 2</label>
			<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 3</label>
			<md-file @md-change="handleFileUpload3" id="inputAttachment3" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 4</label>
			<md-file @md-change="handleFileUpload4" id="inputAttachment4" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 5</label>
			<md-file @md-change="handleFileUpload5" id="inputAttachment5" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/event">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileAdd()">Add</md-button>
		</md-dialog-actions>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entity: {
				type: "Event",
				variantDataList: [],
				brandList: [],
				categoryList: [],
				dataMap: {}
			},
			beginDate: null,
			endDate: null,
			baseLocation: ajax.defaults.baseURL,
			attachment: null,
			attachment1: null,
			attachment2: null,
			attachment3: null,
			attachment4: null,
			attachment5: null,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {},
	methods: {
		listBrand() {
			let vm = this;
			ajax.get("/system/brand/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		handleFileUpload3(fileList) {
			this.attachment3 = fileList[0];
		},
		handleFileUpload4(fileList) {
			this.attachment4 = fileList[0];
		},
		handleFileUpload5(fileList) {
			this.attachment5 = fileList[0];
		},
		submitFileAdd() {
			let vm = this;
			let formData = new FormData();

			if (vm.beginDate) {
				vm.entity.dataMap.beginDate = vm.beginDate.getFullYear() + "-" + (vm.beginDate.getMonth() + 1) + "-" + vm.beginDate.getDate();
			}
			if (vm.endDate) {
				vm.entity.dataMap.endDate = vm.endDate.getFullYear() + "-" + (vm.endDate.getMonth() + 1) + "-" + vm.endDate.getDate();
			}

			vm.entity.type = "Event";

			formData.append("entity", JSON.stringify(vm.entity));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			if (vm.attachment3) {
				formData.append("attachment3", vm.attachment3);
			}
			if (vm.attachment4) {
				formData.append("attachment4", vm.attachment4);
			}
			if (vm.attachment5) {
				formData.append("attachment5", vm.attachment5);
			}
			ajax.post("/system/event/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/event" });
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>