<template>
	<div>
		<div class="md-title">Asset</div>

		<div class="container">
			<md-card v-for="entity in entityList" :key="entity.id" md-with-hover>
				<div @click="selectCategory(entity)">
					<md-card-header>
						<div class="md-title">{{entity.name}}</div>
					</md-card-header>
					<md-card-media>
						<img v-if="entity.imageName" :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
					</md-card-media>
				</div>
			</md-card>
			<md-card md-with-hover>
				<div @click="selectMedia">
					<md-card-header>
						<div class="md-title">Media</div>
					</md-card-header>
					<md-card-media>
                        <md-avatar style="width:100px;height:100px;">
                            <md-icon class="md-size-3x">storage</md-icon>
                        </md-avatar>
                    </md-card-media>
				</div>
			</md-card>
			<md-card md-with-hover>
				<div @click="selectDraft">
					<md-card-header>
						<div class="md-title">Draft</div>
					</md-card-header>
					<md-card-media>
                        <md-avatar style="width:100px;height:100px;">
                            <md-icon class="md-size-3x">book</md-icon>
                        </md-avatar>
                    </md-card-media>
				</div>
			</md-card>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<script>
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted() {
		this.list();
	},
	computed: {
		productType: function () {
			let type = this.$route.params.productType;
			return type == "limitedrelease" ? "Limited Release" : type == "pointofsale" ? "Point Of Sale" : type == "merchandise" ? "Merchandise" : "";
		}
	},
	methods: {
		list() {
			let brandId = this.$route.params.brandId;
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/category/used/list?type=" + vm.productType.replace(/ /g, "") + "&brandId=" + brandId)
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectCategory(entity) {
			let vm = this;
			vm.$router.push({
				path: "/sales/product/" + this.$route.params.productType + "/brand/" + this.$route.params.brandId + "/category/" + entity.id
			});
		},
		selectDraft() {
			let vm = this;
			vm.$router.push({
				path: "/sales/draft/" + this.$route.params.productType + "/brand/" + this.$route.params.brandId
			});
		},
		selectMedia() {
			let vm = this;
			vm.$router.push({
				path: "/sales/drive/sw/" + this.$route.params.brandId + "/:"
			});
		}
	}
};
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 25px 0 0;
}

.md-card {
	margin: 10px;
	height: 350px;
	width: 300px;
	vertical-align: top;
	display: inline-block;
	text-align: center;
}

.md-card img {
	max-height: 280px;
	width: auto;
	padding: 15px;
}
</style>