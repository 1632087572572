<template>
	<div>
		<div class="md-title">Edit Limited Release</div>

		<div style="text-align:right;margin-bottom:20px;">
			<md-button class="md-raised md-primary" @click="reportLimitedRelease()">
				<md-icon>save_alt</md-icon>Download limited release report
			</md-button>
		</div>

		<md-field>
			<label>Offer name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Offer description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<md-switch v-model="active" class="md-primary" id="inputStatus">Active</md-switch>

		<br />

		<md-field>
			<label>Brand</label>
			<md-select v-model="entity.brandId" md-dense id="selectBrand">
				<template v-for="item in limitedReleaseBrandList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-datepicker v-model="notifyDate" md-immediately>
			<label>Notify date</label>
		</md-datepicker>

		<md-datepicker v-model="dueDate" md-immediately>
			<label>Due date</label>
		</md-datepicker>

		<md-card v-if="entity.imageName">
			<media :name="entity.imageName" />

			<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entity.imageName)" style="position:absolute;right:0px;bottom:10px;">
				<md-icon>get_app</md-icon>
			</md-button>
		</md-card>

		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 1</label>
			<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 2</label>
			<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Inventory Type</label>
			<md-input v-model="entity.inventoryType" readonly></md-input>
		</md-field>

		<md-field>
			<label>
				Pack Format:
				<span style="color:#000;">{{entity.packFormat}}</span>
			</label>
		</md-field>

		<template v-if="entity.inventoryType == 'KegCarton'">
			<md-field>
				<label>Keg</label>
				<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityKeg"></md-input>
			</md-field>

			<md-field>
				<label>Carton</label>
				<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityCarton"></md-input>
			</md-field>
		</template>

		<br />
		<div class="md-subheading">Allocation</div>

		<div style="text-align:right;margin-bottom:20px;">
			<md-button class="md-raised md-primary" @click="reportAllocation()">
				<md-icon>save_alt</md-icon>Download allocation report
			</md-button>
		</div>

		<md-table>
			<md-table-row>
				<md-table-head>Name</md-table-head>
				<md-table-head>
					Role
					<select v-model="selectedRole" id="inputSelectRole" style="margin:0 10px;">
						<option value>All</option>
						<option v-for="role in personRoleList" :value="role" :key="role">{{role.replace(/_/g,' ')}}</option>
					</select>
				</md-table-head>
				<md-table-head>Manager</md-table-head>
				<md-table-head>Keg</md-table-head>
				<md-table-head>Keg Ordered</md-table-head>
				<md-table-head>Carton</md-table-head>
				<md-table-head>Carton Ordered</md-table-head>
			</md-table-row>
			<md-table-row v-for="entity in filteredPersonList()" :key="entity.id">
				<md-table-cell>{{entity.name}}</md-table-cell>
				<md-table-cell>{{entity.role && entity.role.replace(/_/g, ' ')}}</md-table-cell>
				<md-table-cell>{{entity.dataMap.managerName}}</md-table-cell>
				<md-table-cell>
					<input type="text" v-model="entity.dataMap.keg" size="3" class="box" />
				</md-table-cell>
				<md-table-cell>{{entity.dataMap.kegOrdered}}</md-table-cell>
				<md-table-cell>
					<input type="text" v-model="entity.dataMap.carton" size="3" class="box" />
				</md-table-cell>
				<md-table-cell>{{entity.dataMap.cartonOrdered}}</md-table-cell>
			</md-table-row>
			<md-table-row>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell>Total/Remaining</md-table-cell>
				<md-table-cell>{{totalKeg}}</md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell>{{totalCarton}}</md-table-cell>
				<md-table-cell></md-table-cell>
			</md-table-row>
		</md-table>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/limitedrelease">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileEdit()">Save</md-button>
		</md-dialog-actions>

		<div v-if="entity.imageDataList.length > 0">
			<div v-for="item in entity.imageDataList" :key="item">
				<md-card style="margin-bottom:5px;">
					<media :name="item" />

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(item)" style="position:absolute;right:40px;bottom:10px;">
						<md-icon>get_app</md-icon>
					</md-button>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteImage(item)" style="position:absolute;right:0px;bottom:10px;">
						<md-icon>delete</md-icon>
					</md-button>
				</md-card>
			</div>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
select {
	padding: 2px;
	font-size: 12px;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			active: false,
			entity: {
				type: "LimitedRelease",
				imageDataList: [],
				inventoryType: null,
				inventoryDataList: [],
				dataMap: {
					inventory: [{}, {}, {}, {}, {}, {}],
					packFormatId: null
				}
			},
			notifyDate: null,
			dueDate: null,
			supplierList: [],
			personList: [],
			brandList: [],
			attachment: null,
			attachment1: null,
			attachment2: null,
			selectedRole: "",
			personRoleList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	computed: {
		totalKeg: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.keg;
				if (e) {
					total += Number(e);
				}
			}
			if (this.entity.dataMap.inventory[0].quantity) {
				total = total + "/" + (this.entity.dataMap.inventory[0].quantity - total);
			}
			return total;
		},
		totalCarton: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.carton;
				if (e) {
					total += Number(e);
				}
			}
			if (this.entity.dataMap.inventory[1].quantity) {
				total = total + "/" + (this.entity.dataMap.inventory[1].quantity - total);
			}
			return total;
		},
		limitedReleaseBrandList: function() {
			var vm = this;
			return vm.brandList.filter(function(brand) {
				return brand.limitedReleaseBrand;
			});
		}
	},
	mounted: function() {
		ajax.all([this.listBrand(), this.listInventoryType(), this.listSupplier()]).then(() => {
			this.find();
		});
		this.listPersonAllocation();
		this.listPersonRole();
	},
	methods: {
		listBrand() {
			let vm = this;
			let promise = ajax.get("/system/brand/list");
			promise
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			return promise;
		},
		listInventoryType() {
			let vm = this;
			let promise = ajax.get("/system/enumeration/inventorytype/list");
			promise
				.then(response => {
					vm.$root.logSuccess(response);
					vm.inventoryTypeList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			return promise;
		},
		listSupplier() {
			let vm = this;
			let promise = ajax.get("/system/company/list?type=Supplier");
			promise
				.then(response => {
					vm.$root.logSuccess(response);
					vm.supplierList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			return promise;
		},
		listPersonAllocation() {
			let vm = this;
			ajax.get("/system/person/allocation/list?productId=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		find() {
			let vm = this;
			ajax.get("/system/product?id=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
					vm.active = vm.entity.status === "Active";

					if (vm.entity.due) {
						let value = vm.entity.due;
						vm.dueDate = new Date(value.date.year, value.date.month - 1, value.date.day, value.time.hour, value.time.minute, value.time.second, 0);
					}
					if (vm.entity.notify) {
						let value = vm.entity.notify;
						vm.notifyDate = new Date(value.date.year, value.date.month - 1, value.date.day, value.time.hour, value.time.minute, value.time.second, 0);
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		handleFileUpload3(fileList) {
			this.attachment3 = fileList[0];
		},
		handleFileUpload4(fileList) {
			this.attachment4 = fileList[0];
		},
		handleFileUpload5(fileList) {
			this.attachment5 = fileList[0];
		},
		submitFileEdit() {
			let vm = this;
			let formData = new FormData();

			vm.entity.status = vm.active ? "Active" : "Inactive";

			if (vm.notifyDate) {
				vm.entity.dataMap.notifyDate = vm.notifyDate.getFullYear() + "-" + (vm.notifyDate.getMonth() + 1) + "-" + vm.notifyDate.getDate();
			}
			if (vm.dueDate) {
				vm.entity.dataMap.dueDate = vm.dueDate.getFullYear() + "-" + (vm.dueDate.getMonth() + 1) + "-" + vm.dueDate.getDate();
			}

			formData.append("entity", JSON.stringify(vm.entity));
			formData.append("allocation", JSON.stringify(vm.personList));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			if (vm.attachment3) {
				formData.append("attachment3", vm.attachment3);
			}
			if (vm.attachment4) {
				formData.append("attachment4", vm.attachment4);
			}
			if (vm.attachment5) {
				formData.append("attachment5", vm.attachment5);
			}
			ajax.post("/system/product/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/limitedrelease" });
					// vm.find();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		deleteImage(name) {
			let vm = this;
			ajax.post("/system/product/image/delete", {
				id: vm.entity.id,
				name: name
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.find();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		reportLimitedRelease() {
			let vm = this;
			location.href = vm.baseLocation + "/system/report/productorder/limitedrelease?productId=" + this.$route.params.entityId;
		},
		reportAllocation() {
			let vm = this;
			location.href = vm.baseLocation + "/system/person/allocation/list?action=report&productId=" + this.$route.params.entityId;
		},
		listPersonRole() {
			let vm = this;
			ajax.get("/system/enumeration/personrole/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personRoleList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		filteredPersonList() {
			if (this.selectedRole && this.selectedRole != "") {
				let list = [];
				for (let i in this.personList) {
					let person = this.personList[i];
					if (person.role == this.selectedRole) {
						list.push(person);
					}
				}
				return list;
			} else {
				return this.personList;
			}
		}
	}
};
</script>