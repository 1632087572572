<template>
	<div>
		<md-card style="max-width:500px;top:80px;margin:0 auto;">
			<md-card-header>
				<div style="text-align:center;">
					<img :src="baseLocation + '/resource/tenant/' + $root.tenantLogoFile('welcome')" style="width:350px;" />
				</div>
				<div class="md-subhead"></div>
			</md-card-header>
			<md-card-content>
				<div v-show="!emailFound">
					<md-field>
						<label>Email</label>
						<md-input v-model="email" id="inputEmail" v-on:keyup.enter="checkEmail()"></md-input>
					</md-field>
				</div>

				<div v-show="emailFound">
					<md-radio v-model="selectedPersonId" v-for="person in personList" :key="person.id" :value="person.id" v-show="personList.length > 1">{{person.dataMap.tenant.name}}: {{person.name}} - {{person.dataMap.company.name}}</md-radio>

					<md-field>
						<label>Password</label>
						<md-input v-model="password" type="password" id="inputPassword" v-on:keyup.enter="signIn()"></md-input>
					</md-field>
				</div>
			</md-card-content>
			<md-card-actions>
				<md-button @click="checkEmail()" class="md-raised md-primary" v-show="!emailFound" id="buttonNext">Next</md-button>
				<md-button @click="signIn()" class="md-raised md-primary" v-show="emailFound" id="buttonSignIn">Sign in</md-button>
			</md-card-actions>
		</md-card>

		<img v-show="$root.loader.value" :src="baseLocation + '/resource/image/loader.svg'" style="position:fixed;z-index:88;top:0;right:0;" />
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			email: null,
			password: null,
			emailFound: false,
			personList: [],
			selectedPersonId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {},
	methods: {
		checkEmail() {
			let vm = this;
			ajax.post("/system/authentication/email", {
				email: vm.email
			})
				.then(response => {
					vm.$root.logSuccess(response);

					let list = response.data;
					if (list.length > 0) {
						vm.personList = list;
						if (list.length == 1) {
							vm.selectedPersonId = list[0].id;
						}
						vm.emailFound = true;
					} else {
						vm.$root.showSnack(vm.snack, "Email is not registered");
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		signIn() {
			let vm = this;
			ajax.post("/system/authentication/login", {
				id: vm.selectedPersonId,
				password: vm.password
			})
				.then(response => {
					vm.$root.logSuccess(response);

					let principal = response.data;
					ajax.defaults.headers.common["Authorization"] = "Bearer " + principal.dataMap.jwt;

					principal.dataMap = {};
					vm.$router.push({
						path: principal.role == "Administrator" ? "administrator/dashboard" : vm.$root.tenantId() != 1 ? "sales/dashboard" : "/sales/brand/pointofsale"
					});
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>