<template>
	<div>
		<div class="md-title">Supplier</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span :class="entity.status">{{entity.name}}</span>
				</div>

				<table>
					<tr>
						<td>
							<md-button class="md-icon-button md-list-action md-raised md-dense" :to="'/administrator/inventoryreport/' + entity.id">
								<md-icon>inventory</md-icon>
							</md-button>
						</td>
						<td>
							<md-button class="md-icon-button md-list-action md-raised md-dense" :to="'/administrator/company/' + entity.id + '/staff'" title="Staff">
								<md-icon>people</md-icon>
							</md-button>
						</td>
						<td>
							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
								<md-icon>edit</md-icon>
							</md-button>
						</td>
					</tr>
				</table>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
			</md-field>

			<md-field>
				<label>Phone Number</label>
				<md-input v-model="entityAdd.phone" id="inputAddPhone" v-on:keypress="isPhone($event, entityAdd.phone)"></md-input>
			</md-field>

			<md-list>
				<md-list-item v-for="entity in entityAdd.addressDataList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<div class="md-list-item-text">
						<span>
							{{entity.address}}
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							{{entity.note}}
						</span>
					</div>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityAdd, entity.id)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>

			<md-card style="padding:0px 10px">
				<table class="layout">
					<tr>
						<td>
							<md-field>
								<label>Address</label>
								<md-input v-model="newAddress.address" id="inputAddAddressAddress"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>City</label>
								<md-input v-model="newAddress.city" id="inputAddAddressCity"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>State</label>
								<md-input v-model="newAddress.state" id="inputAddAddressState"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Postcode</label>
								<md-input v-model="newAddress.postcode" id="inputAddAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4">
							<md-field>
								<label>Address note</label>
								<md-textarea v-model="newAddress.note" id="inputAddAddressNote" md-autogrow></md-textarea>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4" align="right">
							<md-button class="md-raised md-dense md-primary" @click="addAddress(entityAdd)">Add address</md-button>
						</td>
					</tr>
				</table>
			</md-card>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
			</md-field>

			<md-switch v-model="entityEdit.active" class="md-primary" id="inputEditStatus">Active</md-switch>

			<md-field>
				<label>Phone Number</label>
				<md-input v-model="entityEdit.phone" id="inputEditPhone" v-on:keypress="isPhone($event, entityEdit.phone)"></md-input>
			</md-field>

			<md-list>
				<md-list-item v-for="entity in entityEdit.addressDataList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<div class="md-list-item-text">
						<span>
							{{entity.address}}
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							{{entity.note}}
						</span>
					</div>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityEdit, entity.id)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>

			<md-card style="padding:0px 10px">
				<table class="layout">
					<tr>
						<td>
							<md-field>
								<label>Address</label>
								<md-input v-model="newAddress.address" id="inputEditAddressAddress"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>City</label>
								<md-input v-model="newAddress.city" id="inputEditAddressCity"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>State</label>
								<md-input v-model="newAddress.state" id="inputEditAddressState"></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Postcode</label>
								<md-input v-model="newAddress.postcode" id="inputEditAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4">
							<md-field>
								<label>Address note</label>
								<md-textarea v-model="newAddress.note" id="inputEditAddressNote" md-autogrow></md-textarea>
							</md-field>
						</td>
					</tr>
					<tr>
						<td colspan="4" align="right">
							<md-button class="md-raised md-dense md-primary" @click="addAddress(entityEdit)">Add address</md-button>
						</td>
					</tr>
				</table>
			</md-card>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new supplier" },
			dialogEdit: { show: false, title: "Edit supplier" },
			dialogDelete: {
				show: false,
				title: "Delete supplier",
				content: "Are you sure you want to delete this supplier?"
			},
			entityAdd: {
				dataMap: {},
				addressDataList: []
			},
			entityEdit: { active: false, dataMap: {} },
			entityList: [],
			id: 1,
			newAddress: {},
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/company/list?type=Supplier")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/company/search?type=Supplier", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.entityAdd = {
				dataMap: {},
				addressDataList: []
			};
			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit = {
				id: item.id,
				name: item.name,
				active: "Active" === item.status,
				phone: item.phone,
				notifiable: item.notifiable,
				addressDataList: item.addressDataList,
				dataMap: {}
			};
			if (!vm.entityEdit.addressDataList) {
				vm.entityEdit.addressDataList = [];
			} else {
				for (let i in vm.entityEdit.addressDataList) {
					vm.entityEdit.addressDataList[i].id = vm.id++;
				}
			}
			vm.dialogEdit.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/company/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			vm.entityAdd.type = "Supplier";
			ajax.post("/system/company/add", {
				entity: vm.entityAdd
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			vm.entityEdit.status = vm.entityEdit.active ? "Active" : "Inactive";
			ajax.post("/system/company/edit", {
				entity: vm.entityEdit
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addAddress(entity) {
			let vm = this;
			vm.newAddress.id = vm.id++;
			entity.addressDataList.push(vm.newAddress);
			vm.newAddress = {};
		},
		deleteAddress(entity, id) {
			let list = entity.addressDataList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					return;
				}
			}
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
	}
};
</script>
