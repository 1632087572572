<template>
	<div>
		<div class="md-title">{{company.name}} Staff</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<div style="text-align:right;margin-bottom:20px;" v-if="company.type == 'Planner'">
			<md-button class="md-raised md-primary" @click="dialogResetBudget.show = true">Reset all budget spend</md-button>
		</div>

		<md-table>
			<md-table-row>
				<md-table-head>Name</md-table-head>
				<md-table-head>Email</md-table-head>
				<template v-if="company.type == 'Planner'">
					<md-table-head>Role</md-table-head>
					<md-table-head>Manager Name</md-table-head>
					<md-table-head>Budget Available</md-table-head>
					<md-table-head>Budget Spending</md-table-head>
					<md-table-head>Budget Limit</md-table-head>
				</template>
				<md-table-head></md-table-head>
			</md-table-row>
			<template v-for="entity in entityList">
				<md-table-row :key="entity.id + 'a'" style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
					<md-table-cell>
						<span :class="entity.status">{{entity.name}}</span>
					</md-table-cell>
					<md-table-cell>{{entity.email}}</md-table-cell>
					<template v-if="company.type == 'Planner'">
						<md-table-cell>{{entity.role && entity.role.replace(/_/g, ' ')}}</md-table-cell>
						<md-table-cell>{{entity.dataMap.managerName}}</md-table-cell>
						<md-table-cell>$ {{entity.budgetAvailable}}</md-table-cell>
						<md-table-cell>$ {{entity.budgetSpend}}</md-table-cell>
						<md-table-cell>$ {{entity.budgetLimit}}</md-table-cell>
					</template>
					<md-table-cell>
						<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
							<md-icon>edit</md-icon>
						</md-button>
					</md-table-cell>
				</md-table-row>
				<template v-if="company.type == 'Planner' && $root.tenantCustomisation('preferred-printer-email')">
					<md-table-row :key="entity.id + 'b'" v-if="entity.customFieldDataList && entity.customFieldDataList.length > 0">
						<md-table-cell colspan="8">
							<div v-for="field in entity.customFieldDataList" :key="field.name">{{field.name}}: {{field.value}}</div>
						</md-table-cell>
					</md-table-row>
				</template>
			</template>
		</md-table>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<table class="layout">
				<tr>
					<td>
						<md-field>
							<label>Name</label>
							<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Email</label>
							<md-input v-model="entityAdd.email" id="inputAddEmail"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Password</label>
							<md-input v-model="entityAdd.password" type="password" id="inputAddPassword"></md-input>
						</md-field>
					</td>
				</tr>

				<tr>
					<td v-if="company && company.type != 'Customer'">
						<md-switch v-model="entityAdd.notifiable" class="md-primary" id="inputAddNotifiable">Notifiable</md-switch>
					</td>
					<td>
						<md-field>
							<label>Phone Number</label>
							<md-input v-model="entityAdd.phone" id="inputAddPhone" v-on:keypress="isPhone($event, entityAdd.phone)"></md-input>
						</md-field>
					</td>
					<td>
						<md-field v-if="company && company.type == 'Planner'">
							<label>Budget Limit</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entityAdd.budgetLimit" id="inputAddBudgetLimit"></md-input>
						</md-field>
					</td>
				</tr>
				<tr v-if="company && company.type == 'Planner'">
					<td>
						<md-field>
							<label>Manager Name</label>
							<md-input v-model="entityAdd.dataMap.managerName" id="inputAddManagerName"></md-input>
						</md-field>
					</td>
					<td colspan="2">
						<md-field>
							<label>Role</label>
							<md-select v-model="entityAdd.role" id="inputAddRole">
								<md-option v-for="item in personRoleList" :key="item" :value="item">{{item.replace(/_/g, ' ')}}</md-option>
							</md-select>
						</md-field>
					</td>
				</tr>
				<tr v-if="company && company.type == 'Planner'">
					<td>
						<md-chip class="chip-region" v-for="item in entityAdd.regionList" :key="item.region.id" md-deletable @md-delete="deleteRegion(entityAdd, item.region.id)">{{item.region.name}} {{item.approver ? "(Approver)" : ""}}</md-chip>
						<md-field>
							<label>Region</label>
							<md-select v-model="selectedRegionId" md-dense id="selectRegionAdd">
								<template v-for="item in regionList">
									<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
								</template>
							</md-select>
						</md-field>
					</td>
					<td>
						<md-switch v-model="entityAdd.approver" class="md-primary" id="inputAddApprover">Region Approver</md-switch>
					</td>
					<td>
						<md-button class="md-raised" @click="addRegion(entityAdd, selectedRegionId)">Associate Region</md-button>
					</td>
				</tr>
			</table>

			<template v-if="company && company.type == 'Planner'">
				<md-list>
					<md-list-item v-for="(entity, index) in entityAdd.addressDataList" :key="index" style="border-bottom:solid #d9d9d9 1px;">
						<div class="md-list-item-text">
							{{entity.address}}
							<br />
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							<br />
							{{entity.note}}
						</div>

						<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityAdd, index)">
							<md-icon>delete</md-icon>
						</md-button>
					</md-list-item>
				</md-list>

				<md-card style="padding:0px 10px">
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>Address</label>
									<md-input v-model="newAddress.address" id="inputAddAddressAddress"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>City</label>
									<md-input v-model="newAddress.city" id="inputAddAddressCity"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>State</label>
									<md-input v-model="newAddress.state" id="inputAddAddressState"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Postcode</label>
									<md-input v-model="newAddress.postcode" id="inputAddAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
								</md-field>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<md-field>
									<label>Address note</label>
									<md-textarea v-model="newAddress.note" id="inputAddAddressNote" md-autogrow></md-textarea>
								</md-field>
							</td>
							<td>
								<md-button class="md-raised md-dense md-primary" @click="addAddress(entityAdd)" style="margin-bottom:25px;">Add address</md-button>
							</td>
						</tr>
					</table>
				</md-card>

				<template v-if="$root.tenantCustomisation('preferred-printer-email')">
					<md-list class="md-double-line">
						<md-list-item v-for="(entity, index) in entityAdd.customFieldDataList" :key="index" style="border-bottom:solid #d9d9d9 1px;">
							<div class="md-list-item-text">
								<span>{{entity.name}}</span>
								<span>{{entity.value}}</span>
							</div>

							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteCustomField(entityAdd, index)">
								<md-icon>delete</md-icon>
							</md-button>
						</md-list-item>
					</md-list>

					<md-card style="padding:0px 10px">
						<table class="layout">
							<tr>
								<td>
									<md-field>
										<label>Name</label>
										<md-select v-model="newCustomField.name" id="inputAddCustomFieldName">
											<md-option value="Preferred Printer Email">Preferred Printer Email</md-option>
										</md-select>
									</md-field>
								</td>
								<td>
									<md-field>
										<label>Value</label>
										<md-input v-model="newCustomField.value" id="inputAddCustomFieldValue"></md-input>
									</md-field>
								</td>
								<td>
									<md-button class="md-raised md-dense md-primary" @click="addCustomField(entityAdd)" style="margin-bottom:25px;">Add custom field</md-button>
								</td>
							</tr>
						</table>
					</md-card>
				</template>
			</template>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<table class="layout">
				<tr>
					<td>
						<md-field>
							<label>Name</label>
							<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Email</label>
							<md-input v-model="entityEdit.email" id="inputEditEmail"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Password</label>
							<md-input v-model="entityEdit.password" type="password" id="inputEditPassword"></md-input>
							<span class="md-helper-text">Specify new password to change password</span>
						</md-field>
					</td>
				</tr>

				<tr>
					<td v-if="company && company.type != 'Customer'">
						<md-switch v-model="entityEdit.active" class="md-primary" id="inputEditStatus">Active</md-switch>
						<br />
						<md-switch v-model="entityEdit.notifiable" class="md-primary" id="inputEditNotifiable">Notifiable</md-switch>
					</td>
					<td>
						<md-field>
							<label>Phone Number</label>
							<md-input v-model="entityEdit.phone" id="inputEditPhone" v-on:keypress="isPhone($event, entityEdit.phone)"></md-input>
						</md-field>
					</td>
					<td v-if="company && company.type == 'Planner'">
						<md-field>
							<label>Budget Limit</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entityEdit.budgetLimit" id="inputEditBudgetLimit"></md-input>
							<span class="md-helper-text">Budget Spending $ {{entityEdit.budgetSpend}}</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="company && company.type == 'Planner'">
					<td>
						<md-field>
							<label>Manager Name</label>
							<md-input v-model="entityEdit.dataMap.managerName" id="inputEditManagerName"></md-input>
						</md-field>
					</td>
					<td colspan="2">
						<md-field>
							<label>Role</label>
							<md-select v-model="entityEdit.role" id="inputEditRole">
								<md-option v-for="item in personRoleList" :key="item" :value="item">{{item.replace(/_/g, ' ')}}</md-option>
							</md-select>
						</md-field>
					</td>
				</tr>
				<tr v-if="company && company.type == 'Planner'">
					<td>
						<md-chip class="chip-region" v-for="item in entityEdit.regionList" :key="item.region.id" md-deletable @md-delete="deleteRegion(entityEdit, item.region.id)">{{item.region.name}} {{item.approver ? "(Approver)" : ""}}</md-chip>
						<md-field>
							<label>Region</label>
							<md-select v-model="selectedRegionId" md-dense id="selectRegionEdit">
								<template v-for="item in regionList">
									<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
								</template>
							</md-select>
						</md-field>
					</td>
					<td>
						<md-switch v-model="entityEdit.approver" class="md-primary" id="inputEditApprover">Region Approver</md-switch>
					</td>
					<td>
						<md-button class="md-raised" @click="addRegion(entityEdit, selectedRegionId)">Associate Region</md-button>
					</td>
				</tr>
			</table>

			<template v-if="company && company.type == 'Planner'">
				<md-list>
					<md-list-item v-for="(entity, index) in entityEdit.addressDataList" :key="index" style="border-bottom:solid #d9d9d9 1px;">
						<div class="md-list-item-text">
							{{entity.address}}
							<br />
							{{entity.city}}
							{{entity.state}}
							{{entity.postcode}}
							<br />
							{{entity.note}}
						</div>

						<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entityEdit, index)">
							<md-icon>delete</md-icon>
						</md-button>
					</md-list-item>
				</md-list>

				<md-card style="padding:0px 10px">
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>Address</label>
									<md-input v-model="newAddress.address" id="inputEditAddressAddress"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>City</label>
									<md-input v-model="newAddress.city" id="inputEditAddressCity"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>State</label>
									<md-input v-model="newAddress.state" id="inputEditAddressState"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Postcode</label>
									<md-input v-model="newAddress.postcode" id="inputEditAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
								</md-field>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<md-field>
									<label>Address note</label>
									<md-textarea v-model="newAddress.note" id="inputEditAddressNote" md-autogrow></md-textarea>
								</md-field>
							</td>
							<td>
								<md-button class="md-raised md-dense md-primary" @click="addAddress(entityEdit)" style="margin-bottom:25px;">Add address</md-button>
							</td>
						</tr>
					</table>
				</md-card>

				<template v-if="$root.tenantCustomisation('preferred-printer-email')">
					<md-list class="md-double-line">
						<md-list-item v-for="(entity, index) in entityEdit.customFieldDataList" :key="index" style="border-bottom:solid #d9d9d9 1px;">
							<div class="md-list-item-text">
								<span>{{entity.name}}</span>
								<span>{{entity.value}}</span>
							</div>

							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteCustomField(entityEdit, index)">
								<md-icon>delete</md-icon>
							</md-button>
						</md-list-item>
					</md-list>

					<md-card style="padding:0px 10px">
						<table class="layout">
							<tr>
								<td>
									<md-field>
										<label>Name</label>
										<md-select v-model="newCustomField.name" id="inputEditCustomFieldName">
											<md-option value="Preferred Printer Email">Preferred Printer Email</md-option>
										</md-select>
									</md-field>
								</td>
								<td>
									<md-field>
										<label>Value</label>
										<md-input v-model="newCustomField.value" id="inputEditCustomFieldValue"></md-input>
									</md-field>
								</td>
								<td>
									<md-button class="md-raised md-dense md-primary" @click="addCustomField(entityEdit)" style="margin-bottom:25px;">Add custom field</md-button>
								</td>
							</tr>
						</table>
					</md-card>
				</template>
			</template>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit(true)" v-if="company && company.type == 'Planner'">Reset budget & save</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<md-dialog-confirm
			class="confirm"
			:md-active.sync="dialogResetBudget.show"
			:md-title="dialogResetBudget.title"
			:md-content="dialogResetBudget.content"
			md-confirm-text="Yes, reset all"
			md-cancel-text="No"
			@md-cancel="dialogResetBudget.show = false"
			@md-confirm="resetAllBudgetSpend"
		/>

		<md-dialog :md-active.sync="dialogAddAddress.show">
			<md-dialog-title>{{dialogAddAddress.title}}</md-dialog-title>

			<md-content>{{dialogAddAddress.missing}}</md-content>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAddAddress.show = false; dialogAddAddress.missing =''">Close</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
.md-table-cell {
	border-top: none;
	border-bottom: none;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new Staff" },
			dialogEdit: { show: false, title: "Edit Staff" },
			dialogDelete: {
				show: false,
				title: "Delete Staff",
				content: "Are you sure you want to delete this Staff?"
			},
			dialogResetBudget: {
				show: false,
				title: "Reset all budget spend",
				content: "Are you sure you want to reset all budget spend?"
			},
			entityAdd: {
				dataMap: {},
				addressDataList: [],
				customFieldDataList: [],
				regionList: []
			},
			entityEdit: {
				active: false,
				dataMap: {},
				regionList: [],
				customFieldDataList: []
			},
			entityList: [],
			company: {},
			id: 1,
			newAddress: {},
			newCustomField: { name: null, value: null },
			personRoleList: [],
			regionList: [],
			selectedRegionId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			dialogAddAddress: { show: false, title: "Address is not complete", missing: "" },
		};
	},
	mounted: function() {
		this.listPersonRole();
		this.listRegion();
		ajax.all([this.findCompany()]).then(() => {
			this.list();
		});
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/person/list?companyId=" + vm.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/person/search?companyId=" + vm.$route.params.entityId, { keyword: vm.keyword })
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.entityAdd = {
				dataMap: {},
				addressDataList: [],
				customFieldDataList: [],
				regionList: []
			};
			vm.selectedRegionId = null;
			if (vm.company.type == "Planner") {
				vm.entityAdd.budgetLimit = 700;
			}
			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit = {
				id: item.id,
				name: item.name,
				email: item.email,
				active: "Active" === item.status,
				notifiable: item.notifiable,
				phone: item.phone,
				budgetLimit: item.budgetLimit,
				budgetSpend: item.budgetSpend,
				addressDataList: this.$root.clone(item.addressDataList),
				customFieldDataList: this.$root.clone(item.customFieldDataList),
				role: item.role,
				dataMap: {
					managerName: item.dataMap && item.dataMap.managerName
				},
				approver: false
			};
			ajax.get("/system/region/link/list?personId=" + item.id)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityEdit.regionList = [];
					for (let r of response.data) {
						vm.entityEdit.regionList.push({
							region: r.region,
							approver: r.approver
						});
					}
					vm.selectedRegionId = null;
					vm.dialogEdit.show = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/person/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			vm.entityAdd.companyId = vm.$route.params.entityId;
			if (vm.entityAdd.budgetLimit === "") {
				vm.entityAdd.budgetLimit = null;
			}
			ajax.post("/system/person/add", {
				entity: vm.entityAdd
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit(resetBudget) {
			let vm = this;
			vm.dialogEdit.show = false;
			vm.entityEdit.status = vm.entityEdit.active ? "Active" : "Inactive";
			if (vm.entityEdit.budgetLimit === "") {
				vm.entityEdit.budgetLimit = null;
			}
			ajax.post("/system/person/edit", {
				entity: vm.entityEdit,
				resetBudget: resetBudget
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addAddress(entity) {
			let vm = this;
			vm.dialogAddAddress.missing = "";

			if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1 ||
				!vm.newAddress.city || vm.newAddress.city.trim().length < 1 ||
				!vm.newAddress.state || vm.newAddress.state.trim().length < 1 ||
				!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) {
					
					if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1) vm.dialogAddAddress.missing += "Address, ";
					if (!vm.newAddress.city || vm.newAddress.city.trim().length < 1) vm.dialogAddAddress.missing += "City, ";
					if (!vm.newAddress.state || vm.newAddress.state.trim().length < 1) vm.dialogAddAddress.missing += "State, ";
					if (!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) vm.dialogAddAddress.missing += "Postcode, ";
					
					vm.dialogAddAddress.missing = vm.dialogAddAddress.missing.slice(0, -2) + " required.";
					vm.dialogAddAddress.show = true;

			} else {

				entity.addressDataList.push(vm.newAddress);
				vm.newAddress = {};
			}
		},
		deleteAddress(entity, index) {
			entity.addressDataList.splice(index, 1);
		},
		addCustomField(entity) {
			let vm = this;
			if (vm.newCustomField.name != null && vm.newCustomField.value != null) {
				let customValue = vm.newCustomField.value.split("|");
				if (customValue.length == 3) {
					if (vm.newCustomField.name == "Preferred Printer Email" && !this.isValidEmail(customValue[1])) {
						vm.$root.showSnack(vm.snack, "Invalid email address format");
					} else {
						entity.customFieldDataList.push(vm.newCustomField);
						vm.newCustomField = { name: null, value: null };
					}
				} else {
					vm.$root.showSnack(vm.snack, "Please enter a valid format (Supplier Name|Preferred Printer Email|PO Number)");
				}
			} else {
				vm.$root.showSnack(vm.snack, "Field name and value are required");
			}
		},
		isValidEmail(value) {
			for (const email of value.split(",")) {
				if (!email.trim().match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
					return false;
				}
			}
			return true;
		},
		deleteCustomField(entity, index) {
			entity.customFieldDataList.splice(index, 1);
		},
		findCompany() {
			let vm = this;
			ajax.get("/system/company?id=" + vm.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.company = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPersonRole() {
			let vm = this;
			ajax.get("/system/enumeration/personrole/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personRoleList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		resetAllBudgetSpend() {
			let vm = this;
			ajax.post("/system/person/budgetspend/reset/all", {})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listRegion() {
			let vm = this;
			ajax.get("/system/region/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.regionList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addRegion(entity, selectedRegionId) {
			let vm = this;
			for (let region of vm.regionList) {
				if (region.id === selectedRegionId) {
					let found = false;
					for (let entityRegion of entity.regionList) {
						if (entityRegion.region.id == selectedRegionId) {
							found = true;
							break;
						}
					}
					if (!found) {
						entity.regionList.push({
							region: region,
							approver: entity.approver
						});
						vm.$forceUpdate();
						break;
					}
				}
			}
		},
		deleteRegion(entity, id) {
			let vm = this;
			for (let i in entity.regionList) {
				if (entity.regionList[i].region.id === id) {
					entity.regionList.splice(i, 1);
					// seems that if you construct the list from json, you need to do this
					vm.$forceUpdate();
					break;
				}
			}
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		}
	}
};
</script>