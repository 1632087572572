<template>
	<div>
		<div class="md-title">Menu Image</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" to="/administrator/menuimage/add">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<router-link :to="{path:'/administrator/menuimage/edit/' + entity.id}">{{entity.name}}</router-link>
				</div>

				<md-chip class="chip-brand" v-if="entity.brandId">{{entity.brandName}}</md-chip>

				<md-chip class="chip-inventory-type" v-if="entity.dataMap.variantList && entity.dataMap.variantList.length > 0">{{stringify(entity.dataMap.variantList)}}</md-chip>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogDelete: {
				show: false,
				title: "Delete menu image",
				content: "Are you sure you want to delete this menu image?"
			},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/menuimage/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/menuimage/search", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/product/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		stringify(list) {
			let text = "";
			for (let entry of list) {
				if (text !== "") {
					text += ", ";
				}
				text += entry;
			}
			return text;
		}
	}
};
</script>
