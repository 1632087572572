<template>
	<div>
		<div class="md-title">Edit Menu Image</div>

		<md-field>
			<label>Name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<br />

		<md-field>
			<label>Brand</label>
			<md-select v-model="entity.brandId" md-dense id="selectBrand">
				<template v-for="item in brandList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-field>
			<label>Variant</label>
			<md-select v-model="entity.dataMap.variantList" md-dense id="selectVariant" multiple>
				<template v-for="item in variantList">
					<md-option :key="item" :value="item">{{item}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-card v-if="entity.imageName">
			<media :name="entity.imageName" />

			<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entity.imageName)" style="position:absolute;right:0px;bottom:10px;">
				<md-icon>get_app</md-icon>
			</md-button>
		</md-card>

		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Supplier</label>
			<md-select v-model="entity.supplierId" md-dense id="selectSupplier">
				<md-option :key="-1" :value="''"></md-option>
				<template v-for="item in supplierList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<template>
			<table class="layout">
				<tr v-if="entity.dataMap.variantList.includes('DL') && $root.tenantCustomisation('inventory-dl')">
					<td class="md-subheading">DL</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityDL"></md-input>
							<span class="md-helper-text">leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceDL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceDL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="entity.dataMap.variantList.includes('Tent Card') && $root.tenantCustomisation('inventory-tent-card')">
					<td class="md-subheading">Tent Card</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[7].quantity" id="inputQuantityTentCard"></md-input>
							<span class="md-helper-text">leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[7].basePrice" id="inputBasePriceTentCard"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[7].tierPrice" id="inputTierPriceTentCard"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
			</table>
		</template>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/menuimage">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileEdit()">Save</md-button>
		</md-dialog-actions>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			entity: {
				dataMap: {
					variantList: []
				}
			},
			brandList: [],
			supplierList: [],
			variantList: ["DL", "Tent Card"],
			attachment: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		ajax.all([this.listBrand()]).then(() => {
			this.find();
			this.listSupplier();
		});
	},
	methods: {
		listBrand() {
			let vm = this;
			let promise = ajax.get("/system/brand/list");
			promise
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			return promise;
		},
		find() {
			let vm = this;
			ajax.get("/system/product?id=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listSupplier() {
			let vm = this;
			ajax.get("/system/company/list?type=Supplier")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.supplierList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		submitFileEdit() {
			let vm = this;

			vm.entity.supplierId = vm.entity.supplierId == "" ? null : vm.entity.supplierId;

			if (!vm.entity.dataMap.variantList.includes("DL")) {
				vm.entity.dataMap.inventory[7] = { variant: "DL", price: "", tierList: [], dataMap: {} };
			}

			if (!vm.entity.dataMap.variantList.includes("Tent Card")) {
				vm.entity.dataMap.inventory[7] = { variant: "TentCard", price: "", tierList: [], dataMap: {} };
			}

			let formData = new FormData();
			formData.append("entity", JSON.stringify(vm.entity));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			ajax.post("/system/product/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/menuimage" });
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>