<template>
	<div>
		<div class="md-display-1" style="color: black; margin: 20px 0">Cocktail Menu Builder</div>

		<md-field>
			<label>Select brand</label>
			<md-select v-model="brandId" md-dense id="selectBrand">
				<template v-for="item in brandList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<label>Select type</label>
		<br />
		<md-radio v-model="variant" v-for="item in variantList" :key="item" :value="item">{{item}}</md-radio>

		<br />
		<md-button class="md-icon-button md-raised md-primary" @click="listMenuImageAndCocktail()">
			<md-icon>search</md-icon>
		</md-button>

		<br />
		<br />

		<div style="display:flex;">
			<div v-for="item in menuImageList" :key="item.id" style="display:flex;margin:0 30px 15px 0;">
				<md-radio v-model="menuImageId" :value="item.id"></md-radio>
				<img :src="baseLocation + '/asset/file/large/' + item.imageName" />
			</div>
		</div>

		<template v-if="cocktailList.length > 0">
			<hr />

			<div class="md-title" style="color: #13294B; margin: 20px 0">Select cocktails (up to 6) and add pricing</div>

			<div v-for="item in cocktailList" :key="item.id">
				<div style="border-bottom:solid #d9d9d9 1px;margin-bottom:10px !important;">
					<div style="display:flex;align-items:center;">
						<md-checkbox v-model="cocktailIdList" :value="item.id"></md-checkbox>
						<div style="display:flex;align-items:center;justify-content:space-between;width:100%;">
							<span>
								<strong>{{item.name}}</strong>
								-
								{{item.description}}
							</span>
							<div>
								<md-field>
									<label>Price</label>
									<md-input v-model="item.price"></md-input>
								</md-field>
							</div>
						</div>
					</div>
				</div>
			</div>

			<md-button class="md-raised md-primary" @click="select()">Confirm cocktails</md-button>

			<template v-if="entity.id">
				<hr />

				<div class="md-title" style="color: #13294B; margin: 20px 0">Add custom text or logos</div>

				<div style="display:flex;">
					<div>
						<md-card>
							<md-card-media>
								<div style="padding: 10px">
									<img :src="baseLocation + '/asset/file/' + entity.dataMap.renderDataMap.draft + '?randomId=' + randomId" />
								</div>
							</md-card-media>
						</md-card>
					</div>
					<div>
						<div class="md-layout" style="margin-bottom: 5px">
							<div class="md-layout-item">
								<input type="text" v-model="fontSize" style="font-size: 20px; width: 50px; border: 1px solid #aaa; padding: 5px" disabled />
								<md-button class="md-icon-button md-raised md-dense" @click="increaseSize()" title="Increase font size">
									<md-icon>add</md-icon>
								</md-button>
								<md-button class="md-icon-button md-raised md-dense" @click="decreaseSize()" title="Decrease font size">
									<md-icon>remove</md-icon>
								</md-button>

								<select v-model="color" v-if="colorList">
									<option v-for="item in colorList" :value="item.value" :key="item.id">{{ item.name }}</option>
								</select>

								<select v-model="font" v-if="entity && entity.dataMap && entity.dataMap.fontList" style="margin-left:5px;">
									<option v-for="item in entity.dataMap.fontList" :value="item" :key="item.id">{{ item }}</option>
								</select>
							</div>
						</div>

						<table class="dense" style="margin-top: 10px;width: 100%;">
							<tr v-for="(cocktail, index) in selectedCocktailList" :key="cocktail.id">
								<div style="border-bottom:solid #d9d9d9 1px;padding-bottom:10px !important;">
									<td>
										<div class="d-flex flex-column">
											<div class="d-flex flex-inline">
												<div>
													<strong>{{cocktail.name}}</strong>
												</div>
												<div>
													<select v-model="cocktail.titleColor" style="padding: 2px; min-width: 90px;">
														<option v-for="item in getSelectCocktailColor(cocktail.brandId)" :value="item.value" :key="item.id">{{ item.name }}</option>
													</select>
													<select v-model="cocktail.titleFont" style="padding: 2px; min-width: 270px;">
														<option v-for="item in getSelectCocktailFont(cocktail.brandId)" :value="item" :key="item.id">{{ item }}</option>
													</select>
												</div>
											</div>
											<div class="d-flex flex-inline">
												<div>
													<strong>{{cocktail.description}}</strong>
												</div>
												<div>
													<select v-model="cocktail.descriptionColor" style="padding: 2px; min-width: 90px;">
														<option v-for="item in  getSelectCocktailColor(cocktail.brandId)" :value="item.value" :key="item.id">{{ item.name }}</option>
													</select>
													<select v-model="cocktail.descriptionFont" style="padding: 2px; min-width: 270px;">
														<option v-for="item in getSelectCocktailFont(cocktail.brandId)" :value="item" :key="item.id">{{ item }}</option>
													</select>
												</div>
											</div>
											<div>{{cocktail.price}}</div>
										</div>
									</td>
									<td>
										<span @click="up(index)">
											<md-icon class="pointer">arrow_drop_up</md-icon>
										</span>
										<br />
										<span @click="down(index)">
											<md-icon class="pointer">arrow_drop_down</md-icon>
										</span>
									</td>
								</div>
							</tr>
						</table>

						<md-field>
							<label>Top left logo</label>
							<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
						</md-field>

						<md-field>
							<label>Top right logo</label>
							<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
						</md-field>

						<md-field>
							<label>Custom top text line</label>
							<md-input v-model="topLine"></md-input>
						</md-field>

						<md-field>
							<label>Custom bottom text line</label>
							<md-input v-model="bottomLine"></md-input>
						</md-field>

						<div style="margin:0 10px;display:flex;justify-content:space-around;">
							<md-button class="md-raised md-dense md-primary" @click="quickRender()">Quick render</md-button>
							<md-button class="md-raised md-dense md-primary" @click="finalRender()">Download final render</md-button>
						</div>
					</div>
				</div>
			</template>
			<template v-if="entity.dataMap.inventory">
				<div>
					<div>
						Supplier:
						<b>{{ entity.supplierId ? entity.dataMap.supplierName : 'Unassigned' }}</b>
					</div>

					<div v-html="entity.description" class="desc"></div>

					<table class="layout">
						<tr v-if="variant == 'DL' && $root.tenantCustomisation('inventory-dl')">
							<td class="md-subheading">DL</td>
							<td>
								<md-field>
									<label>Quantity</label>
									<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityDL"></md-input>
									<span class="md-helper-text">{{ entity.dataMap.inventory[6].available }}</span>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Base Price</label>
									<span class="md-prefix">$</span>
									<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceDL" disabled></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Tier Price</label>
									<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceDL" disabled></md-input>
								</md-field>
							</td>
							<td>
								<md-switch v-model="printTwoSided[6]" class="md-primary">Print Two Sided</md-switch>
							</td>
						</tr>
						<tr v-if="variant == 'Tent Card' && $root.tenantCustomisation('inventory-tent-card')">
							<td class="md-subheading">Tent Card</td>
							<td>
								<md-field>
									<label>Quantity</label>
									<md-input v-model="entity.dataMap.inventory[7].quantity" id="inputQuantityTentCard"></md-input>
									<span class="md-helper-text">{{ entity.dataMap.inventory[7].available }}</span>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Base Price</label>
									<span class="md-prefix">$</span>
									<md-input v-model="entity.dataMap.inventory[7].basePrice" id="inputBasePriceTentCard" disabled></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Tier Price</label>
									<md-input v-model="entity.dataMap.inventory[7].tierPrice" id="inputTierPriceTentCard" disabled></md-input>
								</md-field>
							</td>
							<td>
								<md-switch v-model="printTwoSided[7]" class="md-primary">Print Two Sided</md-switch>
							</td>
						</tr>
					</table>
				</div>
				<div style="text-align: right; margin-bottom: 20px" v-if="entity.name && entity.status == 'Active'">
					<md-button class="md-raised md-primary" @click="addToCart()">Add to cart</md-button>
				</div>
			</template>
		</template>

		<md-dialog :md-active.sync="dialogNotification.show">
			<md-dialog-title>{{ dialogNotification.title }}</md-dialog-title>
			{{ dialogNotification.content }}
			<md-dialog-actions>
				<md-button class="md-raised" @click="closeNotificationDialog()">Ok</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
@font-face {
	font-family: "Brandon";
	src: url(/resource/tenant/font/1/Brandon.otf);
}
@font-face {
	font-family: "Brown-Bold";
	src: url(/resource/tenant/font/1/Brown-Bold.otf);
}
@font-face {
	font-family: "Elan-BQ-Regular";
	src: url(/resource/tenant/font/1/Elan-BQ-Regular.otf);
}
@font-face {
	font-family: "Arizona-Medium";
	src: url(/resource/tenant/font/1/ABCArizonaText-Medium.otf);
}
@font-face {
	font-family: "Arizona-Regular";
	src: url(/resource/tenant/font/1/ABCArizonaText-Regular.otf);
}
@font-face {
	font-family: "Elan-ITC-Bold";
	src: url(/resource/tenant/font/1/ElanITCStd-Bold.otf);
}
@font-face {
	font-family: "Elan-ITC-Medium";
	src: url(/resource/tenant/font/1/ElanITCStd-Medium.otf);
}
@font-face {
	font-family: "Sharp-Grostek-Medium";
	src: url(/resource/tenant/font/1/SharpGrotesk-Medium.otf);
}
@font-face {
	font-family: "Flama-Basic";
	src: url(/resource/tenant/font/2/Flama-Basic.otf);
}
@font-face {
	font-family: "Flama-Cond-Basic";
	src: url(/resource/tenant/font/2/Flama-Cond-Basic.ttf);
}
@font-face {
	font-family: "Flama-Condensed-Medium";
	src: url(/resource/tenant/font/2/Flama-Condensed-Medium.otf);
}
@font-face {
	font-family: "Flama-Bold";
	src: url(/resource/tenant/font/2/Flama-Bold.otf);
}
@font-face {
	font-family: "Gotham-Bold";
	src: url(/resource/tenant/font/2/Gotham-Bold.ttf);
}
@font-face {
	font-family: "DIN-Condensed-Bold";
	src: url(/resource/tenant/font/2/DIN-Condensed-Bold.ttf);
}
@font-face {
	font-family: "Futura-Bold";
	src: url(/resource/tenant/font/2/Futura-Bold.ttf);
}
@font-face {
	font-family: "Knockout-Junior-Featherweight";
	src: url(/resource/tenant/font/2/Knockout-Junior-Featherweight.otf);
}
@font-face {
	font-family: "Knockout-Featherweight";
	src: url(/resource/tenant/font/2/Knockout-Featherweight.otf);
}
@font-face {
	font-family: "Mostra-Nuova-AltD-Bold";
	src: url(/resource/tenant/font/2/Mostra-Nuova-AltD-Bold.otf);
}
@font-face {
	font-family: "HelveticaNeue-Light";
	src: url(/resource/tenant/font/2/HelveticaNeue-Light.ttf);
}
@font-face {
	font-family: "HelveticaNeue-CondensedBold";
	src: url(/resource/tenant/font/2/HelveticaNeue-CondensedBold.ttf);
}
@font-face {
	font-family: "Vanitas-ExtraBold";
	src: url(/resource/tenant/font/2/Vanitas-ExtraBold.otf);
}
@font-face {
	font-family: "Futura-Book-BT";
	src: url(/resource/tenant/font/2/Futura-Book-BT.ttf);
}
@font-face {
	font-family: "Futura-Medium-BT";
	src: url(/resource/tenant/font/2/Futura-Medium-BT.ttf);
}
@font-face {
	font-family: "BigNoodleTitling";
	src: url(/resource/tenant/font/2/BigNoodleTitling.ttf);
}
@font-face {
	font-family: "DINOT-Cond";
	src: url(/resource/tenant/font/2/DINOT-Cond.otf);
}
@font-face {
	font-family: "Garamond";
	src: url(/resource/tenant/font/2/Garamond.ttf);
}
@font-face {
	font-family: "Gotham-Medium";
	src: url(/resource/tenant/font/2/Gotham-Medium.otf);
}
@font-face {
	font-family: "Vanitas-Black";
	src: url(/resource/tenant/font/2/Vanitas-Black.otf);
}
@font-face {
	font-family: "HurmeGeometricSans1-Bold";
	src: url(/resource/tenant/font/2/HurmeGeometricSans1-Bold.otf);
}
@font-face {
	font-family: "Barlow-Bold";
	src: url(/resource/tenant/font/2/Barlow-Bold.otf);
}
@font-face {
	font-family: "DIN-Black-Regular";
	src: url(/resource/tenant/font/2/DIN-Black-Regular.otf)
}
@font-face {
	font-family: "Natural-Script-Regular";
	src: url(/resource/tenant/font/2/Natural-Script-Regular.ttf)
}
@font-face {
	font-family: "Semplicita-Light";
	src: url(/resource/tenant/font/2/Semplicita-Light.otf)
}
@font-face {
	font-family: "Semplicita-Medium";
	src: url(/resource/tenant/font/2/Semplicita-Medium.otf)
}
@font-face {
	font-family: "Gothic-821-Condensed";
	src: url(/resource/tenant/font/2/Gothic-821-Condensed.ttf)
}
@font-face {
	font-family: "HelveticaNeueLTStd-BdCn";
	src: url(/resource/tenant/font/2/HelveticaNeueLTStd-BdCn.otf)
}
@font-face {
	font-family: "Futura-Condensed-Medium.otf";
	src: url(/resource/tenant/font/2/Futura-Condensed-Medium.otf)
}
.md-checkbox {
	display: flex;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			brandId: null,
			brandList: [],
			variant: "DL",
			variantList: ["DL", "Tent Card"],
			menuImageList: [],
			menuImageId: null,
			cocktailList: [],
			cocktailIdList: [],
			fontSize: 20,
			font: null,
			spacing: 85, // hardcode
			colorList: [],
			color: "#000000",
			randomId: 0,
			entity: {
				dataMap: {
					renderDataMap: {}
				}
			},
			printTwoSided: [false, false, false, false, false, false, false, false],
			selectedCocktailList: [],
			attachment1: null,
			attachment2: null,
			topLine: null,
			bottomLine: null,
			//
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			fontBrandMap: new Map(),
			colorBrandMap: new Map(),
			dialogNotification: {
				show: false,
				title: "Notification",
				content: "",
				requireRefresh: false
			}
		};
	},
	mounted: function() {
		if (this.$route.params.entityId) {
			if (this.$route.query.variant) {
				this.variant = this.$route.query.variant;
				this.listMenuImageAndCocktail();
				this.menuImageId = parseInt(this.$route.params.entityId);
			}
		}
		this.listBrand();
		// this.find();
		// this.listCompanySegment();
	},
	methods: {
		listBrand() {
			let vm = this;
			ajax.get("/system/brand/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listMenuImageAndCocktail() {
			let vm = this;
			ajax.post("/system/menuimage/list/filter", {
				brandId: vm.brandId,
				variant: vm.variant
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.menuImageList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
			ajax.post("/system/cocktail/list/filter", {
				brandId: vm.brandId
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.cocktailList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		select() {
			let vm = this;
			ajax.post("/system/menuimage/select", {
				id: vm.menuImageId,
				cocktailIdList: vm.cocktailIdList,
				otherBrandIdList: vm.getOtherBrandList()
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;

					if (vm.entity.dataMap && vm.entity.dataMap.fontList && vm.entity.dataMap.renderDataMap && vm.entity.dataMap.renderDataMap.fontName && !vm.entity.dataMap.fontList.includes(vm.entity.dataMap.renderDataMap.fontName)) {
						vm.entity.dataMap.fontList.push(vm.entity.dataMap.renderDataMap.fontName);
						vm.fontBrandMap.set(vm.entity.brandId, vm.entity.dataMap.fontList);
					}
					if (vm.entity.dataMap && vm.entity.dataMap.renderDataMap && vm.entity.dataMap.renderDataMap.fontName) {
						vm.font = vm.entity.dataMap.renderDataMap.fontName;
					}

					let defaultColorList = [
						{ name: "Black", value: "rgb(0,0,0)" },
						{ name: "White", value: "rgb(255,255,255)" }
					];

					vm.colorList = defaultColorList;

					if (vm.entity.dataMap && vm.entity.dataMap.colorList && vm.entity.dataMap.colorList.length > 0) {
						vm.colorList = vm.entity.dataMap.colorList;
					}
					vm.color = vm.colorList[0].value;
					vm.colorBrandMap.set(vm.entity.brandId, vm.colorList);

					let transitList = vm.selectedCocktailList;
					vm.selectedCocktailList = [];

					for (let cocktail of transitList) {
						if (vm.cocktailIdList.includes(cocktail.id)) {
							vm.selectedCocktailList.push(cocktail);
						}
					}

					for (let cocktail of vm.cocktailList) {
						if (vm.cocktailIdList.includes(cocktail.id) && !vm.selectedCocktailList.includes(cocktail)) {
							vm.selectedCocktailList.push(cocktail);
						}
					}

					// Render Text Decoration Mapping
					if (vm.entity.dataMap.fontMap) {
						for (const [key, value] of Object.entries(vm.entity.dataMap.fontMap)) {
							if (!value.includes(vm.font)) {
								value.push(vm.font);
							}
							vm.fontBrandMap.set(parseInt(key), value);
						}
					}

					if (vm.entity.dataMap.colorMap) {
						for (const [key, value] of Object.entries(vm.entity.dataMap.colorMap)) {
							let isColorExist = value.length > 0;
							if (isColorExist && value.filter(x => x.value == vm.color).length == 0) {
								value.push(vm.colorList[0]);
							}
							vm.colorBrandMap.set(parseInt(key), isColorExist ? value : defaultColorList);
						}
					}

					for (let cocktail of vm.selectedCocktailList) {
						cocktail.titleColor = vm.color;
						cocktail.titleFont = vm.font;

						cocktail.descriptionColor = vm.color;
						cocktail.descriptionFont = vm.font;
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		getOtherBrandList() {
			let vm = this;
			let brandIdSet = new Set();

			if (vm.cocktailIdList.length > 0) {
				for (let cocktail of vm.cocktailList) {
					if (vm.cocktailIdList.includes(cocktail.id)) {
						brandIdSet.add(cocktail.brandId);
					}
				}
			}
			return Array.from(brandIdSet);
		},
		increaseSize() {
			this.fontSize++;
		},
		decreaseSize() {
			if (this.fontSize > 0) {
				this.fontSize--;
			}
		},
		up(index) {
			if (index > 0) {
				this.swapLine(index - 1, index);
			}
		},
		down(index) {
			if (index + 1 < this.selectedCocktailList.length) {
				this.swapLine(index, index + 1);
			}
		},
		swapLine(a, b) {
			let transit = this.selectedCocktailList[a];
			this.selectedCocktailList[a] = this.selectedCocktailList[b];
			this.selectedCocktailList[b] = transit;
			this.$forceUpdate();
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		getSelectCocktailFont(brandId) {
			return this.fontBrandMap.get(brandId);
		},
		getSelectCocktailColor(brandId) {
			return this.colorBrandMap.get(brandId);
		},
		quickRender() {
			let vm = this;

			let formData = new FormData();
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			formData.append("entity", JSON.stringify(vm.entity));
			formData.append("cocktailList", JSON.stringify(vm.selectedCocktailList));
			if (vm.topLine) {
				formData.append("topLine", JSON.stringify(vm.topLine));
			}
			if (vm.bottomLine) {
				formData.append("bottomLine", JSON.stringify(vm.bottomLine));
			}
			formData.append("font", JSON.stringify(vm.font));
			formData.append("color", JSON.stringify(vm.color));
			formData.append("size", JSON.stringify(vm.fontSize));
			formData.append("spacing", JSON.stringify(vm.spacing));

			ajax.post("/system/menuimage/render?action=quick", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					this.$nextTick(function() {
						vm.randomId++;
					});
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		finalRender() {
			let vm = this;

			let formData = new FormData();
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			formData.append("entity", JSON.stringify(vm.entity));
			formData.append("cocktailList", JSON.stringify(vm.selectedCocktailList));
			if (vm.topLine) {
				formData.append("topLine", JSON.stringify(vm.topLine));
			}
			if (vm.bottomLine) {
				formData.append("bottomLine", JSON.stringify(vm.bottomLine));
			}
			formData.append("font", JSON.stringify(vm.font));
			formData.append("color", JSON.stringify(vm.color));
			formData.append("size", JSON.stringify(vm.fontSize));
			formData.append("spacing", JSON.stringify(vm.spacing));

			ajax.post("/system/menuimage/render?action=final", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.randomId++;
					location.href = vm.baseLocation + "/asset/file/" + vm.entity.dataMap.renderDataMap.final + "?download&id=" + vm.randomId;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addToCart() {
			let vm = this;

			let formData = new FormData();
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}

			if (vm.entity.dataMap.inventory.length > 0) {
				let i = 0;
				for (let inventory of vm.entity.dataMap.inventory) {
					inventory.printTwoSided = this.printTwoSided[i];
					i++;
				}
			}

			formData.append("entity", JSON.stringify(vm.entity));
			formData.append("cocktailList", JSON.stringify(vm.selectedCocktailList));
			if (vm.topLine) {
				formData.append("topLine", JSON.stringify(vm.topLine));
			}
			if (vm.bottomLine) {
				formData.append("bottomLine", JSON.stringify(vm.bottomLine));
			}
			formData.append("font", JSON.stringify(vm.font));
			formData.append("color", JSON.stringify(vm.color));
			formData.append("size", JSON.stringify(vm.fontSize));
			formData.append("spacing", JSON.stringify(vm.spacing));

			ajax.post("/system/menuimage/addtocart", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.dialogNotification.title = "Notification";
					vm.dialogNotification.content = "Added to cart successfully";
					// require refresh to prevent same image file render on add chart
					vm.dialogNotification.requireRefresh = true;
					vm.dialogNotification.show = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		closeNotificationDialog() {
			let vm = this;
			if (vm.dialogNotification.requireRefresh) {
				window.location.reload();
			} else {
				vm.dialogNotification.show = false;
			}
		}
	}
};
</script>