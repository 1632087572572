<template>
	<div>
		<table class="position">
			<tr>
				<td>
					<div class="md-title">Company</div>
				</td>
				<td>
					<md-button class="md-icon-button md-list-action md-raised md-dense" :to="'/administrator/company/' + entity.id + '/brand'" title="Brand" v-if="entity.id">
						<md-icon>stars</md-icon>
					</md-button>
				</td>
				<td>
					<md-button class="md-icon-button md-list-action md-raised md-dense" :to="'/administrator/company/' + entity.id + '/staff'" title="Staff" v-if="entity.id">
						<md-icon>people</md-icon>
					</md-button>
				</td>
			</tr>
		</table>

		<md-field>
			<label>Name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<!--
		<md-switch v-model="active" class="md-primary" id="inputStatus">Active</md-switch>
		-->

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/dashboard">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
		</md-dialog-actions>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			active: false,
			entity: {
				dataMap: {},
				addressDataList: []
			},
			id: 1,
			newAddress: {},
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.find();
	},
	methods: {
		find() {
			let vm = this;
			ajax.get("/system/company/profile")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
					vm.active = vm.entity.status === "Active";
					if (!vm.entity.addressDataList) {
						vm.entity.addressDataList = [];
					} else {
						for (let i in vm.entity.addressDataList) {
							vm.entity.addressDataList[i].id = vm.id++;
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.entity.status = vm.active ? "Active" : "Inactive";
			ajax.post("/system/company/profile/edit", {
				entity: vm.entity
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/dashboard" });
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>