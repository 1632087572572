<template>
	<div>
		<div class="md-title">Edit Event</div>

		<md-field>
			<label>Event name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Event description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<md-field>
			<label>Event creator</label>
			<md-input v-model="entity.dataMap.creatorName" disabled></md-input>
		</md-field>

		<md-field>
			<label>Review status</label>
			<md-select v-model="entity.reviewStatus" md-dense id="selectReviewStatus">
				<template v-for="item in reviewStatusList">
					<md-option :key="item" :value="item">{{item == 'None' ? '' : item}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-field>
			<label>
				Start date:
				<span style="color:#000;" v-if="entity.begin">{{entity.dataMap.beginText}}</span>
			</label>
		</md-field>

		<md-field>
			<label>
				End date:
				<span style="color:#000;" v-if="entity.end">{{entity.dataMap.endText}}</span>
			</label>
		</md-field>

		<br />

		<md-list>
			<md-list-item v-for="item in entity.assetList" :key="item.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">{{item.name}} {{item.regionName ? ' - ' + item.regionName : ''}}</div>
				<div class="md-list-item-text">
					<table>
						<tr>
							<td>{{item.eventBookingStatus === 'None' ? '' : item.eventBookingStatus}}</td>
							<td @click="reviewAssetBooking(item, 'Approved')">
								<md-icon style="cursor:pointer;">done</md-icon>
							</td>
							<td @click="reviewAssetBooking(item, 'Declined')">
								<md-icon style="cursor:pointer;">block</md-icon>
							</td>
							<td @click="reviewAssetBooking(item, 'None')">
								<md-icon style="cursor:pointer;">clear</md-icon>
							</td>
						</tr>
					</table>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAsset(item.id)">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<md-field>
			<label>Asset</label>
			<md-select v-model="selectedAssetId" md-dense id="selectAsset">
				<md-optgroup label="Available">
					<template v-for="item in assetAvailableList">
						<md-option :key="item.id" :value="item.id">{{item.name}} {{item.regionName ? ' - ' + item.regionName : ''}}</md-option>
					</template>
				</md-optgroup>
				<md-optgroup label="Maybe Available">
					<template v-for="item in assetMaybeList">
						<md-option :key="item.id" :value="item.id">{{item.name}} {{item.regionName ? ' - ' + item.regionName : ''}}</md-option>
					</template>
				</md-optgroup>
				<md-optgroup label="Unavailable">
					<template v-for="item in assetUnavailableList">
						<md-option :key="item.id" :value="item.id">{{item.name}} {{item.regionName ? ' - ' + item.regionName : ''}}</md-option>
					</template>
				</md-optgroup>
			</md-select>
			<md-button class="md-raised" @click="addAsset()">Book asset</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="item in entity.resourceList" :key="item.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">{{item.name}}</div>
				<div class="md-list-item-text">
					<table>
						<tr>
							<td>{{item.eventResourceBookingStatus === 'None' ? '' : item.eventResourceBookingStatus}}</td>
							<td @click="reviewResourceBooking(item, 'Approved')">
								<md-icon style="cursor:pointer;">done</md-icon>
							</td>
							<td @click="reviewResourceBooking(item, 'Declined')">
								<md-icon style="cursor:pointer;">block</md-icon>
							</td>
							<td @click="reviewResourceBooking(item, 'None')">
								<md-icon style="cursor:pointer;">clear</md-icon>
							</td>
						</tr>
					</table>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteResource(item.id)">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<md-field>
			<label>Resource</label>
			<md-select v-model="selectedResourceId" md-dense id="selectResource">
				<md-optgroup label="Available">
					<template v-for="item in resourceAvailableList">
						<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
					</template>
				</md-optgroup>
				<md-optgroup label="Maybe Available">
					<template v-for="item in resourceMaybeList">
						<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
					</template>
				</md-optgroup>
				<md-optgroup label="Unavailable">
					<template v-for="item in resourceUnavailableList">
						<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
					</template>
				</md-optgroup>
			</md-select>
			<md-button class="md-raised" @click="addResource()">Book resource</md-button>
		</md-field>

		<md-chip class="md-primary" v-for="item in entity.customerList" :key="item.id" md-deletable @md-delete="deleteCustomer(item.id)">{{item.name}}</md-chip>

		<template v-if="customerList && customerList.length <= 50">
			<md-field>
				<label>Customer</label>
				<md-select v-model="selectedCustomerId" md-dense id="selectCustomer" @md-selected="addCustomer()">
					<template v-for="item in customerList">
						<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
					</template>
				</md-select>
				<md-button class="md-raised" @click="addCustomer()">Associate customer</md-button>
			</md-field>
		</template>

		<template v-else>
			<md-field>
				<table width="100%">
					<tr>
						<td>
							<select v-model="selectedCustomerId" id="selectCustomer" @change="addCustomer()">
								<option v-for="item in customerList" :value="item.id" :key="item.id">{{item.name}}</option>
							</select>
						</td>
						<td style="text-align:right;">
							<md-button class="md-raised" @click="addCustomer()">Associate customer</md-button>
						</td>
					</tr>
				</table>
			</md-field>
		</template>

		<md-chip class="md-primary" v-for="item in entity.staffList" :key="item.id" md-deletable @md-delete="deleteStaff(item.id)">{{item.name}}</md-chip>

		<md-field>
			<label>Contact</label>
			<md-select v-model="selectedStaffId" md-dense id="selectStaff" @md-selected="addStaff()">
				<template v-for="item in personList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
			<md-button class="md-raised" @click="addStaff()">Associate contact</md-button>
		</md-field>

		<md-chip class="md-primary" v-for="item in entity.managerList" :key="item.id" md-deletable @md-delete="deleteManager(item.id)">{{item.name}}</md-chip>

		<md-field>
			<label>Manager</label>
			<md-select v-model="selectedManagerId" md-dense id="selectManager" @md-selected="addManager()">
				<template v-for="item in personList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
			<md-button class="md-raised" @click="addManager()">Associate manager</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entry in entity.addressDataList" :key="entry.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span>
						{{entry.address}}
						{{entry.city}}
						{{entry.state}}
						{{entry.postcode}}
						{{entry.note}}
					</span>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entity, entry.id)">
					<md-icon>delete</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<md-card style="padding:0px 10px">
			<table class="layout">
				<tr>
					<td>
						<md-field>
							<label>Address</label>
							<md-input v-model="newAddress.address" id="inputEditAddressAddress"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>City</label>
							<md-input v-model="newAddress.city" id="inputEditAddressCity"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>State</label>
							<md-input v-model="newAddress.state" id="inputEditAddressState"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Postcode</label>
							<md-input v-model="newAddress.postcode" id="inputEditAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
						</md-field>
					</td>
				</tr>
				<tr>
					<td colspan="3">
						<md-field>
							<label>Address note</label>
							<md-textarea v-model="newAddress.note" id="inputEditAddressNote" md-autogrow></md-textarea>
						</md-field>
					</td>
					<td>
						<md-button class="md-raised md-dense md-primary" @click="addAddress(entity)" style="margin-bottom:25px;">Add address</md-button>
					</td>
				</tr>
			</table>
		</md-card>

		<md-card v-if="entity.imageName">
			<media :name="entity.imageName" />

			<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entity.imageName)" style="position:absolute;right:0px;bottom:10px;">
				<md-icon>get_app</md-icon>
			</md-button>
		</md-card>

		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 1</label>
			<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 2</label>
			<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 3</label>
			<md-file @md-change="handleFileUpload3" id="inputAttachment3" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 4</label>
			<md-file @md-change="handleFileUpload4" id="inputAttachment4" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 5</label>
			<md-file @md-change="handleFileUpload5" id="inputAttachment5" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/event">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileEdit()">Save</md-button>
		</md-dialog-actions>

		<div v-if="entity.imageDataList.length > 0">
			<div v-for="item in entity.imageDataList" :key="item">
				<md-card style="margin-bottom:5px;">
					<media :name="item" />

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(item)" style="position:absolute;right:0px;bottom:10px;">
						<md-icon>get_app</md-icon>
					</md-button>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteImage(item)" style="position:absolute;right:40px;bottom:10px;">
						<md-icon>delete</md-icon>
					</md-button>
				</md-card>
			</div>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			entity: {
				imageDataList: [],
				dataMap: {},
				customerList: [],
				staffList: [],
				managerList: []
			},
			baseLocation: ajax.defaults.baseURL,
			attachment: null,
			attachment1: null,
			attachment2: null,
			attachment3: null,
			attachment4: null,
			attachment5: null,
			customerList: [],
			selectedCustomerId: null,
			personList: [],
			id: 1,
			newAddress: {},
			selectedStaffId: null,
			selectedManagerId: null,
			assetList: [],
			assetUnavailableList: [],
			assetMaybeList: [],
			assetAvailableList: [],
			selectedAssetId: null,
			resourceList: [],
			resourceUnavailableList: [],
			resourceMaybeList: [],
			resourceAvailableList: [],
			reviewStatusList: [],
			selectedResourceId: null,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.find();
		this.listCustomer();
		this.listPerson();
		this.listAsset();
		this.listResource();
		this.listReviewStatus();
	},
	methods: {
		listCustomer() {
			let vm = this;
			ajax.get("/system/company/list?type=Customer&action=active")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.customerList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPerson() {
			let vm = this;
			ajax.get("/system/person/list?action=active")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listAsset() {
			let vm = this;
			ajax.get("/system/asset/booking/list?eventId=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.assetList = response.data;

					vm.assetUnavailableList = [];
					vm.assetMaybeList = [];
					vm.assetAvailableList = [];
					for (let i in vm.assetList) {
						let asset = vm.assetList[i];
						if (asset.dataMap && asset.dataMap.availability == "Unavailable") {
							vm.assetUnavailableList.push(asset);
						} else if (asset.dataMap && asset.dataMap.availability == "Maybe") {
							vm.assetMaybeList.push(asset);
						} else {
							vm.assetAvailableList.push(asset);
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listResource() {
			let vm = this;
			ajax.get("/system/person/booking/list?eventId=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.resourceList = response.data;

					vm.resourceUnavailableList = [];
					vm.resourceMaybeList = [];
					vm.resourceAvailableList = [];
					for (let i in vm.resourceList) {
						let resource = vm.resourceList[i];
						if (resource.dataMap && resource.dataMap.availability == "Unavailable") {
							vm.resourceUnavailableList.push(resource);
						} else if (resource.dataMap && resource.dataMap.availability == "Maybe") {
							vm.resourceMaybeList.push(resource);
						} else {
							vm.resourceAvailableList.push(resource);
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		find() {
			let vm = this;
			ajax.get("/system/event?id=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;

					if (!vm.entity.addressDataList) {
						vm.entity.addressDataList = [];
					} else {
						for (let i in vm.entity.addressDataList) {
							vm.entity.addressDataList[i].id = vm.id++;
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addAsset() {
			let vm = this;
			let list = vm.assetList;
			for (let i in list) {
				let asset = list[i];
				if (asset.id === vm.selectedAssetId) {
					if (!vm.$root.existInList(vm.entity.assetList, "id", asset.id)) {
						vm.entity.assetList.push(asset);
						break;
					}
				}
			}
		},
		deleteAsset(id) {
			let vm = this;
			let list = vm.entity.assetList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					break;
				}
			}
		},
		addResource() {
			let vm = this;
			let list = vm.resourceList;
			for (let i in list) {
				let resource = list[i];
				if (resource.id === vm.selectedResourceId) {
					if (!vm.$root.existInList(vm.entity.resourceList, "id", resource.id)) {
						resource.eventResourceBookingStatus = "None";
						vm.entity.resourceList.push(resource);
						break;
					}
				}
			}
		},
		deleteResource(id) {
			let vm = this;
			let list = vm.entity.resourceList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					break;
				}
			}
		},
		addCustomer() {
			let vm = this;
			let list = vm.customerList;
			for (let i in list) {
				let customer = list[i];
				if (customer.id === vm.selectedCustomerId) {
					if (!vm.$root.existInList(vm.entity.customerList, "id", customer.id)) {
						vm.entity.customerList.push(customer);
						break;
					}
				}
			}
		},
		deleteCustomer(id) {
			let vm = this;
			let list = vm.entity.customerList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					break;
				}
			}
		},
		addStaff() {
			let vm = this;
			let list = vm.personList;
			for (let i in list) {
				let staff = list[i];
				if (staff.id === vm.selectedStaffId) {
					if (!vm.$root.existInList(vm.entity.staffList, "id", staff.id)) {
						vm.entity.staffList.push(staff);
						break;
					}
				}
			}
		},
		deleteStaff(id) {
			let vm = this;
			let list = vm.entity.staffList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					break;
				}
			}
		},
		addManager() {
			let vm = this;
			let list = vm.personList;
			for (let i in list) {
				let manager = list[i];
				if (manager.id === vm.selectedManagerId) {
					if (!vm.$root.existInList(vm.entity.managerList, "id", manager.id)) {
						vm.entity.managerList.push(manager);
						break;
					}
				}
			}
		},
		deleteManager(id) {
			let vm = this;
			let list = vm.entity.managerList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					break;
				}
			}
		},
		addAddress(entity) {
			let vm = this;
			vm.newAddress.id = vm.id++;
			entity.addressDataList.push(vm.newAddress);
			vm.newAddress = {};
		},
		deleteAddress(entity, id) {
			let list = entity.addressDataList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					return;
				}
			}
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		handleFileUpload3(fileList) {
			this.attachment3 = fileList[0];
		},
		handleFileUpload4(fileList) {
			this.attachment4 = fileList[0];
		},
		handleFileUpload5(fileList) {
			this.attachment5 = fileList[0];
		},
		submitFileEdit() {
			let vm = this;
			let formData = new FormData();

			if (!vm.entity.dataMap) {
				vm.entity.dataMap = {};
			}

			formData.append("entity", JSON.stringify(vm.entity));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			if (vm.attachment3) {
				formData.append("attachment3", vm.attachment3);
			}
			if (vm.attachment4) {
				formData.append("attachment4", vm.attachment4);
			}
			if (vm.attachment5) {
				formData.append("attachment5", vm.attachment5);
			}
			ajax.post("/system/event/edit", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/event" });
					// vm.find();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		deleteImage(name) {
			let vm = this;
			ajax.post("/system/event/image/delete", {
				id: vm.entity.id,
				name: name
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.find();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		reviewResourceBooking(item, status) {
			let vm = this;
			item.eventResourceBookingStatus = status;
			vm.$forceUpdate();
		},
		reviewAssetBooking(item, status) {
			let vm = this;
			item.eventBookingStatus = status;
			vm.$forceUpdate();
		},
		listReviewStatus() {
			let vm = this;
			ajax.get("/system/enumeration/reviewstatus/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.reviewStatusList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
	}
};
</script>
