<template>
	<div>
		<div class="md-title">Customer</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<table style="margin:10px 0 5px 0;">
			<tr>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageFirst()">
						<md-icon>first_page</md-icon>
					</md-button>
				</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pagePrevious()">
						<md-icon>chevron_left</md-icon>
					</md-button>
				</td>
				<td>Page {{pagination.page}}</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageNext()">
						<md-icon>chevron_right</md-icon>
					</md-button>
				</td>
				<td>
					<md-button class="md-icon-button md-dense md-raised" @click="pageLast()">
						<md-icon>last_page</md-icon>
					</md-button>
				</td>
			</tr>
		</table>

		<md-list>
			<md-list-item v-for="entity in paginatedEntityList()" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<router-link :to="''">
						<span @click="show(entity)">
							{{entity.name}}
							<template v-if="entity.accountId">
								<small> - {{entity.accountId}}</small>
							</template>
							<template v-if="(entity.addressDataList && entity.addressDataList.length > 0)">
								<small> - {{entity.addressDataList[0].city}} {{entity.addressDataList[0].state}}</small>
							</template>
						</span>
					</router-link>
				</div>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogShow.show">
			<md-dialog-title>{{entity.name}}</md-dialog-title>

			<md-switch v-model="entity.active" class="md-primary" id="inputEditStatus" disabled>Active</md-switch>

			<md-field>
				<label>Sales Representative Name</label>
				<md-input v-model="entity.dataMap.salesPersonName" id="inputEditSalesPersonName" disabled></md-input>
			</md-field>

			<md-field>
				<label>Customer Number</label>
				<md-input v-model="entity.accountId" id="inputEditCustomerNumber" disabled></md-input>
			</md-field>

			<md-field>
				<label>Segment</label>
				<md-input v-model="entity.segment" id="inputEditSegment" disabled></md-input>
			</md-field>

			<md-list>
				<md-subheader>Address</md-subheader>
				<md-list-item v-for="entry in entity.addressDataList" :key="entry.id" style="border-bottom:solid #d9d9d9 1px;">
					<div class="md-list-item-text">
						<span>
							{{entry.address}}
							{{entry.city}}
							{{entry.state}}
							{{entry.postcode}}
							{{entry.note}}
						</span>
					</div>
				</md-list-item>
			</md-list>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogShow.show = false">Close</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogShow: { show: false },
			entity: { active: false, dataMap: {} },
			entityList: [],
			pagination: { page: 1, pageSize: 100, pageLast: 1 },
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/company/salesperson/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.pageCount();
					vm.pageReset();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/company/search?type=Customer", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		show(item) {
			let vm = this;
			vm.entity = {
				name: item.name,
				active: "Active" === item.status,
				addressDataList: item.addressDataList,
				accountId: item.accountId,
				segment: item.segment,
				dataMap: {
					salesPersonName: item.dataMap && item.dataMap.salesPersonName
				}
			};
			vm.dialogShow.show = true;
		},
		pageFirst() {
			this.pagination.page = 1;
		},
		pagePrevious() {
			if (this.pagination.page > 1) {
				this.pagination.page--;
			}
		},
		pageNext() {
			if (this.pagination.page < this.pagination.pageLast) {
				this.pagination.page++;
			}
		},
		pageLast() {
			this.pagination.page = this.pagination.pageLast;
		},
		pageReset() {
			this.pagination.page = 1;
		},
		pageCount() {
			this.pagination.pageLast = Math.ceil(this.entityList.length / this.pagination.pageSize);
		},
		paginatedEntityList() {
			let start = (this.pagination.page - 1) * this.pagination.pageSize;
			let end = start + this.pagination.pageSize;
			return this.entityList.slice(start, end);
		}
	}
};
</script>