import { render, staticRenderFns } from "./salesBrand.vue?vue&type=template&id=df3be140&scoped=true"
import script from "./salesBrand.vue?vue&type=script&lang=js"
export * from "./salesBrand.vue?vue&type=script&lang=js"
import style0 from "./salesBrand.vue?vue&type=style&index=0&id=df3be140&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df3be140",
  null
  
)

export default component.exports