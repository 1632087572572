<template>
	<span v-if="size < 1048576">{{ (size / 1024).toFixed(2) }} KB</span>
	<span v-else>{{ (size / 1048576).toFixed(2) }} MB</span>
</template>

<style scoped>
</style>

<script>
export default {
	data() {
		return {};
	},
	props: {
		size: {}
	}
};
</script>