<template>
	<div>
		<div class="container" v-if="this.$route.params.productType === 'limitedrelease'">
			<div v-for="entity in brandList" :key="entity.id">
				<md-card md-with-hover v-if="entity.limitedReleaseBrand">
					<div @click="selectBrand(entity)">
						<md-card-media>
							<img :src="baseLocation + '/asset/file/large/' + entity.imageName" />
						</md-card-media>
					</div>
				</md-card>
			</div>
		</div>
		<div v-else>
			<md-card v-for="entity in brandList" :key="entity.id" md-with-hover>
				<div @click="selectBrand(entity)">
					<md-card-media>
						<img :src="baseLocation + '/asset/file/large/' + entity.imageName" />
					</md-card-media>
				</div>
			</md-card>
		</div>
		<br />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
.container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.md-card {
	margin: 10px;
	height: 300px;
	width: 300px;
	vertical-align: top;
	display: inline-block;
	text-align: center;
}
.md-card img {
	max-height: 280px;
	width: auto;
	padding: 15px;
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			brandList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.listBrand();
	},
	beforeRouteUpdate (to, from, next) {
		this.lang = to.params.lang;
		this.brandList = [] 
		this.listBrand();
		next();
	},
	methods: {
		listBrand() {
			let vm = this;
			let typeProduct = '';
			if (this.$route.params.productType == 'pointofsale') typeProduct = 'PointOfSale'
			else typeProduct = 'Merchandise'
			ajax.get("/system/brand/product-exist?type=" + typeProduct)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectBrand(entity) {
			let vm = this;
			if (this.$root.tenantId() == 1) {
				vm.$router.push({
					path: "/sales/category/" + this.$route.params.productType + "/brand/" + entity.id
				});
			} else {
				vm.$router.push({
					path: "/sales/product/" + this.$route.params.productType + "/brand/" + entity.id
				});
			}
		},
		getProductBrand(type, vm, v) {
			ajax.get("/system/category/used/list?type="+ type +"&brandId=" + v.id)
				.then(response => {
					if (response.data.length > 0) vm.brandList.push(v)
				})
		}
	}
};
</script>