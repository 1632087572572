<template>
	<div>
		<template v-if="$root.tenantCustomisation('priority-product')">

			<md-field>
				<label>Search</label>
				<md-input v-model="search.keyword" required id="inputSearch" v-on:keyup.enter="searchItem()"></md-input>

				<md-button class="md-icon-button md-raised md-dense" @click="searchReset()">
					<md-icon>close</md-icon>
				</md-button>
				<md-button class="md-icon-button md-raised md-dense" @click="searchItem()">
					<md-icon>search</md-icon>
				</md-button>
			</md-field>

			<div class="md-title" id="pageDashboard" v-if="search.show">Search Result</div>
			<br />
			<md-list>
				<md-list-item v-for="entity in searchList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
					</md-avatar>

					<div class="md-list-item-text">
						<router-link v-if="entity.type=='PointOfSale'" :to="{path: ($root.tenantId() == 1 ? '/administrator/pointofsale/sw/edit/' : '/administrator/pointofsale/edit/') + entity.id}" :class="entity.status">{{entity.name}}</router-link>
						<router-link v-else :to="{path: ($root.tenantId() == 1 ? '/administrator/merchandise/sw/edit/' : '/administrator/merchandise/edit/') + entity.id}" :class="entity.status">{{entity.name}}</router-link>
					</div>

					<md-button v-if="$root.tenantCustomisation('priority-product')" class="md-raised md-primary" :disabled="entity.priority != null" @click="addPriority(entity)">{{ entity.priority ? "In new list" : "Add to new list" }}</md-button>

					<md-chip class="chip-inventory-type" v-if="entity.customizable">Customizable</md-chip>

					<md-chip class="chip-auto-approve" v-if="$root.tenantCustomisation('auto-approve-switch') && entity.autoApprove">Auto approve</md-chip>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>
			<div v-if="!$root.loader.value && searchList.length == 0 && search.show">Empty List</div>


			<div class="md-title" id="pageDashboard" v-if="!search.show">New Items</div>
			<br />
			<md-list>
				<md-list-item v-for="entity in priorityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
					</md-avatar>

					<div class="md-list-item-text">
						<router-link :to="{path: ($root.tenantId() == 1 ? '/administrator/pointofsale/sw/edit/' : '/administrator/pointofsale/edit/') + entity.id}" :class="entity.status">{{entity.name}}</router-link>
					</div>

					<md-button v-if="$root.tenantCustomisation('priority-product')" class="md-raised md-primary" :disabled="entity.priority != null" @click="addPriority(entity)">{{ entity.priority ? "In new list" : "Add to new list" }}</md-button>

					<md-chip class="chip-inventory-type" v-if="entity.customizable">Customizable</md-chip>

					<md-chip class="chip-auto-approve" v-if="$root.tenantCustomisation('auto-approve-switch') && entity.autoApprove">Auto approve</md-chip>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>
			<div v-if="!$root.loader.value && priorityList.length == 0 && !search.show">Empty List</div>
			
			<br /><br />
			<div class="md-title" id="pageDashboard" v-if="!search.show">Core Point of Sales</div>
			<br />
			<md-list>
				<md-list-item v-for="entity in coreList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
					</md-avatar>

					<div class="md-list-item-text">
						<router-link :to="{path: ($root.tenantId() == 1 ? '/administrator/merchandise/sw/edit/' : '/administrator/merchandise/edit/') + entity.id}" :class="entity.status">{{entity.name}}</router-link>
					</div>

					<md-button v-if="$root.tenantCustomisation('priority-product')" class="md-raised md-primary" :disabled="entity.priority != null" @click="addPriority(entity)">{{ entity.priority ? "In new list" : "Add to new list" }}</md-button>

					<md-chip class="chip-inventory-type" v-if="entity.customizable">Customizable</md-chip>

					<md-chip class="chip-auto-approve" v-if="$root.tenantCustomisation('auto-approve-switch') && entity.autoApprove">Auto approve</md-chip>

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectedId = entity.id; dialogDelete.show = true;">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
			</md-list>
			<div v-if="!$root.loader.value && coreList.length == 0 && !search.show">Empty List</div>

			<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

			<br />
		</template>

		<template v-else>
			<div class="md-display-1">What's New</div>
			<br />

			<div class="md-title" id="pageDashboard">Order</div>
			<md-list>
				<md-list-item v-for="entity in productOrderList" :key="entity.id">
					<md-avatar class="square">
						<md-icon>receipt</md-icon>
					</md-avatar>

					<div class="md-list-item-text">
						<span class="label">Order Id:</span>
						{{entity.id}}
						<span class="label">Created:</span>
						{{entity.dataMap.createdText}}
						<span class="label">Delivery:</span>
						{{entity.deliveryAddress}}
						<span class="label">Delivery Phone Number:</span>
						{{entity.deliveryPhone}}
						<span class="label">Attention To:</span>
						{{entity.attentionTo == null ? "N/A" : entity.attentionTo == "" ? "N/A" : entity.attentionTo}}
						<span class="label">Note:</span>
						{{entity.note == null ? "N/A" : entity.note == "" ? "N/A" : entity.note}}
					</div>
				</md-list-item>
			</md-list>

			<template v-if="$root.tenantId() != 2">
				<div class="md-title" id="pageDashboard">Merchandise</div>
				<md-list>
					<md-list-item v-for="entity in merchandiseList" :key="entity.id">
						<md-avatar>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</md-avatar>

						<div class="md-list-item-text">
							<router-link :to="{path:'/administrator/merchandise/edit/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
						</div>
					</md-list-item>
				</md-list>
			</template>

			<div class="md-title" id="pageDashboard">Point Of Sale</div>
			<md-list>
				<md-list-item v-for="entity in pointOfSaleList" :key="entity.id">
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
					</md-avatar>

					<div class="md-list-item-text">
						<router-link :to="{path:'/administrator/pointofsale/edit/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
					</div>
				</md-list-item>
			</md-list>

			<template v-if="$root.tenantId() != 2">
				<div class="md-title" id="pageDashboard">Limited Release</div>
				<md-list>
					<md-list-item v-for="entity in limitedReleaseList" :key="entity.id">
						<md-avatar>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</md-avatar>

						<div class="md-list-item-text">
							<router-link :to="{path:'/administrator/limitedrelease/edit/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
						</div>
					</md-list-item>
				</md-list>

				<div class="md-title" id="pageDashboard">Event</div>
				<md-list>
					<md-list-item v-for="entity in eventList" :key="entity.id">
						<md-avatar>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</md-avatar>

						<div class="md-list-item-text">
							<router-link :to="{path:'/administrator/event/edit/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
						</div>
					</md-list-item>
				</md-list>

				<div class="md-title" id="pageDashboard">Asset</div>
				<md-list>
					<md-list-item v-for="entity in assetList" :key="entity.id">
						<md-avatar>
							<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
						</md-avatar>

						<div class="md-list-item-text">
							<router-link :to="{path:'/administrator/asset/edit/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
						</div>
					</md-list-item>
				</md-list>
			</template>
		</template>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
.label {
	color: #aaa;
	font-size: 12px;
	display: inline-block;
}
.md-card {
	margin: 10px;
	vertical-align: center;
	display: inline-block;
	text-align: center;
}
.md-card img {
	max-height: 230px;
	width: auto;
	max-width: 230px;
	padding: 15px;
}
div.card {
	text-align: center;
	border-radius: 5px;
	margin: 5px;
	width: 300px;
	height: 300px;
	display: inline-block;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	will-change: background-color, box-shadow;
}
div.card img {
	padding: 5px;
	max-height: 250px;
}
div.text {
	font-size: 24px;
	letter-spacing: 0;
	line-height: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 260px;
	padding: 0 15px;
}
div.card:hover {
	cursor: pointer;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			productOrderList: [],
			merchandiseList: [],
			pointOfSaleList: [],
			limitedReleaseList: [],
			eventList: [],
			assetList: [],
			priorityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			search: { show: false, keyword: ""},
			dialogDelete: {
				show: false,
				title: "Delete item",
				content: "Are you sure you want to delete this item?"
			},
			coreList: [],
			searchList: [],
		};
	},
	mounted: function() {
		let vm = this;

		if (this.$store.state.searchKeyword === null) {
			if (vm.$root.tenantCustomisation('priority-product')) {
			this.listPriority();
			this.listCorepos();
			} else {
				this.list("ProductOrder");
				this.list("Merchandise");
				this.list("PointOfSale");
				this.list("LimitedRelease");
				this.list("Event");
				this.list("Asset");
			}
		} else {
			vm.search.keyword = this.$store.state.searchKeyword;
			this.searchItem(this.$store.state.searchKeyword);
		}
		
	},
	methods: {
		list(type) {
			let vm = this;
			if (type === "Merchandise") {
				ajax.get("/system/notification/product/list?type=Merchandise")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.merchandiseList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else if (type === "PointOfSale") {
				ajax.get("/system/notification/product/list?type=PointOfSale")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.pointOfSaleList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else if (type === "LimitedRelease") {
				ajax.get("/system/notification/product/list?type=LimitedRelease")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.limitedReleaseList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else if (type === "Event") {
				ajax.get("/system/notification/event/list")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.eventList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else if (type === "Asset") {
				ajax.get("/system/notification/asset/list")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.assetList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			} else if (type === "ProductOrder") {
				ajax.get("/system/notification/productorder/list")
					.then(response => {
						vm.$root.logSuccess(response);
						vm.productOrderList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		selectProduct(type, entity) {
			let vm = this;
			type = type.toLowerCase();
			vm.$router.push({
				path: "/sales/" + type + "/view/" + entity.id
			});
		},
		listPriority() {
			let vm = this;
			ajax.get("/system/product/priority/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.priorityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listCorepos() {
			let vm = this;
			ajax.get("/system/product/corepos/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.coreList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		searchItem(stateKeyword) {
			let vm = this;
			let keyword = stateKeyword != null ? stateKeyword : vm.search.keyword;

			vm.searchList = []
			if (keyword.length < 1 || (keyword && keyword.trim() == "")) {
				vm.search.show = false;
				this.$store.commit("fillSearchKeyword", null);
				if (vm.$root.tenantCustomisation('priority-product')) {
					this.listPriority();
					this.listCorepos();
				} else {
					this.list("ProductOrder");
					this.list("Merchandise");
					this.list("PointOfSale");
					this.list("LimitedRelease");
					this.list("Event");
					this.list("Asset");
				}
			} else {
				vm.search.show = true;
				this.$store.commit("fillSearchKeyword", keyword);
				// search all product type
				ajax.post("/system/product/search", {
					keyword: keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.searchList = response.data;
						vm.priorityList = []
						vm.coreList = []
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		searchReset() {
			let vm = this;
			vm.search.show = false;
			vm.search.keyword = "";
			this.searchItem();
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/product/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					this.searchItem();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addPriority(product) {
			let vm = this;
			ajax.post("/system/product/priority/add", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					this.searchItem();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>