<template>
	<div>
		<md-button class="md-icon-button md-raised md-dense" @click="$router.go(-1)">
			<md-icon>keyboard_backspace</md-icon>
		</md-button>

		<div v-if="isPermitted">

			<div style="text-align: right; margin-bottom: 20px" v-if="entity.type && entity.type == 'LimitedRelease'">
				<md-button class="md-raised md-primary" @click="reportLimitedRelease()">
					<md-icon>save_alt</md-icon>Download limited release report
				</md-button>
			</div>
	
			<div class="md-display-1" style="color: black; margin: 20px 0">
				<span :class="entity.status">{{ entity.name }}</span>
			</div>
	
			<md-chip class="chip-product-type">{{ entity.productType }}</md-chip>
			<md-chip class="chip-brand">{{ entity.dataMap.brandName }}</md-chip>
			<md-chip class="chip-inventory-type">{{ entity.inventoryType }}</md-chip>
	
			<br />
	
			<div class="md-heading" style="margin: 5px 0" v-if="entity.due">Due {{ entity.dataMap.dueText }}</div>
			<div class="md-heading" style="margin: 5px 0" v-if="entity.notify">Notify {{ entity.dataMap.notifyText }}</div>
	
			<div v-if="entity.supplierId">Supplier: {{ entity.dataMap.supplierName }}</div>
	
			<md-switch v-model="entity.customizable" class="md-primary" disabled>Customisable</md-switch>
	
			<md-switch v-model="entity.autoApprove" class="md-primary" disabled v-if="$root.tenantCustomisation('auto-approve-switch')">Auto approve</md-switch>
	
			<table style="margin-left: -0.25em;">
				<tr>
					<td>
						<div class="md-title" style="font-size: 1.2em;">Product ID</div>
					</td>
					<td>
						<div class="md-title" style="margin-left:50px;font-size: 1.2em;">{{ entity.id }}</div>
					</td>
				</tr>
			</table>
	
			<div style="margin-top: 1.5em;" v-html="entity.description" class="desc"></div>
	
			<template v-if="$root.tenantCustomisation('sales-note')">
				<label for="salesNoteDesc" style="text-decoration: underline;font-weight: bold;">SALES NOTE : </label>
				<div v-html="entity.salesNote" class="desc" id="salesNoteDesc"></div>
	
				<md-field>
					<label for="inputSalesNote">Sales Note :</label>
					<md-textarea v-model="entity.salesNote" id="inputSalesNote" md-autogrow></md-textarea>
				</md-field>
				<div style="text-align: right; margin-bottom: 20px">
					<md-button class="md-raised md-primary" @click="saveNote()">Save Note</md-button>
				</div>
			</template>
	
			<template v-if="entity.customizable">
				<md-dialog :md-active.sync="showDialogDrive">
					<md-dialog-title>Select image</md-dialog-title>
	
					<md-dialog-actions>
						<md-button class="md-raised" @click="showDialogDrive = false">Close</md-button>
					</md-dialog-actions>
	
					<md-list>
						<md-list-item v-for="entity in driveList" :key="entity.id">
							<md-avatar
								v-if="
									!entity.folder &&
									entity.name &&
									(entity.name.toLowerCase().endsWith('.jpg') ||
										entity.name.toLowerCase().endsWith('.jpeg') ||
										entity.name.toLowerCase().endsWith('.png') ||
										entity.name.toLowerCase().endsWith('.gif') ||
										entity.name.toLowerCase().endsWith('.bmp') ||
										entity.name.toLowerCase().endsWith('.svg'))
								"
							>
								<img :src="baseLocation + '/asset' + entity.pathName + '?cache&size=medium'" />
							</md-avatar>
							<md-avatar class="square" v-else-if="entity.folder">
								<md-icon>folder</md-icon>
							</md-avatar>
							<md-avatar class="square" v-else>
								<md-icon>attachment</md-icon>
							</md-avatar>
	
							<div class="md-list-item-text" style="margin-right: 10px; max-width: 500px; text-overflow: ellipsis">
								<span v-if="entity.folder" @click="
										drivePath = drivePath + ':' + $root.encodePathName(entity.name);
										listDrive();
									" style="text-decoration: underline; cursor: pointer; color: #448aff">{{ entity.name }}</span>
	
								<span v-else>{{ entity.name }}</span>
							</div>
	
							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="selectDrive(entity.pathName)" v-if="!entity.folder">
								<md-icon>done</md-icon>
							</md-button>
	
							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="download(entity.pathName)" v-if="!entity.folder">
								<md-icon>get_app</md-icon>
							</md-button>
						</md-list-item>
						<md-list-item></md-list-item>
					</md-list>
				</md-dialog>
	
				<table width="100%">
					<tr>
						<td :width="entity.dataMap.renderDataMap.draftWidth + 20 + 'px'" style="vertical-align: top"></td>
						<td style="vertical-align: top">
							<template v-if="mode == 'line'">
								<div class="md-layout" style="margin-bottom: 5px">
									<div class="md-layout-item">
										<md-button class="md-icon-button md-raised md-dense" @click="alignLeft()" title="Left justify">
											<md-icon>format_align_left</md-icon>
										</md-button>
										<md-button class="md-icon-button md-raised md-dense" @click="alignCenter()" title="Center justify">
											<md-icon>format_align_center</md-icon>
										</md-button>
										<md-button class="md-icon-button md-raised md-dense" @click="alignRight()" title="Right justify">
											<md-icon>format_align_right</md-icon>
										</md-button>
										
										<template v-if="!$root.tenantCustomisation('custom-pos-type') || !isLimitedTypeCustomisable()">
											<md-button class="md-icon-button md-raised md-dense" @click="increaseSize()" title="Increase font size/image height">
												<md-icon>add</md-icon>
											</md-button>
											<md-button class="md-icon-button md-raised md-dense" @click="decreaseSize()" title="Decrease font size/image height">
												<md-icon>remove</md-icon>
											</md-button>
										</template>
	
	
										<span style="font-size: 20px" title="Font size/image height">{{ selection && styleList[selection] && styleList[selection].size }}</span>
										
										<template v-if="!$root.tenantCustomisation('custom-pos-type') || !isLimitedTypeCustomisable()">
											<input type="text" v-model="newSize" style="font-size: 20px; width: 50px; border: 1px solid #aaa; padding: 5px" />
											<md-button class="md-icon-button md-raised md-dense" @click="setSize(newSize)" title="Set new size">
												<md-icon>format_size</md-icon>
											</md-button>
										</template>
	
										<md-button class="md-icon-button md-raised md-dense" @click="indentLeft()" title="Move image to the left">
											<md-icon>arrow_back</md-icon>
										</md-button>
										<md-button class="md-icon-button md-raised md-dense" @click="indentRight()" title="Move image to the right">
											<md-icon>arrow_forward</md-icon>
										</md-button>
	
										<select v-model="color" v-if="colorList">
											<option v-for="item in colorList" :value="item.value" :key="item.id">{{ item.name }}</option>
										</select>
	
										<md-button class="md-icon-button md-raised md-dense" @click="applyColor()" title="Apply color to selected line">
											<md-icon>palette</md-icon>
										</md-button>
	
										<md-button class="md-icon-button md-raised md-dense" style="background-color:grey;" @click="applyColorAll()" title="Apply color to all">
											<md-icon style="color:white;">palette</md-icon>
										</md-button>
	
										<template v-if="!$root.tenantCustomisation('custom-pos-type') || !isLimitedTypeCustomisable()">
											<select v-model="font" v-if="entity && entity.dataMap && entity.dataMap.fontList">
												<option v-for="item in entity.dataMap.fontList" :value="item" :key="item.id">{{ item }}</option>
											</select>
	
											<md-button class="md-icon-button md-raised md-dense" @click="applyFont()" title="Apply font to selected line">
												<md-icon>text_format</md-icon>
											</md-button>
	
											<md-button class="md-icon-button md-raised md-dense" style="background-color:grey;" @click="applyFontAll()" title="Apply font to all">
												<md-icon style="color:white;">text_format</md-icon>
											</md-button>
										</template>
	
										<template v-if="$root.tenantCustomisation('custom-pos-type') && isLimitedTypeCustomisable()">
											<select v-model="selectedPredefineStyle" md-dense id="selectOptions" @change="updatePredefineStyle" :title="titlePredefineStyle">
												<template v-for="item in predefineStyles">
													<option :key="item.title" :value="item">{{item.title}}</option>
												</template>
											</select>
										</template>
	
										<template v-if="$root.tenantId() != 2">
											<md-button class="md-raised md-dense" @click="
													driveType = 'drive';
													drivePath = ':';
													listDrive();
												">Media</md-button>
										</template>
	
										<template v-if="$root.tenantId() != 1">
											<md-button class="md-raised md-dense" @click="
													driveType = 'mydrive';
													drivePath = ':';
													listDrive();
												">My Drive</md-button>
										</template>
	
										<md-button class="md-raised md-dense" @click="reset()">Reset</md-button>
									</div>
								</div>
	
								<div class="md-layout" style="margin-bottom: 5px">
									<div class="md-layout-item">
										<md-button class="md-raised md-dense md-primary" @click="quickRender()">Quick render</md-button>
										<template v-if="!$root.tenantCustomisation('restrict-download')">
											<md-button class="md-raised md-dense md-primary" @click="finalRender()">Download final render</md-button>
										</template>
										<template v-if="$root.tenantCustomisation('draft')">
											<md-button class="md-raised md-dense md-primary" @click="dialogDraft()">Save as draft</md-button>
										</template>
										<!--
										<md-button class="md-raised md-dense md-accent" @click="mode = 'point'">Switch to point mode</md-button>
										-->
									</div>
								</div>
							</template>
							<template v-if="mode == 'point'">
								<div class="md-layout" style="margin-bottom: 5px">
									<div class="md-layout-item">
										<md-button class="md-raised md-dense" @click="addItemText()">Text</md-button>
										<md-button class="md-raised md-dense" @click="
												driveType = 'drive';
												drivePath = ':';
												listDrive();
											">Media</md-button>
										<md-button class="md-raised md-dense" @click="
												driveType = 'mydrive';
												drivePath = ':';
												listDrive();
											">My Drive</md-button>
									</div>
								</div>
	
								<div class="md-layout" style="margin-bottom: 5px">
									<div class="md-layout-item">
										<md-button class="md-raised md-dense md-primary" @click="quickRender()">Quick render</md-button>
										<md-button class="md-raised md-dense md-primary" @click="finalRender()">Download final render</md-button>
										<md-button class="md-raised md-dense md-accent" @click="mode = 'line'">Switch to line mode</md-button>
									</div>
									<div style="padding: 10px 0 0 0; text-align: center">{{ instruction }}</div>
								</div>
	
								<div class="md-layout">
									<div class="md-layout-item">
										<table>
											<tr v-for="item in itemList" :key="item.id">
												<td width="100%">
													<div v-if="item.type == 'text'">
														<input type="text" v-model="item.value" class="slug" :style="item.style" />
													</div>
													<div v-if="item.type == 'image'" :style="item.style">
														<img :src="baseLocation + '/asset' + item.value" :style="item.style" />
													</div>
	
													<div style="height: 5px"></div>
	
													<md-button class="md-icon-button md-raised md-dense" @click="placeItem(item, 'left')" title="Place item">
														<md-icon :style="item.iconStyle">create</md-icon>
													</md-button>
	
													<md-button class="md-icon-button md-raised md-dense" @click="increaseItemSize(item)" title="Increase font size/image height">
														<md-icon>add</md-icon>
													</md-button>
													<md-button class="md-icon-button md-raised md-dense" @click="decreaseItemSize(item)" title="Decrease font size/image height">
														<md-icon>remove</md-icon>
													</md-button>
	
													<select v-model="item.style.color" v-if="item.type == 'text'">
														<option value="#000">Black</option>
														<option value="#fff">White</option>
													</select>
													Size: {{ item.style.size }}
													<md-button class="md-icon-button md-raised md-dense" @click="deleteItem(item.id)">
														<md-icon>delete</md-icon>
													</md-button>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</template>
						</td>
					</tr>
					<tr>
						<td style="vertical-align: top">
							<md-card>
								<md-card-media>
									<div style="padding: 10px">
										<img :src="baseLocation + '/asset/file/' + entity.dataMap.renderDataMap.draft + '?randomId=' + randomId" @click="locate($event)" />
									</div>
								</md-card-media>
							</md-card>
						</td>
						<td style="vertical-align: top">
							<template v-if="mode == 'line'">
								<div class="md-layout">
									<div class="md-layout-item">
										<table class="dense" style="margin-top: 10px">
											<tr v-for="index in indexList" :key="index">
												<td>
													<md-radio v-model="selection" :value="index"></md-radio>
												</td>
												<td v-if="!$root.tenantCustomisation('editable-slug')">
													<span @click="up(index)">
														<md-icon class="pointer">arrow_drop_up</md-icon>
													</span>
													<br />
													<span @click="down(index)">
														<md-icon class="pointer">arrow_drop_down</md-icon>
													</span>
												</td>
												<td width="100%">
													<template v-if="elementList[index].type == 'text'">
														<template v-if="$root.tenantCustomisation('editable-slug')">
															<template v-if="entity.editableSlugMap[index + 1]">
																<input type="text" v-model="elementList[index].value" :maxlength="elementList[index].maxCharacters" class="slug" :style="styleList[index]" @focus="selection = index" ref="positionTextList" />
															</template>
															<template v-else>
																<input type="text" v-model="elementList[index].value" class="slug-non-editable" :style="styleList[index]" @focus="selection = index" ref="positionTextList" disabled="true" />
															</template>
														</template>
														<template v-else>
															<input type="text" v-model="elementList[index].value" class="slug" :style="styleList[index]" @focus="selection = index" ref="positionTextList" />
														</template>
													</template>
													<div v-if="elementList[index].type == 'image'" :style="styleList[index]">
														<img :src="baseLocation + '/asset' + elementList[index].value" :style="styleList[index]" ref="positionImageList" />
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</template>
						</td>
					</tr>
				</table>
	
				<div style="height: 20px"></div>
			</template>
	
			<template v-if="entity.customizable"></template>
			<template v-else>
				<md-card v-if="entity.imageName" style="margin: 10px 0">
					<media :name="entity.imageName" />
	
					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entity.imageName)" style="position: absolute; right: 0px; bottom: 10px">
						<md-icon>get_app</md-icon>
					</md-button>
				</md-card>
			</template>
	
			<template v-if="entity.inventoryType == 'OneSize'">
				<table class="layout">
					<tr>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantity" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[0].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePrice" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPrice" disabled></md-input>
							</md-field>
						</td>
					</tr>
				</table>
			</template>
	
			<template v-if="entity.inventoryType == 'MultiSize'">
				<table class="layout">
					<tr>
						<td class="md-subheading">XS</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityXS" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[0].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceXS" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceXS" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">S</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityS" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[1].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceS" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceS" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">M</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[2].quantity" id="inputQuantityM" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[2].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[2].basePrice" id="inputBasePriceM" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[2].tierPrice" id="inputTierPriceM" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">L</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[3].quantity" id="inputQuantityL" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[3].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[3].basePrice" id="inputBasePriceL" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[3].tierPrice" id="inputTierPriceL" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">XL</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[4].quantity" id="inputQuantityXL" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[4].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[4].basePrice" id="inputBasePriceXL" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[4].tierPrice" id="inputTierPriceXL" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">XXL</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[5].quantity" id="inputQuantityXXL" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[5].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[5].basePrice" id="inputBasePriceXXL" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[5].tierPrice" id="inputTierPriceXXL" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr v-if="entity.dataMap.inventory[6]">
						<td class="md-subheading">XXXL</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityXXXL" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[6].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceXXXL" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceXXXL" disabled></md-input>
							</md-field>
						</td>
					</tr>
				</table>
			</template>
	
			<template v-if="entity.inventoryType == 'Paper'">
				<table class="layout">
					<tr>
						<td class="md-subheading">A0</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityA0" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[0].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceA0" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceA0" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">A1</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityA1" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[1].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceA1" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceA1" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">A2</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[2].quantity" id="inputQuantityA2" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[2].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[2].basePrice" id="inputBasePriceA2" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[2].tierPrice" id="inputTierPriceA2" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">A3</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[3].quantity" id="inputQuantityA3" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[3].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[3].basePrice" id="inputBasePriceA3" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[3].tierPrice" id="inputTierPriceA3" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">A4</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[4].quantity" id="inputQuantityA4" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[4].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[4].basePrice" id="inputBasePriceA4" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[4].tierPrice" id="inputTierPriceA4" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">A5</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[5].quantity" id="inputQuantityA5" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[5].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[5].basePrice" id="inputBasePriceA5" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[5].tierPrice" id="inputTierPriceA5" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr v-if="$root.tenantCustomisation('inventory-dl') && entity.dataMap.inventory.length >= 7">
						<td class="md-subheading">DL</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityDL" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[6].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceDL" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceDL" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr v-if="$root.tenantCustomisation('inventory-tent-card') && entity.dataMap.inventory.length >= 8">
						<td class="md-subheading">Tent Card</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[7].quantity" id="inputQuantityTentCard" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[7].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[7].basePrice" id="inputBasePriceTentCard" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[7].tierPrice" id="inputTierPriceTentCard" disabled></md-input>
							</md-field>
						</td>
					</tr>
				</table>
			</template>
	
			<template v-if="entity.inventoryType == 'KegCarton' && entity.type != 'LimitedRelease'">
				<table class="layout">
					<tr>
						<td class="md-subheading">Keg</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityKeg" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[0].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceKeg" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceKeg" disabled></md-input>
							</md-field>
						</td>
					</tr>
					<tr>
						<td class="md-subheading">Carton</td>
						<td>
							<md-field>
								<label>Quantity</label>
								<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityCarton" type="number"></md-input>
								<span class="md-helper-text">{{ entity.dataMap.inventory[1].available }}</span>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Base Price</label>
								<span class="md-prefix">$</span>
								<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceCarton" disabled></md-input>
							</md-field>
						</td>
						<td>
							<md-field>
								<label>Tier Price</label>
								<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceCarton" disabled></md-input>
							</md-field>
						</td>
					</tr>
				</table>
			</template>
	
			<template v-if="entity.type == 'LimitedRelease'">
				<div v-if="allocation">
					<fieldset>
						<legend>Keg</legend>
						Allocated: {{ allocation.keg }}
						<br />
						Ordered: {{ allocation.kegOrdered }}
						<br />
						Available: {{ allocation.keg ? allocation.keg - allocation.kegOrdered : "0" }}
					</fieldset>
	
					<fieldset>
						<legend>Carton</legend>
						Allocated: {{ allocation.carton }}
						<br />
						Ordered: {{ allocation.cartonOrdered }}
						<br />
						Available: {{ allocation.carton ? allocation.carton - allocation.cartonOrdered : "0" }}
					</fieldset>
				</div>
	
				<md-field style="max-width: 250px">
					<label>Segment</label>
					<md-select v-model="selectedCompanySegment" md-dense id="selectCompanySegment">
						<template v-for="entry in companySegmentList">
							<md-option :key="entry.id" :value="entry.id">{{ entry.name }}</md-option>
						</template>
					</md-select>
				</md-field>
	
				<md-table>
					<md-table-row>
						<md-table-head>Name</md-table-head>
						<md-table-head>Keg</md-table-head>
						<md-table-head>Carton</md-table-head>
						<md-table-head>Segment</md-table-head>
						<md-table-head>Delivery Address</md-table-head>
						<md-table-head>Delivery Date/Note</md-table-head>
					</md-table-row>
					<md-table-row v-for="entity in filteredCustomerList()" :key="entity.id">
						<md-table-cell>{{ entity.name }}</md-table-cell>
						<md-table-cell>
							<input type="text" v-model="entity.dataMap.keg" size="3" class="box" number />
						</md-table-cell>
						<md-table-cell>
							<input type="text" v-model="entity.dataMap.carton" size="3" class="box" number />
						</md-table-cell>
						<md-table-cell>{{ entity.segment }}</md-table-cell>
						<md-table-cell>
							<textarea v-model="entity.dataMap.deliveryAddress" rows="2" cols="50" />
						</md-table-cell>
						<md-table-cell>
							<textarea v-model="entity.dataMap.note" rows="2" cols="50" />
						</md-table-cell>
					</md-table-row>
					<md-table-row>
						<md-table-head>Total</md-table-head>
						<md-table-head>{{ totalKegSegment }}</md-table-head>
						<md-table-head>{{ totalCartonSegment }}</md-table-head>
						<md-table-head></md-table-head>
						<md-table-head></md-table-head>
						<md-table-head></md-table-head>
					</md-table-row>
				</md-table>
	
				<div style="text-align: right; margin-bottom: 20px" v-if="entity.name && entity.status == 'Active'">
					<md-button class="md-raised md-primary" @click="addToOrder()">Save</md-button>
				</div>
	
				<md-button class="md-dense md-raised" @click="showAllocation = !showAllocation">Show/Hide Reallocation</md-button>
	
				<template v-if="showAllocation">
					<div style="text-align: right; margin-bottom: 20px">
						<md-button class="md-raised md-primary" @click="reportAllocation()">
							<md-icon>save_alt</md-icon>Download allocation report
						</md-button>
					</div>
					<md-table>
						<md-table-row>
							<md-table-head>Name</md-table-head>
							<md-table-head>Role</md-table-head>
							<md-table-head>Manager</md-table-head>
							<md-table-head>Keg</md-table-head>
							<md-table-head>Carton</md-table-head>
						</md-table-row>
						<md-table-row v-for="entity in personList" :key="entity.id">
							<md-table-cell>{{ entity.name }}</md-table-cell>
							<md-table-cell>{{ entity.role && entity.role.replace(/_/g, " ") }}</md-table-cell>
							<md-table-cell>{{ entity.dataMap.managerName }}</md-table-cell>
							<md-table-cell>
								<input type="text" v-model="entity.dataMap.keg" size="3" class="box" />
							</md-table-cell>
							<md-table-cell>
								<input type="text" v-model="entity.dataMap.carton" size="3" class="box" />
							</md-table-cell>
						</md-table-row>
						<md-table-row>
							<md-table-cell></md-table-cell>
							<md-table-cell></md-table-cell>
							<md-table-cell>Total</md-table-cell>
							<md-table-cell>{{ totalKeg }}</md-table-cell>
							<md-table-cell>{{ totalCarton }}</md-table-cell>
						</md-table-row>
					</md-table>
	
					<div style="text-align: right; margin-bottom: 20px">
						<md-button class="md-dense md-primary md-raised" @click="reallocate()">Reallocate</md-button>
					</div>
				</template>
			</template>
	
			<template v-else>
				<div style="text-align: right; margin-bottom: 20px" v-if="entity.name && entity.status == 'Active'">
					<md-button class="md-raised md-primary" @click="addToCart()">Add to cart</md-button>
				</div>
			</template>
	
			<div v-if="entity.imageDataList && entity.imageDataList.length > 0">
				<div v-for="item in entity.imageDataList" :key="item">
					<md-card style="margin-bottom: 10px">
						<media :name="item" />
	
						<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(item)" style="position: absolute; right: 0px; bottom: 10px">
							<md-icon>get_app</md-icon>
						</md-button>
					</md-card>
				</div>
			</div>
	
			<md-dialog :md-active.sync="showDialogDraft" style="min-width:500px;">
				<md-dialog-title>Save as draft</md-dialog-title>
				<md-field>
					<label>Draft name</label>
					<md-input v-model="draftName"></md-input>
				</md-field>
				<md-dialog-actions>
					<md-button @click="showDialogDraft = false">Cancel</md-button>
					<md-button class="md-raised md-primary" @click="addDraft()">Save</md-button>
				</md-dialog-actions>
			</md-dialog>
	
			<md-dialog :md-active.sync="dialogNotification.show">
				<md-dialog-title>{{ dialogNotification.title }}</md-dialog-title>
				{{ dialogNotification.content }}
				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogNotification.show = false">Ok</md-button>
				</md-dialog-actions>
			</md-dialog>
	
			<span v-if="'false'"></span>
			<notification :notification="notification"></notification>
			<snackbar :snack="snack"></snackbar>
		</div>

		<div v-if="isPermitted == false" style="text-align: center;">
			<h2 style="display: inline-block;">You don't have access to this page.</h2>
		</div>

	</div>
</template>

<style scoped>
@font-face {
	font-family: "Brandon";
	src: url(/resource/tenant/font/1/Brandon.otf);
}
@font-face {
	font-family: "Brown-Bold";
	src: url(/resource/tenant/font/1/Brown-Bold.otf);
}
@font-face {
	font-family: "Elan-BQ-Regular";
	src: url(/resource/tenant/font/1/Elan-BQ-Regular.otf);
}
@font-face {
	font-family: "Arizona-Medium";
	src: url(/resource/tenant/font/1/ABCArizonaText-Medium.otf);
}
@font-face {
	font-family: "Arizona-Regular";
	src: url(/resource/tenant/font/1/ABCArizonaText-Regular.otf);
}
@font-face {
	font-family: "Elan-ITC-Bold";
	src: url(/resource/tenant/font/1/ElanITCStd-Bold.otf);
}
@font-face {
	font-family: "Elan-ITC-Medium";
	src: url(/resource/tenant/font/1/ElanITCStd-Medium.otf);
}
@font-face {
	font-family: "Sharp-Grostek-Medium";
	src: url(/resource/tenant/font/1/SharpGroteskMedium15.otf);
}
@font-face {
	font-family: "Flama-Basic";
	src: url(/resource/tenant/font/2/Flama-Basic.otf);
}
@font-face {
	font-family: "Flama-Cond-Basic";
	src: url(/resource/tenant/font/2/Flama-Cond-Basic.ttf);
}
@font-face {
	font-family: "Flama-Condensed-Medium";
	src: url(/resource/tenant/font/2/Flama-Condensed-Medium.otf);
}
@font-face {
	font-family: "Flama-Bold";
	src: url(/resource/tenant/font/2/Flama-Bold.otf);
}
@font-face {
	font-family: "Gotham-Bold";
	src: url(/resource/tenant/font/2/Gotham-Bold.ttf);
}
@font-face {
	font-family: "DIN-Condensed-Bold";
	src: url(/resource/tenant/font/2/DIN-Condensed-Bold.ttf);
}
@font-face {
	font-family: "Futura-Bold";
	src: url(/resource/tenant/font/2/Futura-Bold.ttf);
}
@font-face {
	font-family: "Knockout-Junior-Featherweight";
	src: url(/resource/tenant/font/2/Knockout-Junior-Featherweight.otf);
}
@font-face {
	font-family: "Knockout-Featherweight";
	src: url(/resource/tenant/font/2/Knockout-Featherweight.otf);
}
@font-face {
	font-family: "Mostra-Nuova-AltD-Bold";
	src: url(/resource/tenant/font/2/Mostra-Nuova-AltD-Bold.otf);
}
@font-face {
	font-family: "HelveticaNeue-Light";
	src: url(/resource/tenant/font/2/HelveticaNeue-Light.ttf);
}
@font-face {
	font-family: "HelveticaNeue-CondensedBold";
	src: url(/resource/tenant/font/2/HelveticaNeue-CondensedBold.ttf);
}
@font-face {
	font-family: "Vanitas-ExtraBold";
	src: url(/resource/tenant/font/2/Vanitas-ExtraBold.otf);
}
@font-face {
	font-family: "Futura-Book-BT";
	src: url(/resource/tenant/font/2/Futura-Book-BT.ttf);
}
@font-face {
	font-family: "Futura-Medium-BT";
	src: url(/resource/tenant/font/2/Futura-Medium-BT.ttf);
}
@font-face {
	font-family: "BigNoodleTitling";
	src: url(/resource/tenant/font/2/BigNoodleTitling.ttf);
}
@font-face {
	font-family: "DINOT-Cond";
	src: url(/resource/tenant/font/2/DINOT-Cond.otf);
}
@font-face {
	font-family: "Garamond";
	src: url(/resource/tenant/font/2/Garamond.ttf);
}
@font-face {
	font-family: "Gotham-Medium";
	src: url(/resource/tenant/font/2/Gotham-Medium.otf);
}
@font-face {
	font-family: "Vanitas-Black";
	src: url(/resource/tenant/font/2/Vanitas-Black.otf);
}
@font-face {
	font-family: "HurmeGeometricSans1-Bold";
	src: url(/resource/tenant/font/2/HurmeGeometricSans1-Bold.otf);
}
@font-face {
	font-family: "Barlow-Bold";
	src: url(/resource/tenant/font/2/Barlow-Bold.otf);
}
@font-face {
	font-family: "DIN-Black-Regular";
	src: url(/resource/tenant/font/2/DIN-Black-Regular.otf)
}
@font-face {
	font-family: "Natural-Script-Regular";
	src: url(/resource/tenant/font/2/Natural-Script-Regular.ttf)
}
@font-face {
	font-family: "Semplicita-Light";
	src: url(/resource/tenant/font/2/Semplicita-Light.otf)
}
@font-face {
	font-family: "Semplicita-Medium";
	src: url(/resource/tenant/font/2/Semplicita-Medium.otf)
}
@font-face {
	font-family: "Gothic-821-Condensed";
	src: url(/resource/tenant/font/2/Gothic-821-Condensed.ttf)
}
@font-face {
	font-family: "HelveticaNeueLTStd-BdCn";
	src: url(/resource/tenant/font/2/HelveticaNeueLTStd-BdCn.otf)
}
@font-face {
	font-family: "Futura-Condensed-Medium.otf";
	src: url(/resource/tenant/font/2/Futura-Condensed-Medium.otf)
}
input.slug {
	border: none;
	background: #ddd;
	width: 700px;
}
input.slug-non-editable {
	border: none;
	background: #fff;
	width: 700px;
}
div.radio-button {
	margin-left: 5px;
}
.Active {
	color: black;
}
.Inactive {
	color: #aaa;
}
fieldset {
	border-radius: 5px;
}
table.dense {
	border-spacing: 0;
	border-collapse: collapse;
}
table.dense td {
	padding: 0;
}
textarea {
	font-family: "Source Sans Pro", Helvetica, Arial, monospace, sans-serif;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			isPermitted: null,
			entity: {
				inventoryType: null,
				inventoryDataList: [],
				imageDataList: [],
				dataMap: {
					inventory: [{}, {}, {}, {}, {}, {}, {}, {}]
				}
			},
			dialogNotification: {
				show: false,
				title: "Notification",
				content: ""
			},
			personList: [],
			customerList: [],
			driveType: "drive",
			drivePath: ":",
			brandCode : null,
			showDialogDrive: false,
			driveList: [],
			color: "#000000",
			selection: null,
			indexList: [],
			elementList: [],
			styleList: [],
			limit: 15,
			randomId: 0,
			itemList: [],
			itemId: 0,
			mode: "line",
			selectedItem: null,
			instruction: "",
			showAllocation: false,
			allocation: {},
			positionList: [],
			companySegmentList: [],
			selectedCompanySegment: -1,
			newSize: 50,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			font: null,
			colorList: [],
			showDialogDraft: false,
			draftName: "",
			selectedPredefineStyle : null,
			predefineStyles : [
				{
					title : "Style 1",
					leading : 41,
					size : 35,
					fontFamily : "ABCArizonaText-Medium"
				},
				{
					title : "Style 2",
					leading : 46,
					size : 40,
					fontFamily : "ABCArizonaText-Medium"
				}
			],
		};
	},
	created() {
		let vm = this;

		// check user group access here
		ajax.post("/system/usergroup/check/product", { id: vm.$route.params.entityId })
			.then(response => {
				vm.isPermitted = response.data
			})
			.catch(error => {
				vm.$root.logError(error);
				vm.$root.showNotification(vm.notification, error.response);
			});
	},
	computed: {
		totalKeg: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.keg;
				if (e) {
					total += Number(e);
				}
			}
			return total;
		},
		totalCarton: function() {
			let total = 0;
			for (let i in this.personList) {
				let e = this.personList[i].dataMap.carton;
				if (e) {
					total += Number(e);
				}
			}
			return total;
		},
		totalKegSegment: function() {
			let total = 0;
			for (let i in this.customerList) {
				let e = this.customerList[i].dataMap.keg;
				if (e) {
					total += Number(e);
				}
			}
			return total;
		},
		totalCartonSegment: function() {
			let total = 0;
			for (let i in this.customerList) {
				let e = this.customerList[i].dataMap.carton;
				if (e) {
					total += Number(e);
				}
			}
			return total;
		},
		titlePredefineStyle() {
			return 'Font : ' + this.selectedPredefineStyle.fontFamily + ', Leading : ' + this.selectedPredefineStyle.leading + ' pt, Size : ' + this.selectedPredefineStyle.size +  ' pt';
		}
	},
	mounted: function() {
		this.find();
		this.listCompanySegment();
	},
	methods: {
		find() {
			let vm = this;

			ajax.get("/system/product?action=load&id=" + this.$route.params.entityId)
			.then(response => {
				vm.$root.logSuccess(response);
				vm.entity = response.data;
				vm.entity.productType = vm.entity.type == "LimitedRelease" ? "Limited Release" : vm.entity.type == "PointOfSale" ? "Point Of Sale" : vm.entity.type;
				if (vm.entity.dataMap && vm.entity.dataMap.fontList && vm.entity.dataMap.renderDataMap && vm.entity.dataMap.renderDataMap.fontName && !vm.entity.dataMap.fontList.includes(vm.entity.dataMap.renderDataMap.fontName)) {
					vm.entity.dataMap.fontList.push(vm.entity.dataMap.renderDataMap.fontName);
				}
				if (vm.entity.dataMap && vm.entity.dataMap.renderDataMap && vm.entity.dataMap.renderDataMap.fontName) {
					vm.font = vm.entity.dataMap.renderDataMap.fontName;
				}
				if (vm.entity.type == "LimitedRelease") {
					// store the total keg and carton before it is wiped out by the for loop below
					// vm.entity.totalKeg = vm.entity.dataMap.inventory[0].quantity;
					// vm.entity.totalCarton = vm.entity.dataMap.inventory[1].quantity;
					vm.listSegmentCustomer();
					vm.listPersonAllocation();
				}
				if (vm.entity.dataMap.inventory) {
					for (let i in vm.entity.dataMap.inventory) {
						let entry = vm.entity.dataMap.inventory[i];
						entry.available = entry.quantity;
						entry.quantity = null;
					}
				}

				if (vm.entity.dataMap && vm.entity.dataMap.editableSlugMap) {
					vm.entity.editableSlugMap = vm.entity.dataMap.editableSlugMap;
				} else {
					vm.entity.editableSlugMap = {};
				}

				vm.colorList = [
					{ name: "Black", value: "rgb(0,0,0)" },
					{ name: "White", value: "rgb(255,255,255)" }
				];

				if (vm.entity.dataMap && vm.entity.dataMap.colorList && vm.entity.dataMap.colorList.length > 0) {
					vm.colorList = vm.entity.dataMap.colorList;
				}
				vm.color = vm.colorList[0].value;

				if (vm.entity.customizable) {
					vm.setup();

					if (vm.$route.query.draftId) {
						vm.findDraft();
					}

					if (vm.$route.query.orderLineId) {
						vm.findOrderLine();
					}

					if (this.$root.tenantId() == 1) { // Search brand code for tenant 'stone and wood'
						vm.findBrand();
					}
				}
			})
			.catch(error => {
				vm.$root.logError(error);
				vm.$root.showNotification(vm.notification, error.response);
			});

		},
		findDraft() {
			let vm = this;
			ajax.get("/system/draft?id=" + this.$route.query.draftId)
				.then(response => {
					vm.$root.logSuccess(response);
					let draft = response.data;
					vm.elementList = JSON.parse(draft.elementListData);
					vm.styleList = JSON.parse(draft.styleListData);
					if (draft.dataMap && draft.dataMap.predefineStyle) {
						vm.selectedPredefineStyle = vm.predefineStyles.find(ele => ele.title == draft.dataMap.predefineStyle);
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		findOrderLine() {
			let vm = this;
			ajax.get("/system/orderline?id=" + this.$route.query.orderLineId)
				.then(response => {
					vm.$root.logSuccess(response);
					let orderLine = response.data;
					vm.elementList = JSON.parse(orderLine.elementListData);
					vm.styleList = JSON.parse(orderLine.styleListData);
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		findBrand() {
			let vm = this;
			ajax.get("/system/brand?id=" + vm.entity.brandId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandCode = response.data.code;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPersonAllocation() {
			let vm = this;
			ajax.get("/system/person/reallocation/list?productId=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personList = response.data.entityList;
					vm.allocation = response.data.allocation;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listCompanySegment() {
			let vm = this;
			ajax.get("/system/company/segment/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.companySegmentList = [{ id: -1, name: "All" }]; // special meaning
					let list = response.data;
					let id = 1;
					for (let entry of list) {
						if (entry) {
							vm.companySegmentList.push({
								id: id++,
								name: entry
							});
						} else {
							vm.companySegmentList.push({
								id: 0, // special meaning
								name: "Unspecified"
							});
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		filteredCustomerList() {
			if (this.selectedCompanySegment == -1) {
				return this.customerList;
			} else {
				let filter = null;
				if (this.selectedCompanySegment > 0) {
					// since 0 means unspecified
					for (let segment of this.companySegmentList) {
						// find the text
						if (segment.id == this.selectedCompanySegment) {
							filter = segment.name;
						}
					}
				}

				let list = [];
				for (let entity of this.customerList) {
					if (filter) {
						if (entity.segment && entity.segment == filter) {
							list.push(entity);
						}
					} else {
						if (!entity.segment) {
							list.push(entity);
						}
					}
				}
				return list;
			}
		},
		listSegmentCustomer() {
			let vm = this;
			ajax.post("/system/company/salesperson/segment/list", {
				action: "limitedRelease",
				productId: this.$route.params.entityId
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.customerList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addToCart() {
			let vm = this;
			vm.calculatePositionList();

			ajax.get("/system/company?id=" + vm.entity.supplierId)
				.then(sup => {

					console.log(sup.data.name);

					ajax.post("/system/product/render?action=final", {
						entity: vm.entity,
						elementList: vm.elementList,
						styleList: vm.styleList,
						itemList: vm.itemList,
						positionList: vm.positionList,
						mode: vm.mode,
						predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null,
					})
						.then(response => {
							vm.$root.logSuccess(response);
							vm.randomId++;

							ajax.post("/system/cartline/add", {
								entity: vm.entity,
								elementList: vm.elementList,
								styleList: vm.styleList,
								mode: vm.mode,
								predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null,
							})
								.then(response => {
									vm.$root.logSuccess(response);
									vm.dialogNotification.title = "Notification";
									vm.dialogNotification.content = "Added to cart successfully";
									vm.dialogNotification.show = true;
								})
								.catch(error => {
									vm.$root.logError(error);
									vm.$root.showNotification(vm.notification, error.response);
								});
						})
						.catch(error => {
							vm.$root.logError(error);
							vm.$root.showNotification(vm.notification, error.response);
						});
					
			});
			
		},
		addToOrder() {
			let vm = this;
			vm.calculatePositionList();
			ajax.post("/system/product/render?action=final", {
				entity: vm.entity,
				elementList: vm.elementList,
				styleList: vm.styleList,
				itemList: vm.itemList,
				positionList: vm.positionList,
				mode: vm.mode,
				predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null,
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.randomId++;

					ajax.post("/system/productorder/limitedrelease", {
						entity: vm.entity,
						customerList: vm.customerList
					})
						.then(response => {
							vm.$root.logSuccess(response);
							vm.listPersonAllocation();
							vm.dialogNotification.title = "Notification";
							vm.dialogNotification.content = "Saved successfully";
							vm.dialogNotification.show = true;
						})
						.catch(error => {
							vm.$root.logError(error);
							vm.$root.showNotification(vm.notification, error.response);
						});
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		reallocate() {
			let vm = this;
			ajax.post("/system/product/reallocate", {
				entity: vm.entity,
				allocation: vm.personList
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.find();
					vm.dialogNotification.content = "Reallocated successfully";
					vm.dialogNotification.show = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		setup() {
			let i = 0;
			// 50 is the initial size, add 10 because chrome around 10px padding
			this.limit = this.entity.dataMap.renderDataMap.draftHeight / (50 + 10);
			while (i < this.limit) {
				this.indexList.push(i);
				this.elementList.push({ type: "text", maxCharacters: -1});
				this.styleList.push({
					textAlign: "left",
					size: 50,
					fontSize: 50 + "px",
					leadingSize : 0,
					color: this.color,
					fontFamily: this.font,
					leftPosition: 0,
					rightPosition: 0
				});
				i++;
			}
			if (this.$root.tenantCustomisation('custom-pos-type') && this.isLimitedTypeCustomisable()) {
				// Set default style to 1
				this.selectedPredefineStyle = this.predefineStyles[0];
				this.updatePredefineStyle();
			}
		},
		alignLeft() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			this.styleList[this.selection].textAlign = "left";
			this.styleList[this.selection].leftPosition = 0;
			this.styleList[this.selection].rightPosition = 0;
			if (this.elementList[this.selection].type == "image") {
				this.styleList[this.selection].left = this.styleList[this.selection].leftPosition + "px";
				delete this.styleList[this.selection].right;
			}
		},
		alignCenter() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			this.styleList[this.selection].textAlign = "center";
			this.styleList[this.selection].leftPosition = 0;
			this.styleList[this.selection].rightPosition = 0;
			if (this.elementList[this.selection].type == "image") {
				delete this.styleList[this.selection].left;
				delete this.styleList[this.selection].right;
			}
		},
		alignRight() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			this.styleList[this.selection].textAlign = "right";
			this.styleList[this.selection].leftPosition = 0;
			this.styleList[this.selection].rightPosition = 0;
			if (this.elementList[this.selection].type == "image") {
				delete this.styleList[this.selection].left;
				this.styleList[this.selection].right = this.styleList[this.selection].rightPosition + "px";
			}
		},
		increaseSize() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			if (this.elementList[this.selection].type == "text") {
				this.styleList[this.selection].size = this.styleList[this.selection].size + 5;
				this.styleList[this.selection].fontSize = this.styleList[this.selection].size + "px";
			} else if (this.elementList[this.selection].type == "image") {
				this.styleList[this.selection].size = this.styleList[this.selection].size + 5;
				this.styleList[this.selection].height = this.styleList[this.selection].size + "px";
			}
		},
		decreaseSize() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			if (this.styleList[this.selection].size <= 5) {
				return;
			}

			if (this.elementList[this.selection].type == "text") {
				this.styleList[this.selection].size = this.styleList[this.selection].size - 5;
				this.styleList[this.selection].fontSize = this.styleList[this.selection].size + "px";
			} else if (this.elementList[this.selection].type == "image") {
				this.styleList[this.selection].size = this.styleList[this.selection].size - 5;
				this.styleList[this.selection].height = this.styleList[this.selection].size + "px";
			}
		},
		setSize(value) {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			value = new Number(value);

			if (!(value > 0)) {
				this.dialogNotification = {
					show: true,
					content: "Size/height must be a positive number"
				};
				return;
			}

			if (this.elementList[this.selection].type == "text") {
				this.styleList[this.selection].size = value;
				this.styleList[this.selection].fontSize = value + "px";
			} else if (this.elementList[this.selection].type == "image") {
				this.styleList[this.selection].size = value;
				this.styleList[this.selection].height = value + "px";
			}
		},
		indentLeft() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			if (this.elementList[this.selection].type == "image") {
				if (this.styleList[this.selection].textAlign == "left") {
					this.styleList[this.selection].leftPosition = this.styleList[this.selection].leftPosition - 5;
					this.styleList[this.selection].left = this.styleList[this.selection].leftPosition + "px";
					this.styleList[this.selection].position = "relative";
				} else if (this.styleList[this.selection].textAlign == "right") {
					this.styleList[this.selection].rightPosition = this.styleList[this.selection].rightPosition + 5;
					this.styleList[this.selection].right = this.styleList[this.selection].rightPosition + "px";
					this.styleList[this.selection].position = "relative";
				}
			}
		},
		indentRight() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			if (this.elementList[this.selection].type == "image") {
				if (this.styleList[this.selection].textAlign == "left") {
					this.styleList[this.selection].leftPosition = this.styleList[this.selection].leftPosition + 5;
					this.styleList[this.selection].left = this.styleList[this.selection].leftPosition + "px";
					this.styleList[this.selection].position = "relative";
				} else if (this.styleList[this.selection].textAlign == "right") {
					this.styleList[this.selection].rightPosition = this.styleList[this.selection].rightPosition - 5;
					this.styleList[this.selection].right = this.styleList[this.selection].rightPosition + "px";
					this.styleList[this.selection].position = "relative";
				}
			}
		},
		applyColor() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			this.styleList[this.selection].color = this.color;
		},
		applyColorAll() {
			for (let line of this.styleList) {
				line.color = this.color;
			}
		},
		applyFont() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}
			this.styleList[this.selection].fontFamily = this.font;
		},
		applyFontAll() {
			for (let line of this.styleList) {
				line.fontFamily = this.font;
			}
		},
		reset() {
			if (this.selection === null) {
				this.dialogNotification = {
					show: true,
					content: "Please make a selection first"
				};
				return;
			}

			this.elementList[this.selection].type = "text";
			this.elementList[this.selection].value = "";

			this.styleList[this.selection].textAlign = "left";
			this.styleList[this.selection].size = 50;
			this.styleList[this.selection].fontSize = "50px";
			this.styleList[this.selection].color = "#000";

			delete this.styleList[this.selection].left;
			delete this.styleList[this.selection].right;
			delete this.styleList[this.selection].position;

			delete this.styleList[this.selection].height;
		},
		listDrive() {
			if (this.mode == "line") {
				if (this.selection === null) {
					this.dialogNotification = {
						show: true,
						content: "Please make a selection first"
					};
					return;
				}
			}

			let vm = this;
			ajax.post("/system/" + vm.driveType + "/list", {
				path: vm.drivePath,
				code: vm.brandCode
			})
				.then(response => {
					vm.$root.logSuccess(response);

					// do not let user pick non image file
					let filteredList = [];
					let list = response.data.list;
					for (let i in list) {
						let item = list[i];
						if (item.folder == true) {
							filteredList.push(item);
						} else {
							if (item.name && (item.name.toLowerCase().endsWith(".jpg") || item.name.toLowerCase().endsWith(".jpeg") || item.name.toLowerCase().endsWith(".png"))) {
								filteredList.push(item);
							}
						}
					}

					vm.driveList = filteredList;
					vm.showDialogDrive = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectDrive(name) {
			if (this.mode == "line") {
				this.elementList[this.selection].type = "image";
				this.elementList[this.selection].value = name;

				this.styleList[this.selection].textAlign = "left";
				this.styleList[this.selection].size = 100;
				this.styleList[this.selection].height = "100px";

				delete this.styleList[this.selection].fontSize;
				delete this.styleList[this.selection].color;
			} else if (this.mode == "point") {
				this.itemList.push({
					id: this.itemId++,
					type: "image",
					value: name,
					style: {
						size: 100,
						height: 100 + "px"
					}
				});
			}

			this.showDialogDrive = false;
		},
		calculatePositionList() {
			if (this.mode == "line") {
				this.positionList = [];
				let a = 0;
				let b = 0;
				for (let i in this.elementList) {
					let element = this.elementList[i];
					let p = {};
					let q = {};
					if (element.type == "text") {
						q = this.$refs.positionTextList[a++].getBoundingClientRect();
						p.type = "text";
					} else if (element.type == "image") {
						q = this.$refs.positionImageList[b++].getBoundingClientRect();
						p.type = "image";
					}
					p.y = q.y;
					p.value = element.value;
					if (p.value) {
						p.spacing = this.styleList[i].leadingSize;
					}
					this.positionList.push(p);
				}
			}
		},
		quickRender() {
			let vm = this;
			vm.calculatePositionList();
			ajax.post("/system/product/render?action=quick", {
				entity: vm.entity,
				elementList: vm.elementList,
				styleList: vm.styleList,
				itemList: vm.itemList,
				positionList: vm.positionList,
				mode: vm.mode,
				predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null,
			})
				.then(response => {
					vm.$root.logSuccess(response);
					this.$nextTick(function() {
						vm.randomId++;
					});
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		finalRender() {
			let vm = this;
			vm.calculatePositionList();
			ajax.post("/system/product/render?action=final", {
				entity: vm.entity,
				elementList: vm.elementList,
				styleList: vm.styleList,
				itemList: vm.itemList,
				positionList: vm.positionList,
				mode: vm.mode,
				predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null,
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.randomId++;
					location.href = vm.baseLocation + "/asset/file/" + vm.entity.dataMap.renderDataMap.final + "?download&id=" + vm.randomId;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addItemText() {
			this.itemList.push({
				id: this.itemId++,
				type: "text",
				style: {
					size: 50,
					fontSize: 50 + "px",
					color: "#000",
					fontFamily: this.entity.dataMap.renderDataMap.fontName
				}
			});
		},
		placeItem(item, align) {
			this.instruction = "Click on any point on the image to place the text/image";
			this.selectedItem = item;
			this.selectedItem.align = align;
			this.selectedItem.iconStyle = { color: "#ff5252" };
		},
		locate(event) {
			if (this.mode == "point") {
				let element = event.target.getBoundingClientRect();
				this.selectedItem.x = event.clientX - element.left;
				this.selectedItem.y = event.clientY - element.top;
				this.instruction = "";
				this.selectedItem.iconStyle = {};
				this.selectedItem = null;
				this.quickRender();
			}
		},
		deleteItem(id) {
			for (let i in this.itemList) {
				if (this.itemList[i].id === id) {
					this.itemList.splice(i, 1);
					return;
				}
			}
		},
		applyItemColor(item) {
			item.style.color = this.color;
		},
		increaseItemSize(item) {
			if (item.type == "text") {
				item.style.size = item.style.size + 5;
				item.style.fontSize = item.style.size + "px";
			} else if (item.type == "image") {
				item.style.size = item.style.size + 5;
				item.style.height = item.style.size + "px";
			}
		},
		decreaseItemSize(item) {
			if (item.type == "text") {
				item.style.size = item.style.size - 5;
				item.style.fontSize = item.style.size + "px";
			} else if (item.type == "image") {
				item.style.size = item.style.size - 5;
				item.style.height = item.style.size + "px";
			}
		},
		up(index) {
			if (this.mode == "line") {
				if (index > 0) {
					this.swapLine(index - 1, index);
				}
			}
		},
		down(index) {
			if (this.mode == "line") {
				if (index + 1 < this.elementList.length) {
					this.swapLine(index, index + 1);
				}
			}
		},
		swapLine(a, b) {
			let elementA = this.elementList[a];
			let styleA = this.styleList[a];

			let elementB = this.elementList[b];
			let styleB = this.styleList[b];

			let transit = {
				type: elementA.type,
				value: elementA.value,
				textAlign: styleA.textAlign,
				size: styleA.size,
				fontSize: styleA.fontSize,
				color: styleA.color,
				fontFamily: styleA.fontFamily,
				leftPosition: styleA.leftPosition,
				rightPosition: styleA.rightPosition,
				left: styleA.left,
				right: styleA.right,
				position: styleA.position,
				height: styleA.height
			};

			elementA.type = elementB.type;
			elementA.value = elementB.value;
			styleA.textAlign = styleB.textAlign;
			styleA.size = styleB.size;
			styleA.fontSize = styleB.fontSize;
			styleA.color = styleB.color;
			styleA.fontFamily = styleB.fontFamily;
			styleA.leftPosition = styleB.leftPosition;
			styleA.rightPosition = styleB.rightPosition;
			styleA.left = styleB.left;
			styleA.right = styleB.right;
			styleA.position = styleB.position;
			styleA.height = styleB.height;

			elementB.type = transit.type;
			elementB.value = transit.value;
			styleB.textAlign = transit.textAlign;
			styleB.size = transit.size;
			styleB.fontSize = transit.fontSize;
			styleB.color = transit.color;
			styleB.fontFamily = transit.fontFamily;
			styleB.leftPosition = transit.leftPosition;
			styleB.rightPosition = transit.rightPosition;
			styleB.left = transit.left;
			styleB.right = transit.right;
			styleB.position = transit.position;
			styleB.height = transit.height;

			this.$forceUpdate();
		},
		reportLimitedRelease() {
			let vm = this;
			location.href = vm.baseLocation + "/system/report/productorder/limitedrelease?productId=" + this.$route.params.entityId;
		},
		reportAllocation() {
			let vm = this;
			location.href = vm.baseLocation + "/system/person/allocation/list?action=report&productId=" + this.$route.params.entityId;
		},
		dialogDraft() {
			let vm = this;
			vm.draftName = vm.entity.name + " " + vm.$root.formatJavaScriptDateTime(new Date());
			vm.showDialogDraft = true;
		},
		addDraft() {
			let vm = this;
			ajax.post("/system/draft/add", {
				name: vm.draftName,
				entity: vm.entity,
				elementList: vm.elementList,
				styleList: vm.styleList,
				mode: vm.mode,
				dataMap: {
					predefineStyle : vm.selectedPredefineStyle ?  vm.selectedPredefineStyle.title : null
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.showDialogDraft = false;
					vm.dialogNotification.title = "Notification";
					vm.dialogNotification.content = "Draft successfully saved";
					vm.dialogNotification.show = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		saveNote() {
			let vm = this;
			ajax.post("/system/product/edit", {
				entity: vm.entity
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.showDialogDraft = false;
					vm.dialogNotification.title = "Notification";
					vm.dialogNotification.content = "Notes successfully saved";
					vm.dialogNotification.show = true;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		updatePredefineStyle() {
			let vm = this;

			let typeCustomisable = this.entity.dataMap.typeCustomisable;

			for (let i in this.elementList) {
				vm.styleList[i].fontFamily = vm.selectedPredefineStyle.fontFamily;
				vm.styleList[i].size = vm.selectedPredefineStyle.size;
				vm.styleList[i].leadingSize = vm.selectedPredefineStyle.leading;
				vm.styleList[i].fontSize = vm.selectedPredefineStyle.size + "px";

				if (vm.selectedPredefineStyle.title == "Style 1") {
					vm.styleList[i].width = "32rem";
					vm.elementList[i].maxCharacters = 34;
				} else {
					if (typeCustomisable == 'limitedA4') {
						vm.styleList[i].width = "31rem";
						vm.elementList[i].maxCharacters = 28;
					} else {
						vm.styleList[i].width = "30rem";
						vm.elementList[i].maxCharacters = 27;
					}
				}

				if (vm.elementList[i].value != null && vm.elementList[i].value.length > 0) {
					vm.elementList[i].value = vm.elementList[i].value.substring(0, vm.elementList[i].maxCharacters);
				}

			}
		},
		isLimitedTypeCustomisable() {
			return this.entity.dataMap.typeCustomisable == 'limitedA1' || this.entity.dataMap.typeCustomisable == 'limitedA3' || this.entity.dataMap.typeCustomisable == 'limitedA4';
		}
	}
};
</script>