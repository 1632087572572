<template>
	<div>
		<div class="md-title">New Items</div>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom: solid #d9d9d9 1px">
				<div>
					<span @click="up(entity)">
						<md-icon class="pointer">arrow_drop_up</md-icon>
					</span>
					<br />
					<span @click="down(entity)">
						<md-icon class="pointer">arrow_drop_down</md-icon>
					</span>
				</div>
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<span :class="entity.status" style="padding-left: 10px">{{ entity.name }}</span>
				</div>

				<md-button class="md-raised md-accent" @click="removePriority(entity)">Remove from new list</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<br/><br/>

		<div class="md-title">Core Point of Sales</div>

		<md-list>
			<md-list-item v-for="entity in coreList" :key="entity.id" style="border-bottom: solid #d9d9d9 1px">
				<div>
					<span @click="upCore(entity)">
						<md-icon class="pointer">arrow_drop_up</md-icon>
					</span>
					<br />
					<span @click="downCore(entity)">
						<md-icon class="pointer">arrow_drop_down</md-icon>
					</span>
				</div>
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<span :class="entity.status" style="padding-left: 10px">{{ entity.name }}</span>
				</div>

				<md-button class="md-raised md-accent" @click="removeCorepos(entity)">Remove from core point of sales</md-button>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && coreList.length == 0">Empty list</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			coreList: [],
		};
	},
	mounted: function() {
		this.list();
		this.coreposList();
	},
	methods: {
		swapLine(a, b) {
			let vm = this;
			ajax.post("/system/product/priority/swap", {
				productId1: vm.entityList[a].id,
				productId2: vm.entityList[b].id
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		swapCoreLine(a, b) {
			let vm = this;
			ajax.post("/system/product/corepos/swap", {
				productId1: vm.coreList[a].id,
				productId2: vm.coreList[b].id
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
					vm.coreposList();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		up(entity) {
			let vm = this;
			console.log(entity);
			let index = vm.entityList.indexOf(entity);
			if (index > 0) {
				this.swapLine(index - 1, index);
			}
		},
		down(entity) {
			let vm = this;
			let index = vm.entityList.indexOf(entity);
			if (index + 1 < this.entityList.length) {
				this.swapLine(index, index + 1);
			}
		},
		upCore(entity) {
			let vm = this;
			let index = vm.coreList.indexOf(entity);
			if (index > 0) {
				this.swapCoreLine(index - 1, index);
			}
		},
		downCore(entity) {
			let vm = this;
			let index = vm.coreList.indexOf(entity);
			if (index + 1 < this.entityList.length) {
				this.swapCoreLine(index, index + 1);
			}
		},
		list() {
			let vm = this;
			ajax.get("/system/product/priority/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		coreposList() {
			let vm = this;
			ajax.get("/system/product/corepos/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.coreList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		removePriority(product) {
			let vm = this;
			
			ajax.post("/system/product/priority/remove", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		removeCorepos(product) {
			let vm = this;
			
			ajax.post("/system/product/corepos/remove", { productId: product.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.coreposList();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>