<template>
	<div>
		<div class="md-title">Event</div>
		{{data.currentMonth}}
		<br />
		<br />

		<table class="calendar" v-if="data.calendar">
			<tr>
				<th style="width:14%;">Mon</th>
				<th style="width:14%;">Tue</th>
				<th style="width:14%;">Wed</th>
				<th style="width:14%;">Thu</th>
				<th style="width:14%;">Fri</th>
				<th style="width:14%;">Sat</th>
				<th style="width:14%;">Sun</th>
			</tr>
			<tr v-for="row in data.calendar" :key="row[0].id">
				<td v-for="entry in row" :key="entry.id">
					<div style="text-align:center;font-size:18px;margin:10px;">{{entry.view}}</div>
					<template v-for="event in entry.eventList">
						<template v-if="event.id">
							<template v-if="event.name">
								<div
									:style="{ backgroundColor: event.color, height: '20px', marginBottom: '5px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }"
									:key="event.id"
									:class="{ approved: event.approved, unapproved: !event.approved, begin: event.begin, end: event.end }"
								>{{$root.ellipsify(event.name, 25)}}</div>
							</template>
							<template v-else>
								<div :style="{ backgroundColor: event.color, color: event.color, height: '20px', marginBottom: '5px' }" :key="event.id" :class="{ begin: event.begin, end: event.end }"></div>
							</template>
						</template>
						<template v-else>
							<div style="height:20px;margin-bottom:5px;" :key="event.id" :class="{ begin: event.begin, end: event.end }"></div>
						</template>
					</template>
				</td>
			</tr>
		</table>

		<fieldset>
			<legend>Legend</legend>Black text: Approved
			<br />White text: Not yet approved
			<br />
		</fieldset>
		<br />

		<div>
			<md-button class="md-icon-button md-raised md-dense" @click="list(data.previousMonth)">
				<md-icon>keyboard_arrow_left</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showSearch()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" to="/sales/event/add">
				<md-icon>add</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="list(data.nextMonth)">
				<md-icon>keyboard_arrow_right</md-icon>
			</md-button>
		</div>

		<md-list>
			<md-list-item v-for="entity in data.list" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<router-link :to="entity.creatorId == me.id ? '/sales/event/edit/' + entity.id : '/sales/event/view/' + entity.id">{{entity.name}}</router-link>
					<div v-if="entity.dataMap.creatorName" style="font-size:-1;">by {{entity.dataMap.creatorName}}</div>
					<span class="circle" :style="{ backgroundColor: entity.dataMap.color }"></span>
					{{entity.dataMap.beginText}} - {{entity.dataMap.endText}}
				</div>
			</md-list-item>
		</md-list>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
.approved {
	font-weight: bold;
	color: black;
	padding-left: 5px;
}
.unapproved {
	color: white;
	padding-left: 5px;
}
.begin {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.end {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			me: {},
			data: {},
			currentMonth: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.profile();
	},
	methods: {
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.me = response.data;
					vm.list("");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list(month) {
			let vm = this;
			ajax.get("/system/event/calendar?month=" + month)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.data = response.data;
					vm.currentMonth = month;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showSearch() {
			let vm = this;
			vm.$router.push({
				path: "/sales/event/search"
			});
		}
	}
};
</script>