<template>
	<div>
		<md-button class="md-icon-button md-raised md-dense" @click="$router.go(-1)">
			<md-icon>keyboard_backspace</md-icon>
		</md-button>

		<br />
		<br />

		<div class="md-title">{{entity.name}}</div>

		<div v-html="entity.description" class="desc"></div>

		<div>Region: {{entity.regionName}}</div>

		<md-switch v-model="active" class="md-primary" disabled>Active</md-switch>

		<md-card v-if="entity.imageName">
			<media :name="entity.imageName" />

			<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(entity.imageName)" style="position:absolute;right:0px;bottom:10px;">
				<md-icon>get_app</md-icon>
			</md-button>
		</md-card>

		<div v-if="entity.imageDataList.length > 0">
			<div v-for="item in entity.imageDataList" :key="item">
				<md-card style="margin-bottom:5px;">
					<media :name="item" />

					<md-button class="md-icon-button md-list-action md-raised md-dense" @click="$root.downloadImage(item)" style="position:absolute;right:0px;bottom:10px;">
						<md-icon>get_app</md-icon>
					</md-button>
				</md-card>
			</div>
		</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import media from "./media.vue";

export default {
	components: {
		notification,
		snackbar,
		media
	},
	data() {
		return {
			active: false,
			entity: {
				imageDataList: []
			},
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.find();
	},
	methods: {
		find() {
			let vm = this;
			ajax.get("/system/asset?id=" + this.$route.params.entityId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
					vm.active = vm.entity.status === "Active";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>