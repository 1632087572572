<template>
	<div>
		<div class="md-title">Profile</div>

		<br />

		<md-card>
			<md-card-header>
				<div class="md-subheading">Update profile</div>
			</md-card-header>

			<md-card-content>
				<md-field>
					<label>Name</label>
					<md-input v-model="entity.name" id="inputEditName"></md-input>
				</md-field>

				<md-field>
					<label>Email</label>
					<md-input v-model="entity.email" id="inputEditEmail"></md-input>
				</md-field>

				<md-switch v-model="entity.notifiable" class="md-primary" id="inputEditNotifiable">Notifiable</md-switch>

				<md-field>
					<label>Phone Number</label>
					<md-input v-model="entity.phone" id="inputEditPhone" v-on:keypress="isPhone($event, entity.phone)"></md-input>
				</md-field>

				<md-field>
					<label>Budget Available</label>
					<span class="md-prefix">$</span>
					<md-input v-model="entity.budgetAvailable" id="inputEditBudgetAvailable" disabled></md-input>
				</md-field>

				<md-field>
					<label>Budget Spending</label>
					<span class="md-prefix">$</span>
					<md-input v-model="entity.budgetSpend" id="inputEditBudgetSpend" disabled></md-input>
				</md-field>

				<md-field>
					<label>Budget Limit</label>
					<span class="md-prefix">$</span>
					<md-input v-model="entity.budgetLimit" id="inputEditBudgetLimit" disabled></md-input>
				</md-field>

				<md-field>
					<label>Manager Name</label>
					<md-input v-model="entity.dataMap.managerName" id="inputEditManagerName" disabled></md-input>
				</md-field>

				<md-field>
					<label>Role</label>
					<md-select v-model="entity.role" id="inputEditRole" disabled>
						<md-option v-for="item in personRoleList" :key="item" :value="item">{{item.replace(/_/g, ' ')}}</md-option>
					</md-select>
				</md-field>

				<template v-if="$root.tenantId() != 2">
					<md-field>
						<md-chip v-for="item in entity.regionList" :key="item.id">{{item.region.name}} {{item.approver ? "(Approver)" : ""}}</md-chip>
						<span class="md-helper-text">Region List</span>
					</md-field>
				</template>

				<md-list>
					<md-list-item v-for="location in entity.addressDataList" :key="location.id" style="border-bottom:solid #d9d9d9 1px;">
						<div class="md-list-item-text">
							<span>
								{{location.address}}
								{{location.city}}
								{{location.state}}
								{{location.postcode}}
								{{location.note}}
							</span>
						</div>

						<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAddress(entity, location.id)">
							<md-icon>delete</md-icon>
						</md-button>
					</md-list-item>
				</md-list>

				<md-card style="padding:0px 10px">
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>Address</label>
									<md-input v-model="newAddress.address" id="inputEditAddressAddress"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>City</label>
									<md-input v-model="newAddress.city" id="inputEditAddressCity"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>State</label>
									<md-input v-model="newAddress.state" id="inputEditAddressState"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Postcode</label>
									<md-input v-model="newAddress.postcode" id="inputEditAddressPostcode" v-on:keypress="isPostalCode($event, newAddress.postcode)"></md-input>
								</md-field>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<md-field>
									<label>Address note</label>
									<md-textarea v-model="newAddress.note" id="inputEditAddressNote" md-autogrow></md-textarea>
								</md-field>
							</td>
							<td>
								<md-button class="md-raised md-dense md-primary" @click="addAddress(entity)" style="margin-bottom:25px;">Add address</md-button>
							</td>
						</tr>
					</table>
				</md-card>

				<md-dialog-actions>
					<md-button class="md-raised md-primary" @click="edit()">Update</md-button>
				</md-dialog-actions>
			</md-card-content>
		</md-card>
		<br />

		<md-card>
			<md-card-header>
				<div class="md-subheading">Change password</div>
			</md-card-header>

			<md-card-content>
				<md-field>
					<label>Current password</label>
					<md-input v-model="currentPassword" type="password" id="inputCurrent"></md-input>
				</md-field>

				<md-field>
					<label>New password</label>
					<md-input v-model="newPassword" type="password" id="inputNew"></md-input>
				</md-field>

				<md-field>
					<label>Confirm new password</label>
					<md-input v-model="confirmPassword" type="password" id="inputConfirm"></md-input>
				</md-field>

				<md-dialog-actions>
					<md-button class="md-raised md-primary" @click="changePassword()">Change password</md-button>
				</md-dialog-actions>
			</md-card-content>
		</md-card>

		<md-dialog :md-active.sync="dialogAddAddress.show">
			<md-dialog-title>{{dialogAddAddress.title}}</md-dialog-title>

			<md-content>{{dialogAddAddress.missing}}</md-content>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAddAddress.show = false; dialogAddAddress.missing =''">Close</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entity: {
				dataMap: {},
				addressDataList: []
			},
			personRoleList: [],
			id: 1,
			newAddress: {},
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			dialogAddAddress: { show: false, title: "Address is not complete", missing: "" },
		};
	},
	mounted: function() {
		this.profile();
		this.listPersonRole();
	},
	methods: {
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;

					if (!vm.entity.addressDataList) {
						vm.entity.addressDataList = [];
					} else {
						for (let i in vm.entity.addressDataList) {
							vm.entity.addressDataList[i].id = vm.id++;
						}
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addAddress(entity) {
			let vm = this;
			vm.dialogAddAddress.missing = "";

			if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1 ||
				!vm.newAddress.city || vm.newAddress.city.trim().length < 1 ||
				!vm.newAddress.state || vm.newAddress.state.trim().length < 1 ||
				!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) {
					
					if (!vm.newAddress.address || vm.newAddress.address.trim().length < 1) vm.dialogAddAddress.missing += "Address, ";
					if (!vm.newAddress.city || vm.newAddress.city.trim().length < 1) vm.dialogAddAddress.missing += "City, ";
					if (!vm.newAddress.state || vm.newAddress.state.trim().length < 1) vm.dialogAddAddress.missing += "State, ";
					if (!vm.newAddress.postcode || vm.newAddress.postcode.trim().length < 1) vm.dialogAddAddress.missing += "Postcode, ";
					
					vm.dialogAddAddress.missing = vm.dialogAddAddress.missing.slice(0, -2) + " required.";
					vm.dialogAddAddress.show = true;

			} else {

				vm.newAddress.id = vm.id++;
				entity.addressDataList.push(vm.newAddress);
				vm.newAddress = {};
			}

		},
		deleteAddress(entity, id) {
			let list = entity.addressDataList;
			for (let i in list) {
				if (list[i].id === id) {
					list.splice(i, 1);
					return;
				}
			}
		},
		edit() {
			let vm = this;
			ajax.post("/system/person/edit", {
				entity: vm.entity
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$root.showSnack(vm.snack, "Profile updated");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		changePassword() {
			let vm = this;
			ajax.post("/system/selfservice/changepassword", {
				currentPassword: vm.currentPassword,
				newPassword: vm.newPassword,
				confirmPassword: vm.confirmPassword
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$root.showSnack(vm.snack, "Password changed");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPersonRole() {
			let vm = this;
			ajax.get("/system/enumeration/personrole/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.personRoleList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		}
	}
};
</script>