<template>
	<div>
		<h1 class="md-title" style="padding-bottom:5px;">
			Year
			<md-button class="md-icon-button md-dense md-raised" @click="yearPrevious()">
				<md-icon>chevron_left</md-icon>
			</md-button>
			<span>{{year}}</span>
			<md-button class="md-icon-button md-dense md-raised" @click="yearNext()">
				<md-icon>chevron_right</md-icon>
			</md-button>
		</h1>

		<canvas ref="chart" style="margin-bottom:20px;"></canvas>

		<md-table v-model="entityList" md-sort="name" md-sort-order="asc" md-card style="margin-bottom:20px;">
			<md-table-toolbar>
				<h1 class="md-title">Report</h1>
				<md-button class="md-raised md-primary" @click="downloadInventoryReport()">
					<md-icon>save_alt</md-icon>Download Inventory Report
				</md-button>
				<md-button class="md-raised md-primary" @click="downloadUserReport()">
					<md-icon>save_alt</md-icon>Download User Report
				</md-button>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
				<md-table-cell v-for="monthName in monthNameList" :key="monthName" :md-label="monthName">
					<span v-if="item.dataMap[monthName]">$ {{item.dataMap[monthName]}}</span>
					<span v-else style="color:#dedede;">0</span>
				</md-table-cell>
				<md-table-cell md-label="Total">
					<span v-if="item.dataMap.grandTotal">$ {{item.dataMap.grandTotal}}</span>
					<span v-else style="color:#dedede;">0</span>
				</md-table-cell>
				<md-table-cell md-label>
					<md-button class="md-icon-button md-raised md-dense" @click="month = null; listProductOrder(item.id); selectedPersonId = item.id;">
						<md-icon>expand_more</md-icon>
					</md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<md-table md-card>
			<md-table-toolbar style="margin-bottom:10px;">
				<md-field style="width:150px;right:20px;position:absolute;">
					<label>Month</label>
					<md-select v-model="month" @md-selected="listProductOrder(selectedPersonId)" md-dense id="selectBrand" placeholder="Month">
						<md-option :value="''" selected>All</md-option>
						<template v-for="monthName in monthNameList">
							<md-option :key="monthName" :value="monthName">{{monthName}}</md-option>
						</template>
					</md-select>
				</md-field>
			</md-table-toolbar>
		</md-table>

		<md-table v-if="productOrderList && productOrderList.length > 0" v-model="productOrderList" md-sort="id" md-sort-order="asc" md-card>
			<md-table-toolbar>
				<h1 class="md-title">Order</h1>
				<md-button class="md-raised md-primary" @click="downloadProductOrderReport()">
					<md-icon>save_alt</md-icon>Download User Order Report
				</md-button>
			</md-table-toolbar>

			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell md-label="Order Id" md-sort-by="id">{{ item.id }}</md-table-cell>
				<md-table-cell md-label="Date" md-sort-by="dataMap.createdNumber">{{ item.dataMap.createdText }}</md-table-cell>
				<md-table-cell md-label="Address" md-sort-by="deliveryAddress">{{ item.deliveryAddress }}</md-table-cell>
				<md-table-cell md-label="Note" md-sort-by="note">{{ item.note }}</md-table-cell>
				<md-table-cell md-label="Total" md-sort-by="dataMap.total">$ {{ item.total }}</md-table-cell>
				<md-table-cell md-label>
					<md-button class="md-icon-button md-raised md-dense" @click="listOrderLine(item.id)">
						<md-icon>expand_more</md-icon>
					</md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<md-table v-if="orderLineList && orderLineList.length > 0" v-model="orderLineList" md-sort="id" md-sort-order="asc" md-card style="margin-top: 50px;">
			<md-table-toolbar>
				<h1 class="md-title">Order Line</h1>
			</md-table-toolbar>

			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell md-label="Order Id" md-sort-by="orderId">{{ item.orderId }}</md-table-cell>
				<md-table-cell md-label="Name" md-sort-by="productName">{{ item.productName }}</md-table-cell>
				<md-table-cell md-label="Variant" md-sort-by="variant">{{ item.variant }}</md-table-cell>
				<md-table-cell md-label="Quantity" md-sort-by="quantity">{{ item.quantity }}</md-table-cell>
				<md-table-cell md-label="Price" md-sort-by="dataMap.price">$ {{ item.price }}</md-table-cell>
				<md-table-cell md-label="Subtotal" md-sort-by="dataMap.total">$ {{ item.total }}</md-table-cell>
				<md-table-cell md-label="Status" md-sort-by="status">{{ item.status && item.status.replace(/_/g, ' ') }}</md-table-cell>
				<md-table-cell md-label="Note" md-sort-by="note">{{ item.note }}</md-table-cell>
			</md-table-row>
		</md-table>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import chart from "chart.js";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entityList: [],
			productOrderList: [],
			orderLineList: [],
			selectedPersonId: null,
			monthNameList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			year: null,
			month: null,
			chartEntityLimit: 20,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.yearCurrent();
	},
	methods: {
		list() {
			let vm = this;
			ajax.get("/system/report/person?year=" + vm.year)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;

					let chartData = {
						labels: vm.monthNameList,
						datasets: []
					};

					let i = 0;
					for (let person of vm.entityList) {
						// only top 20
						if (person.dataMap.grandTotal > 0 && i < vm.chartEntityLimit) {
							let color = vm.$root.colorList[i % vm.$root.colorList.length];
							i++;

							let totalList = [];
							for (let monthName of vm.monthNameList) {
								if (person.dataMap[monthName]) {
									totalList.push(person.dataMap[monthName]);
								} else {
									totalList.push(0);
								}
							}

							chartData.datasets.push({
								label: vm.$root.ellipsify(person.name, 20),
								fill: false,
								borderColor: color,
								data: totalList
							});
						}
					}
					this.createChart(this.$refs.chart, chartData);
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listProductOrder(personId) {
			let vm = this;
			vm.orderLineList = null;
			if (personId == null) {
				return;
			}
			ajax.get("/system/report/person?personId=" + personId + "&year=" + vm.year + "&month=" + vm.month)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.productOrderList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listOrderLine(productOrderId) {
			let vm = this;
			ajax.get("/system/report/person?productOrderId=" + productOrderId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.orderLineList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		downloadUserReport() {
			let vm = this;
			location.href = vm.baseLocation + "/system/report/person?action=report&year=" + vm.year;
		},
		downloadProductOrderReport() {
			let vm = this;
			location.href = vm.baseLocation + "/system/report/person?personId=" + vm.selectedPersonId + "&action=report&year=" + vm.year + "&month=" + vm.month;
		},
		downloadInventoryReport() {
			let vm = this;
			location.href = vm.baseLocation + "/system/report/inventory";
		},
		yearCurrent() {
			let vm = this;
			let date = new Date();
			vm.year = date.getFullYear();
			vm.list();
		},
		yearPrevious() {
			let vm = this;
			vm.year--;
			vm.productOrderList = null;
			vm.orderLineList = null;
			vm.selectedPersonId = null;
			vm.month = null;
			vm.$forceUpdate();
			vm.list();
		},
		yearNext() {
			let vm = this;
			vm.year++;
			vm.productOrderList = null;
			vm.orderLineList = null;
			vm.selectedPersonId = null;
			vm.month = null;
			vm.$forceUpdate();
			vm.list();
		},
		createChart(element, data) {
			const myChart = new chart(element, {
				type: "line",
				data: data,
				options: {
					responsive: true,
					title: {
						display: true,
						text: "Top " + this.chartEntityLimit + " Sales Activity"
					}
				}
			});
		}
	}
};
</script>