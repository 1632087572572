<template>
	<div>
		<div class="md-title" style="margin:20px">Order History</div>

		<md-field>
			<label>Search by Order ID</label>
			<md-input v-model="search.keyword" required id="inputSearch" v-on:keyup.enter="pagination('filter')" v-on:keypress="isPhone($event, search.keyword)"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="searchReset()">
				<md-icon>close</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="pagination('filter')">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-toolbar md-elevation="1" style="margin: 0px 20px; width: 97.5%">
			<div style="text-align: right; margin-bottom: 20px; padding: 5px 20px 5px 0px; flex: 1 1 0%" class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="chosenSupplier">Supplier Filter</label>
						<md-select v-model="chosenSupplier" name="chosenSupplier" id="chosenSupplier" placeholder="Supplier Filter">
							<md-option value="-1">All</md-option>
							<template v-if="$root.tenantId() == 1">
								<md-option value="0">Stone & Wood</md-option>
							</template>
							<template v-for="supplier in supplierList">
								<md-option :key="supplier.name" :value="supplier.id">{{supplier.name}}</md-option>
							</template>
						</md-select>
					</md-field>
				</div>
				<div v-if="$root.tenantCustomisation('preferred-printer-email')" class="md-layout-item">
					<md-field>
						<label for="chosenPrinterEmail">Printer Email Filter</label> 
						<md-select v-model="chosenPrinterEmail" name="chosenPrinterEmail" id="chosenPrinterEmail" placeholder="Supplier Filter"> 
							<md-option value="">All</md-option> 
							<template v-for="printerEmail in printerEmailList"> 
								<md-option :key="printerEmail" :value="printerEmail">{{printerEmail}}</md-option> 
							</template> 
						</md-select> 
					</md-field> 
				</div> 
				<div class="md-layout-item">
					<md-field>
						<label for="chosenStatus">Status Filter</label>
						<md-select v-model="chosenStatus" name="chosenStatus" id="chosenStatus" placeholder="Status Filter">
							<md-option value="All">All</md-option>
							<md-option value="None">None</md-option>
							<md-option value="Declined">Declined</md-option>
							<md-option value="Approved">Approved</md-option>
							<md-option value="Completed">Completed</md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="chosenAge">Age</label>
						<md-select v-model="chosenAge" name="chosenAge" id="chosenAge" placeholder="Age">
							<md-option value="All">All</md-option>
							<md-option value="Last30">Last 30 Days</md-option>
						</md-select>
					</md-field>
				</div>
				<md-button class="md-raised md-primary" @click="pagination('filter')">Apply Filter</md-button>
				<md-button class="md-raised md-primary" @click="report()">
					<md-icon>save_alt</md-icon>Download report
				</md-button>
			</div>
		</md-toolbar>
		<div align="center" class="md-layout">
			<div class="md-layout-item" style="text-align: left; padding-left: 8px; margin: 10px 0">
				<md-button :disabled="selectedEntityList.length == 0" class="md-raised" style="text-transform: none" @click="markAsCompleted">Mark selected as Completed</md-button>
			</div>
			<div class="md-layout-item" style="text-align: right; padding-right: 10px; margin: 10px 0">
				<div style="padding-top: 5px; display: inline-block">
					{{ listSize == 0 ? 0 : pageNumber * pageSize + 1 }} - {{ pageNumber * pageSize + pageSize > listSize ? listSize : pageNumber * pageSize + pageSize }} of {{ listSize }} (Page {{ pageNumber + 1 }})
				</div>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('first')" :disabled="pageNumber == 0">
					<md-icon>first_page</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('dec')" :disabled="pageNumber == 0">
					<md-icon>chevron_left</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('inc')" :disabled="pageNumber >= maxPage-1">
					<md-icon>chevron_right</md-icon>
				</md-button>
				<md-button class="md-icon-button md-dense md-raised" @click="pagination('last')" :disabled="pageNumber >= maxPage-1">
					<md-icon>last_page</md-icon>
				</md-button>
			</div>
		</div>
		<md-table md-card>
			<md-table-row>
				<md-table-head aria-colspan="9">Order History</md-table-head>
			</md-table-row>
			<md-table-row v-for="entity in entityList" :key="entity.id">
				<template>
					<md-table-cell colspan="9">
						<md-table md-card style="margin-bottom: 20px">
							<md-table-row>
								<md-table-head>Order Id</md-table-head>
								<md-table-head>Date</md-table-head>
								<md-table-head>Name</md-table-head>
								<md-table-head>Customer</md-table-head>
								<md-table-head colspan="2">Address</md-table-head>
								<md-table-head>City</md-table-head>
								<md-table-head>State</md-table-head>
								<md-table-head>Postcode</md-table-head>
								<md-table-head>Phone</md-table-head>
								<md-table-head>Attention To</md-table-head>
								<md-table-head>Note</md-table-head>
								<md-table-head>Total</md-table-head>
								<md-table-head>Status</md-table-head>
								<md-table-head></md-table-head>
							</md-table-row>

							<md-table-row>
								<md-table-cell>{{ entity.id }}</md-table-cell>
								<md-table-cell>{{ entity.dataMap.createdText }}</md-table-cell>
								<md-table-cell>{{ entity.dataMap.ownerName }}</md-table-cell>
								<md-table-cell>{{ entity.dataMap.deliveryCompanyName }}</md-table-cell>
								<md-table-cell colspan="2">{{ entity.deliveryAddress }}</md-table-cell>
								<md-table-cell>{{entity.deliveryCity}}</md-table-cell>
								<md-table-cell>{{entity.deliveryState}}</md-table-cell>
								<md-table-cell>{{entity.deliveryPostcode}}</md-table-cell>
								<md-table-cell>{{entity.deliveryPhone}}</md-table-cell>
								<md-table-cell>{{entity.attentionTo}}</md-table-cell>
								<md-table-cell>{{ entity.note }}</md-table-cell>
								<md-table-cell>$ {{ entity.total }}</md-table-cell>
								<md-table-cell>{{ entity.status }}</md-table-cell>
								<md-table-cell>
									<div style="white-space: nowrap;">
										<md-button class="md-icon-button md-raised md-dense" @click.stop="showEditAddress(entity)">
											<md-icon>edit</md-icon>
										</md-button>
										<md-button class="md-icon-button md-raised md-dense md-primary" @click.stop="showEditCharge(entity)" v-if="$root.tenantCustomisation('lion-charge-code')">
											<md-icon>edit</md-icon>
										</md-button>
									</div>
								</md-table-cell>
							</md-table-row>

							<md-table-row v-if="$root.tenantCustomisation('lion-charge-code')">
								<md-table-head></md-table-head>
								<md-table-head>Type: {{ entity.chargeItemType }}</md-table-head>
								<md-table-head>CCID: {{ entity.chargeCcid }}</md-table-head>
								<md-table-head>Customer Number: {{ entity.chargeReceiverCode }}</md-table-head>
								<md-table-head>Freight: ${{ entity.freightCost }}</md-table-head>
								<md-table-head>Despatch Date: {{ entity.transitMap.despatchDate }}</md-table-head>
								<md-table-head>Done: {{ entity.done ? 'Yes' : 'No' }}</md-table-head>
								<md-table-head>Charge Posted: {{ entity.transitMap.chargePosted }}</md-table-head>
							</md-table-row>

							<md-table-row>
								<md-table-head></md-table-head>
								<md-table-head>Order Id {{ entity.id }}</md-table-head>
								<md-table-head>Name</md-table-head>
								<md-table-head>Variant</md-table-head>
								<md-table-head>Quantity</md-table-head>
								<md-table-head>Price</md-table-head>
								<md-table-head>Subtotal</md-table-head>
								<md-table-head>Note</md-table-head>
								<md-table-head>Status</md-table-head>
							</md-table-row>

							<md-table-row v-for="line in entity.orderLineList" :key="line.id" :class="line.productType">
								<md-table-cell>
									<md-checkbox v-model="selectedEntityList" :value="line" v-if="line.status !== 'Completed' && line.status !== 'Declined'"></md-checkbox>
								</md-table-cell>
								<md-table-cell>
									<md-avatar>
										<img :src="baseLocation + '/asset/file/medium/' + line.imageName
												+ (line.productType == 'MenuImage' ? '?randomId=' + randomId : '')" />
									</md-avatar>
								</md-table-cell>
								<md-table-cell>
									<router-link v-if="line.productId" :to="'/administrator/' + (line.productType && line.productType.toLowerCase()) + ($root.tenantId() == 1 ? '/sw/edit/' + line.productId : '/edit/' + line.productId)">{{line.productName}}</router-link>
									<span v-else>{{line.productName}}</span>
								</md-table-cell>
								<md-table-cell>{{line.variant}}</md-table-cell>
								<md-table-cell>{{line.quantity}}</md-table-cell>
								<md-table-cell>$ {{line.price}}</md-table-cell>
								<md-table-cell>$ {{line.total}}</md-table-cell>
								<md-table-cell>{{line.note}}</md-table-cell>
								<md-table-cell>{{line.status && line.status.replace(/_/g, ' ')}}</md-table-cell>
								<md-table-cell>
									<div style="min-width:125px;">
										<md-button class="md-icon-button md-raised md-dense" @click="showEdit(line)">
											<md-icon>edit</md-icon>
										</md-button>
										<!--
										<md-button class="md-icon-button md-raised md-dense" @click="selectedId = line.id;dialogDelete.show = true;">
											<md-icon>delete</md-icon>
										</md-button>
										-->
										<md-button v-if="line.status == 'None' && $root.tenantCustomisation('resubmission') && line.productType !== 'MenuImage' && line.productType !== 'LimitedRelease'" class="md-icon-button md-raised md-dense" @click="$router.push('/administrator/' + (line.productType && line.productType.toLowerCase()) + '/view/' + line.productId + '?orderLineId=' + line.id)"> 
											<md-icon title="Resubmit">brush</md-icon> 
										</md-button> 
										<md-button class="md-icon-button md-raised md-dense" @click="$root.downloadImage(line.imageName)">
											<md-icon>get_app</md-icon>
										</md-button>
									</div>
								</md-table-cell>
							</md-table-row>
						</md-table>
					</md-table-cell>
				</template>
			</md-table-row>
		</md-table>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Status</label>
				<md-select v-model="entity.status" id="inputStatus">
					<md-option value="None"></md-option>
					<md-option value="Declined">Declined</md-option>
					<md-option value="Approved">Approved</md-option>
					<md-option value="Completed">Completed</md-option>
				</md-select>
			</md-field>

			<md-field>
				<label>Note</label>
				<md-textarea v-model="entity.note" id="inputNote" md-autogrow></md-textarea>
			</md-field>

			<md-switch v-if="$root.tenantCustomisation('print-2-sided') && entity.dataMap && entity.dataMap.menuImageDataMap" v-model="entity.dataMap.printTwoSided" class="md-primary" id="inputPrintTwoSided" disabled>Print Two Sided</md-switch>

			<div v-if="dialogEdit.isMenuImage" class="d-flex">
				<div>
					<md-card>
						<md-card-media>
							<div style="padding: 10px">
								<img
									:src="baseLocation 
										+ '/asset/file/' 
										+ (dialogEdit.isQuickRendered == true ? entity.dataMap.menuImageDataMap.renderDataMap.draft : entity.dataMap.menuImageDataMap.renderDataMap.finalDraft)
										+ '?randomId=' + randomId"
								/>
							</div>
						</md-card-media>
					</md-card>
				</div>
				<div>
					<md-field>
						<label>Update top text line</label>
						<md-input v-model="entity.dataMap.menuImageDataMap.topLine" :disabled="entity.status != 'None'"></md-input>
					</md-field>

					<md-field>
						<label>Update bottom text line</label>
						<md-input v-model="entity.dataMap.menuImageDataMap.bottomLine" :disabled="entity.status != 'None'"></md-input>
					</md-field>
					<md-button class="md-raised md-dense md-primary" @click="resetMenuImageForm()" :disabled="entity.status != 'None'">Reset</md-button>
					<md-button class="md-raised md-dense md-primary" @click="renderMenuImage('orderpreview')" :disabled="entity.status != 'None'">Quick render</md-button>
				</div>
			</div>

			<md-dialog-actions>
				<md-button class="md-raised" @click="closeEditDialog()">Close</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEditAddress.show" style="min-width:600px;">
			<md-dialog-title>{{dialogEditAddress.title}}</md-dialog-title>

			<md-field>
				<label>Delivery Address</label>
				<md-textarea v-model="productOrderEntity.deliveryAddress" id="inputAddress" md-autogrow :disabled="!canUpdateAddress"></md-textarea>
			</md-field>
			<md-field>
				<label>City</label>
				<md-textarea v-model="productOrderEntity.deliveryCity" id="inputCity" md-autogrow :disabled="!canUpdateAddress"></md-textarea>
			</md-field>
			<md-field>
				<label>State</label>
				<md-textarea v-model="productOrderEntity.deliveryState" id="inputState" md-autogrow :disabled="!canUpdateAddress"></md-textarea>
			</md-field>
			<md-field>
				<label>Post Code</label>
				<md-textarea v-model="productOrderEntity.deliveryPostcode" id="inputPostcode" md-autogrow :disabled="!canUpdateAddress" v-on:keypress="isPostalCode($event, productOrderEntity.deliveryPostcode)"></md-textarea>
			</md-field>
			<md-field>
				<label>Phone</label>
				<md-textarea v-model="productOrderEntity.deliveryPhone" id="inputPhone" md-autogrow :disabled="!canUpdateAddress" v-on:keypress="isPhone($event, productOrderEntity.deliveryPhone)"></md-textarea>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEditAddress.show = false">Close</md-button>
				<md-button v-if="canUpdateAddress" class="md-raised md-primary" @click="editAddress()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEditCharge.show">
			<div style="width:600px;">
				<md-dialog-title>{{dialogEditCharge.title}}</md-dialog-title>

				<md-field>
					<label>Freight Cost</label>
					<span class="md-prefix">$</span>
					<md-input v-model="productOrderEntity.freightCost"></md-input>
				</md-field>

				<md-field>
					<label>Despatch Date (format: DD-MM-YYYY)</label>
					<md-input v-model="productOrderEntity.transitMap.despatchDate"></md-input>
				</md-field>

				<md-checkbox v-model="productOrderEntity.done">Mark as done</md-checkbox>

				<div>
					Charge Type: {{ productOrderEntity.chargeItemType }}<br/>
					Charge Total (Total Order Cost): ${{ productOrderEntity.chargeTotal }}<br/>
					CCID: {{ productOrderEntity.chargeCcid }}<br/>
					Customer Number: {{ productOrderEntity.chargeReceiverCode }}<br/>
					Posted: {{ productOrderEntity.transitMap.chargePosted }}<br/>
				</div>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogEditCharge.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="editCharge()">Save</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
tr.LimitedRelease td {
	color: #03b3b2;
}
.md-table-row:hover:not(.md-header-row) .md-table-cell {
	background: unset !important;
}
.md-progress-bar {
	margin: 24px;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			dialogEdit: {
				show: false,
				title: "Edit",
				isMenuImage: false,
				isQuickRendered: false
			},
			dialogDelete: {
				show: false,
				title: "Delete",
				content: "Are you sure you want to delete this?"
			},
			dialogEditCharge: {
				show: false,
				title: "Update Charge Data"
			},
			selectedId: null,
			entity: {
				dataMap: {},
				transitMap: {}
			},
			entityList: [],
			supplierList: [],
			chosenSupplier: -1,
			chosenStatus: "All",
			printerEmailList: [], 
			chosenPrinterEmail: "", 
			chosenAge: this.$root.tenantId() == 1 ? "Last30" : "Last30",
			selectedEntityList: [],
			dialogEditAddress: { show: false, title: "Edit" },
			productOrderEntity: {
				dataMap: {},
				transitMap: {}
			},
			pageNumber: 0,
			pageSize: 10,
			listSize: 0,
			maxPage: 0,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			menuImageTemp: {
				oldTopLine: null,
				oldBottomLine: null
			},
			randomId: new Date().valueOf(),
			canUpdateAddress: true,
			search: { show: false, keyword: ""},
		};
	},
	mounted: function() {
		this.listCompanyOrder();
		this.listSupplier();
		this.listPrinterEmail(); 
	},
	methods: {
		searchReset() {
			let vm = this;
			vm.search.show = false;
			vm.search.keyword = "";
			this.$store.commit("fillSearchOrderId", null);
			this.listCompanyOrder();
		},
		markAsCompleted() {
			let vm = this;
			ajax
				.post("/system/orderline/complete", {
					orderLineList: vm.selectedEntityList
				})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.selectedEntityList = [];
					vm.listCompanyOrder();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		pagination(mode) {
			let vm = this;
			vm.pageNumber = mode == "inc" ? vm.pageNumber + 1 : mode == "dec" ? vm.pageNumber - 1 : mode == "last" ? vm.maxPage - 1 : 0;
			vm.listCompanyOrder();
		},
		editAddress() {
			let vm = this;
			vm.dialogEditAddress.show = false;

			ajax.post("/system/productorder/edit", {
				id: vm.productOrderEntity.id,
				address: vm.productOrderEntity.deliveryAddress
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listCompanyOrder();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showEditAddress(item) {
			let vm = this;
			let orderList = item.orderLineList;

			orderList.forEach(order => {
				vm.canUpdateAddress = true;
				if (order.supplierName !== undefined) {
					if (order.supplierName === "TAVO") {
						vm.canUpdateAddress = false;
					}
				}
			});

			vm.productOrderEntity = item;
			vm.dialogEditAddress.show = true;
		},
		showEditCharge(item) {
			let vm = this;
			vm.productOrderEntity = vm.$root.clone(item);
			vm.dialogEditCharge.show = true;
		},
		editCharge() {
			let vm = this;
			vm.dialogEditCharge.show = false;

			ajax.post("/system/productorder/charge/edit", {
				id: vm.productOrderEntity.id,
				done: vm.productOrderEntity.done,
				freightCost: vm.productOrderEntity.freightCost,
				despatchDate: vm.productOrderEntity.transitMap.despatchDate
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listCompanyOrder();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listSupplier() {
			let vm = this;
			ajax.post("/system/company/list", {
				type: "Supplier"
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.supplierList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listPrinterEmail() { 
			let vm = this; 
			ajax.get("/system/person/printerEmail/list") 
				.then(response => { 
					vm.$root.logSuccess(response); 
					vm.printerEmailList = response.data; 
				}) 
				.catch(error => { 
					vm.$root.logError(error); 
					vm.$root.showNotification(vm.notification, error.response); 
				}); 
		}, 
		listCompanyOrder() {
			let vm = this;
			ajax.get(
				"/system/productorder/company/list?status=" +
					vm.chosenStatus +
					(vm.chosenSupplier != null ? "&supplierId=" + vm.chosenSupplier : "") +
					"&printerEmail=" + vm.chosenPrinterEmail + 
					"&age=" +
					vm.chosenAge +
					"&keyword=" +
					vm.search.keyword +
					"&pageNumber=" +
					vm.pageNumber +
					"&pageSize=" +
					vm.pageSize +
					(vm.$route.query.productType ? "&productType=" + vm.$route.query.productType : "")
			)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listSize = response.data.size;
					vm.entityList = response.data.list;
					if (vm.pageNumber == 0) {
						vm.maxPage = Math.floor(vm.listSize / ((vm.pageNumber + 1) * vm.pageSize) + (vm.listSize % vm.pageSize > 0 ? 1 : 0));
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list() {
			let vm = this;
			ajax.get(
				"/system/productorder/list?action=company" +
					"&status=" +
					vm.chosenStatus +
					(vm.chosenSupplier != null ? "&supplierId=" + vm.chosenSupplier : "") +
					"&age=" +
					vm.chosenAge +
					"&pageNumber=" +
					vm.pageNumber +
					"&pageSize=" +
					vm.pageSize +
					(vm.$route.query.productType ? "&productType=" + vm.$route.query.productType : "")
			)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listSize = response.data.size;
					vm.entityList = response.data.list;
					if (vm.pageNumber == 0) {
						vm.maxPage = Math.floor(vm.listSize / ((vm.pageNumber + 1) * vm.pageSize) + (vm.listSize % vm.pageSize > 0 ? 1 : 0));
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		report() {
			let vm = this;
			var keywords = "&status=" + vm.chosenStatus + "&supplierId=" + vm.chosenSupplier + "&age=" + vm.chosenAge + "&keyword=" + vm.search.keyword;
			location.href = vm.baseLocation + "/system/productorder/list?action=company!report" + keywords;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/orderline/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listCompanyOrder();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showEdit(item) {
			let vm = this;
			vm.entity = {
				id: item.id,
				status: item.status,
				note: item.note,
				variant: item.variant,
				dataMap: item.dataMap
			};
			
			if (vm.entity.dataMap && vm.entity.dataMap.menuImageDataMap) {
				vm.menuImageTemp.oldTopLine = vm.entity.dataMap.menuImageDataMap.topLine;
				vm.menuImageTemp.oldBottomLine = vm.entity.dataMap.menuImageDataMap.bottomLine;
				vm.dialogEdit.isMenuImage = true;
			} else {
				vm.dialogEdit.isMenuImage = false;
			}

			vm.dialogEdit.show = true;
		},
		resetMenuImageForm() {
			let vm = this;
			vm.entity.dataMap.menuImageDataMap.topLine = vm.menuImageTemp.oldTopLine;
			vm.entity.dataMap.menuImageDataMap.bottomLine = vm.menuImageTemp.oldBottomLine;
			vm.dialogEdit.isQuickRendered = false;
		},
		edit() {
			let vm = this;

			if (vm.dialogEdit.isMenuImage == true && (vm.menuImageTemp.oldTopLine != vm.entity.dataMap.menuImageDataMap.topLine || vm.menuImageTemp.oldBottomLine != vm.entity.dataMap.menuImageDataMap.bottomLine)) {
				vm.renderMenuImage("editorder");
			}

			ajax.post("/system/orderline/edit", {
				entity: vm.entity
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.listCompanyOrder();
					vm.closeEditDialog();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		closeEditDialog() {
			let vm = this;
			vm.menuImageTemp = {
				oldTopLine: null,
				oldBottomLine: null
			};
			vm.dialogEdit.isMenuImage = false;
			vm.dialogEdit.show = false;
			vm.dialogEdit.isQuickRendered = false;
		},
		renderMenuImage(mode) {
			let vm = this;
			let formData = new FormData();
			let product = {
				dataMap: {
					renderDataMap: vm.entity.dataMap.menuImageDataMap.renderDataMap
				}
			};

			formData.append("entity", JSON.stringify(product));
			if (vm.entity.dataMap.menuImageDataMap.topLine) {
				formData.append("topLine", JSON.stringify(vm.entity.dataMap.menuImageDataMap.topLine));
			}
			if (vm.entity.dataMap.menuImageDataMap.bottomLine) {
				formData.append("bottomLine", JSON.stringify(vm.entity.dataMap.menuImageDataMap.bottomLine));
			}
			formData.append("font", JSON.stringify(vm.entity.dataMap.menuImageDataMap.font));
			formData.append("color", JSON.stringify(vm.entity.dataMap.menuImageDataMap.color));
			formData.append("size", JSON.stringify(vm.entity.dataMap.menuImageDataMap.size));

			ajax.post("/system/menuimage/render?action=" + mode, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.randomId = new Date().valueOf();

					if (mode == "orderpreview") {
						console.log("Quick Rendered");
						vm.dialogEdit.isQuickRendered = true;
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		}
	}
};
</script>