<template>
	<div>
		<div class="md-title">Volume Tracker </div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id">
				<div class="md-list-item-text" style="padding-left: 10px;margin-bottom: 20px;">
					<div>{{ entity.name }}</div>
					<div v-html="entity.description" style="padding-top: 10px;font-size: small;"></div>
				</div>
				<md-button class="md-raised" @click="isValidEmail = true; emailList = ''; selectedId = entity.id; dialogSendEmail.show = true;">Send Email</md-button>
				<md-button class="md-icon-button md-raised md-dense" @click="downloadVolumeTracker(entity.id)">
					<md-icon>get_app</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<md-dialog :md-active.sync="dialogSendEmail.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogSendEmail.title }}</md-dialog-title>

				<md-field>
					<label for="inputEditEmail">Please write email address(es)</label>
					<md-input v-model="emailList" id="inputEditEmail"></md-input>
				</md-field>
				<div v-if="!isValidEmail" style="color: red; margin-top: -15px; padding-bottom: 10px;">Please enter a valid email address</div>
				
				<div>Separate each email with a comma</div>
				<br />

				<md-dialog-actions>
					<md-button class="md-raised" @click="emailList = ''; isValidEmail = true; dialogSendEmail.show = false">Close</md-button>
					<md-button class="md-raised md-primary" @click="sendEmails(selectedId)">Send</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<md-dialog :md-active.sync="dialogSuccessSendEmail.show">
			<div class="dialog-container">
				<md-dialog-title>{{ dialogSuccessSendEmail.title }}</md-dialog-title>

				<md-dialog-actions>
					<md-button class="md-raised" @click="dialogSuccessSendEmail.show = false">Close</md-button>
				</md-dialog-actions>
			</div>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<script>
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			me: {},
			entityList: [],
			selectedId: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			dialogSendEmail: {
				show: false,
				title: "Send Email"
			},
			dialogSuccessSendEmail: {
				show: false,
				title: "Email Sent Successfully"
			},
			emailList: '',
			isValidEmail: true,
		}
	},
	mounted() {
		this.list();
	},
	watch: {
		emailList(newEmailList) {
			const emails = newEmailList.split(',');

			// Check if any email in the list is not valid (i.e., not containing '@')
			this.isValidEmail = emails.every((email) => this.checkValidEmail(email.trim()));
		}
	},
	methods: {
		checkValidEmail(email) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/venue/list/sales")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/venue/search/sales", {
					keyword: vm.keyword
				})
					.then((response) => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch((error) => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		downloadVolumeTracker(venueId) {
			let vm = this;
			
			let formData = new FormData();
			formData.append("venueId", venueId);

			ajax.post("/system/venue/volumetracker/download", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then((response) => {
					vm.$root.logSuccess(response);
					location.href = ajax.defaults.baseURL + "/asset/file/" + response.data + "?download&time=" + new Date();
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		sendEmails(venueId) {
			let vm = this;

			if (vm.isValidEmail) {
				// Remove spaces between email addresses
				const emailListWithoutSpaces = this.emailList.replace(/\s*,\s*/g, ',');
				// Convert the email list string to an array of email addresses
				const emailListArray = emailListWithoutSpaces.split(',');
				
				ajax.post("/system/venue/volumetracker/sendemail", {
					venueId: venueId,
					emailList: emailListArray
				})
					.then((response) => {
						vm.$root.logSuccess(response);
						this.dialogSendEmail.show = false;
						this.dialogSuccessSendEmail.show = true;
					})
					.catch((error) => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		}
	}
}

</script>