<template>
	<div style="min-height:50px;padding:10px;text-align:center;">
		<iframe :src="baseLocation + '/asset/file/' + name" v-if="name && name.endsWith('.pdf')" frameborder="0" style="height:300px;overflow:scroll;width:100%;"></iframe>
		<img :src="baseLocation + '/asset/file/large/' + name" v-else-if="name && (name.toLowerCase().endsWith('.jpg') || name.toLowerCase().endsWith('.jpeg') || name.toLowerCase().endsWith('.png'))" />
		<video v-else-if="name && name.toLowerCase().endsWith('.mp4')" controls :src="baseLocation + '/asset/file/' + name"></video>
		<div v-else style="padding:20px 30px;">{{name}}</div>
	</div>
</template>

<style scoped>
</style>

<script>
import ajax from "axios";

export default {
	data() {
		return {
			baseLocation: ajax.defaults.baseURL
		};
	},
	props: {
		name: {}
	}
};
</script>