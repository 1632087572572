<template>
	<div>
		<div class="md-title">{{productType}} - {{entity.name}}</div>

		<md-field>
			<label>Search {{productType}}</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
		</md-field>

		<md-list v-if="entityList != null">
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar>
					<img :src="baseLocation + '/asset/file/medium/' + entity.imageName" />
				</md-avatar>

				<div class="md-list-item-text">
					<router-link :to="{path:'/sales/' + $route.params.productType + '/view/' + entity.id}" :class="entity.status">{{entity.name}}</router-link>
				</div>

				<md-chip class="chip-inventory-type" v-if="entity.customizable">Customizable</md-chip>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			entity: {},
			entityList: [],
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.find();
		this.list();
	},
	watch: {
		"$route.params.productType": function() {
			this.list();
		}
	},
	computed: {
		productType: function() {
			let type = this.$route.params.productType;
			return type == "limitedrelease" ? "Limited Release" : type == "pointofsale" ? "Point Of Sale" : type == "merchandise" ? "Merchandise" : "";
		}
	},
	methods: {
		find() {
			let vm = this;
			ajax.get("/system/brand?id=" + this.$route.params.brandId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entity = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/product/list?type=" + vm.productType.replace(/ /g, "") + "&brandId=" + this.$route.params.brandId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/product/search?type=" + vm.productType.replace(/ /g, ""), {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
			vm.keyword = "";
		}
	}
};
</script>
