<template>
	<div>
		<div class="md-title">Create new Point Of Sale</div>

		<md-field>
			<label>Offer name</label>
			<md-input v-model="entity.name" id="inputName"></md-input>
			<span class="md-helper-text">Enter your product name starting with your Brand name e.g Pacific Ale Wobbler - 2022</span>
		</md-field>

		<div v-html="entity.description" class="desc"></div>

		<md-field>
			<label>Offer description</label>
			<md-textarea v-model="entity.description" id="inputDescription" md-autogrow></md-textarea>
		</md-field>

		<md-field>
			<label>Brand</label>
			<md-select v-model="entity.brandId" md-dense id="selectBrand">
				<template v-for="item in brandList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<slot name="category" :entity="entity"></slot>

		<md-field>
			<label>Supplier</label>
			<md-select v-model="entity.supplierId" md-dense id="selectSupplier">
				<md-option :key="-1" :value="''"></md-option>
				<template v-for="item in supplierList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-field v-if="$root.tenantCustomisation('product-region')">
			<label>Region</label>
			<md-select v-model="entity.regionId" md-dense id="selectRegion">
				<md-option :key="-1" :value="''"></md-option>
				<template v-for="item in regionList">
					<md-option :key="item.id" :value="item.id">{{item.name}}</md-option>
				</template>
			</md-select>
		</md-field>

		<md-switch v-model="entity.customizable" class="md-primary" id="inputCustomizable" @change="customizableChanged">Customisable</md-switch>

		<md-switch v-model="entity.autoApprove" class="md-primary" id="inputAutoApprove" v-if="$root.tenantCustomisation('auto-approve-switch')">Auto approve</md-switch>

		<div v-if="$root.tenantCustomisation('custom-pos-type') && entity.customizable">
			<md-radio id="selectCustomisable" v-model="entity.dataMap.typeCustomisable" v-for="typeCustomisable in typesCustomisable" :key="typeCustomisable" :value="typeCustomisable" @change="selectTypeCustomizable">{{ getTextCustomisation(typeCustomisable) }} </md-radio>
		</div>

		<template v-if="(entity.dataMap.typeCustomisable == null || entity.dataMap.typeCustomisable == 'fully')">
			<md-field v-if="entity.customizable && $root.tenantCustomisation('editable-slug')" style="margin-bottom:50px;">
				<label>Editable slug lines</label>
				<md-input v-model="entity.dataMap.editableSlug" id="inputName"></md-input>
				<span class="md-helper-text">e.g. 1,5,6-20</span>
			</md-field>

			<md-field v-if="entity.customizable && $root.tenantCustomisation('slug-replication')" style="margin-bottom:50px;">
				<label>Slug replication</label>
				<md-input v-model="entity.dataMap.slugReplication" id="inputName"></md-input>
				<span
					class="md-helper-text"
				>format is bounding box (width x height) followed by horizontal positions of columns e.g. 200x300 250,1250,2250 (this means the bounding box for each column is 200 pixels by 300 pixels, the columns are located at 250,1250,2250 pixels from the left)</span>
			</md-field>
		</template>
		
		<md-field>
			<label>Main image</label>
			<md-file @md-change="handleFileUpload" id="inputAttachment" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 1</label>
			<md-file @md-change="handleFileUpload1" id="inputAttachment1" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Additional file 2</label>
			<md-file @md-change="handleFileUpload2" id="inputAttachment2" accept="image/x-png,image/jpeg,application/pdf,image/gif,image/bmp" />
		</md-field>

		<md-field>
			<label>Inventory Type</label>
			<md-select v-model="entity.inventoryType" md-dense id="selectInventoryType">
				<template v-for="item in inventoryTypeList">
					<md-option :key="item" :value="item">{{item}}</md-option>
				</template>
			</md-select>
		</md-field>

		<template v-if="entity.inventoryType == 'OneSize'">
			<table class="layout">
				<tr>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantity"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePrice"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPrice"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[0].stockLimit" id="inputStockLimit"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
			</table>
		</template>

		<template v-if="entity.inventoryType == 'MultiSize'">
			<table class="layout">
				<tr>
					<td class="md-subheading">XS</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityXS"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceXS"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceXS"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[0].stockLimit" id="inputStockLimitXS"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">S</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityS"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceS"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceS"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[1].stockLimit" id="inputStockLimitS"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">M</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[2].quantity" id="inputQuantityM"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[2].basePrice" id="inputBasePriceM"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[2].tierPrice" id="inputTierPriceM"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[2].stockLimit" id="inputStockLimitM"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">L</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[3].quantity" id="inputQuantityL"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[3].basePrice" id="inputBasePriceL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[3].tierPrice" id="inputTierPriceL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>	
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[3].stockLimit" id="inputStockLimitL"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">XL</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[4].quantity" id="inputQuantityXL"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[4].basePrice" id="inputBasePriceXL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[4].tierPrice" id="inputTierPriceXL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>	
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[4].stockLimit" id="inputStockLimitXL"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">XXL</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[5].quantity" id="inputQuantityXXL"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[5].basePrice" id="inputBasePriceXXL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[5].tierPrice" id="inputTierPriceXXL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>	
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[5].stockLimit" id="inputStockLimitXXL"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">XXXL</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityXXXL"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceXXXL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceXXXL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>	
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[6].stockLimit" id="inputStockLimitXXXL"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
			</table>
		</template>

		<template v-if="entity.inventoryType == 'Paper'">
			<table class="layout">
				<tr>
					<td class="md-subheading">A0</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityA0"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceA0"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceA0"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[0].stockLimit" id="inputStockLimitA0"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">A1</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityA1"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceA1"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceA1"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[1].stockLimit" id="inputStockLimitA1"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">A2</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[2].quantity" id="inputQuantityA2"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[2].basePrice" id="inputBasePriceA2"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[2].tierPrice" id="inputTierPriceA2"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[2].stockLimit" id="inputStockLimitA2"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">A3</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[3].quantity" id="inputQuantityA3"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[3].basePrice" id="inputBasePriceA3"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[3].tierPrice" id="inputTierPriceA3"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[3].stockLimit" id="inputStockLimitA3"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">A4</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[4].quantity" id="inputQuantityA4"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[4].basePrice" id="inputBasePriceA4"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[4].tierPrice" id="inputTierPriceA4"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[4].stockLimit" id="inputStockLimitA4"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">A5</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[5].quantity" id="inputQuantityA5"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[5].basePrice" id="inputBasePriceA5"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[5].tierPrice" id="inputTierPriceA5"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[5].stockLimit" id="inputStockLimitA5"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('inventory-dl')">
					<td class="md-subheading">DL</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[6].quantity" id="inputQuantityDL"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[6].basePrice" id="inputBasePriceDL"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[6].tierPrice" id="inputTierPriceDL"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[6].stockLimit" id="inputStockLimitDL"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('inventory-tent-card')">
					<td class="md-subheading">Tent Card</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[7].quantity" id="inputQuantityTentCard"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[7].basePrice" id="inputBasePriceTentCard"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[7].tierPrice" id="inputTierPriceTentCard"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[7].stockLimit" id="inputStockLimitTendCard"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
			</table>
		</template>

		<template v-if="entity.inventoryType == 'KegCarton'">
			<table class="layout">
				<tr>
					<td class="md-subheading">Keg</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[0].quantity" id="inputQuantityKeg"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[0].basePrice" id="inputBasePriceKeg"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[0].tierPrice" id="inputTierPriceKeg"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[0].stockLimit" id="inputStockLimitKeg"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
				<tr>
					<td class="md-subheading">Carton</td>
					<td>
						<md-field>
							<label>Stock</label>
							<md-input v-model="entity.dataMap.inventory[1].quantity" id="inputQuantityCarton"></md-input>
							<span class="md-helper-text">Leave empty if unlimited</span>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Base Price</label>
							<span class="md-prefix">$</span>
							<md-input v-model="entity.dataMap.inventory[1].basePrice" id="inputBasePriceCarton"></md-input>
						</md-field>
					</td>
					<td>
						<md-field>
							<label>Tier Price</label>
							<md-input v-model="entity.dataMap.inventory[1].tierPrice" id="inputTierPriceCarton"></md-input>
							<span class="md-helper-text">e.g. 100 $7,200 $6,300 $5</span>
						</md-field>
					</td>
				</tr>
				<tr v-if="$root.tenantCustomisation('low-stock-alert')">
					<td></td>
					<td>
						<md-field>
							<label>Stock Limit</label>
							<md-input v-model="entity.dataMap.inventory[1].stockLimit" id="inputStockLimitCarton"></md-input>
							<span class="md-helper-text">If stock goes below or equal to this number, an alert will be sent. Leave empty for no alert</span>
						</md-field>
					</td>
				</tr>
			</table>
		</template>

		<md-dialog-actions>
			<md-button class="md-raised" to="/administrator/pointofsale">Cancel</md-button>
			<md-button class="md-raised md-primary" @click="submitFileAdd()">Add</md-button>
		</md-dialog-actions>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			entity: {
				type: "PointOfSale",
				inventoryType: null,
				inventoryDataList: [],
				customizable: false,
				autoApprove: true,
				dataMap: {
					inventory: [{}, {}, {}, {}, {}, {}, {}, {}],
					typeCustomisable: "fully"
				}
			},
			inventoryTypeList: [],
			supplierList: [],
			regionList: [],
			brandList: [],
			attachment: null,
			attachment1: null,
			attachment2: null,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			typesCustomisable : ["limitedA1", "limitedA3", "limitedA4", "fully"]
		};
	},
	mounted: function() {
		this.listBrand();
		this.listInventoryType();
		this.listSupplier();
		this.listRegion();
	},
	methods: {
		listBrand() {
			let vm = this;
			ajax.get("/system/brand/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brandList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listInventoryType() {
			let vm = this;
			ajax.get("/system/enumeration/inventorytype/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.inventoryTypeList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listSupplier() {
			let vm = this;
			ajax.get("/system/company/list?type=Supplier")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.supplierList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		handleFileUpload1(fileList) {
			this.attachment1 = fileList[0];
		},
		handleFileUpload2(fileList) {
			this.attachment2 = fileList[0];
		},
		submitFileAdd() {
			let vm = this;
			let formData = new FormData();
			vm.entity.supplierId = vm.entity.supplierId == "" ? null : vm.entity.supplierId;
			vm.entity.regionId = vm.entity.regionId == "" ? null : vm.entity.regionId;
			formData.append("entity", JSON.stringify(vm.entity));
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			}
			if (vm.attachment1) {
				formData.append("attachment1", vm.attachment1);
			}
			if (vm.attachment2) {
				formData.append("attachment2", vm.attachment2);
			}
			ajax.post("/system/product/add", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.$router.push({ path: "/administrator/pointofsale" });
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listRegion() {
			let vm = this;
			ajax.get("/system/region/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.regionList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectTypeCustomizable(value) {
			if (value == "limitedA1" || value == "limitedA3" || value == "limitedA4") {
				this.entity.dataMap.editableSlug = "10-13";
			}
		},
		getTextCustomisation(value) {
			if (value == "limitedA1") {
				return "Limited A1";
			}
			if (value == "limitedA3") {
				return "Limited A3";
			}
			if (value == "limitedA4") {
				return "Limited A4";
			}
			return "Full Customisation";
		},
		customizableChanged() {
			if (this.entity.customizable) {
				this.entity.autoApprove = false;
			} else {
				this.entity.autoApprove = true;
			}
		}
	}
};
</script>