<template>
	<div>
		<div class="md-title">List of User Group</div>

		<md-field>
			<label>Search</label>
			<md-input v-model="keyword" required id="inputSearch" v-on:keyup.enter="search()"></md-input>

			<md-button class="md-icon-button md-raised md-dense" @click="search()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showAdd()">
				<md-icon>add</md-icon>
			</md-button>
		</md-field>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<div class="md-list-item-text">
					<span :class="entity.status">{{entity.name}}</span>
				</div>

				<table>
					<tr>
						<td>
							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
								<md-icon>edit</md-icon>
							</md-button>
						</td>
					</tr>
				</table>
			</md-list-item>
		</md-list>

		<div v-if="!$root.loader.value && entityList.length == 0">Empty list</div>

		<md-dialog :md-active.sync="dialogAdd.show">
			<md-dialog-title>{{dialogAdd.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityAdd.name" id="inputAddName"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAdd.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="add()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<!-- EDIT DIALOG -->

		<md-dialog :md-active.sync="dialogEdit.show">
			<hr style="width:50vw; color:transparent; border-color: transparent; background-color: transparent"/>
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>
			<md-card>

				<md-card-content>
					<md-field>
						<label>Name</label>
						<md-input v-model="entityEdit.name" id="inputEditName"></md-input>
					</md-field>

					<md-switch v-model="entityEdit.isActive" class="md-primary" id="inputEditStatus">Active</md-switch>
				</md-card-content>
			</md-card>

			<!-- Add Product -->
			<md-card>
				<md-card-header>
					<div class="md-subheading"><b>Products that can be accessed/ordered by this group :</b></div>
				</md-card-header>

				<md-card-content>
					<md-field>
						<label>Search Product</label>
						<md-input v-model="keywordProduct" v-on:keyup.enter="searchProduct()"></md-input>
						<md-button class="md-icon-button md-raised md-dense" @click="searchProductReset()">
							<md-icon>close</md-icon>
						</md-button>
						<md-button class="md-icon-button md-raised md-dense" @click="searchProduct()">
							<md-icon>search</md-icon>
						</md-button>
					</md-field>

					<div v-if="isSearchProduct">
						<table v-if="productList.length > 0" style="width:115%">
							<tr>
								<td>
									<md-field>
										<md-select v-model="chosenProduct"> 
											<template v-for="product in productList"> 
												<md-option :key="product.id" :value="product.id" >{{product.name}}</md-option> 
											</template> 
										</md-select>
									</md-field>
								</td>
								<td>
									<md-button class="md-raised md-primary" @click="addProduct()">Add</md-button>
								</td>
							</tr>
						</table>
						<div v-else>
							Empty list
						</div>
					</div>

					<md-list>
						<md-list-item v-for="entity in chosenProductList" :key="entity" style="border-bottom:solid #d9d9d9 1px;">
							<div class="md-list-item-text">
								<span>
									{{entity}}
								</span>
							</div>

							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteProduct(entity)">
								<md-icon>delete</md-icon>
							</md-button>
						</md-list-item>
					</md-list>
				</md-card-content>
			</md-card>

			<!-- Add Assets -->
			<md-card>
				<md-card-header>
					<div class="md-subheading"><b>Assets that can be accessed/ordered by this group :</b></div>
				</md-card-header>

				<md-card-content>
					<md-field>
						<label>Search Asset</label>
						<md-input v-model="keywordAsset" v-on:keyup.enter="searchAsset()"></md-input>
						<md-button class="md-icon-button md-raised md-dense" @click="searchAssetReset()">
							<md-icon>close</md-icon>
						</md-button>
						<md-button class="md-icon-button md-raised md-dense" @click="searchAsset()">
							<md-icon>search</md-icon>
						</md-button>
					</md-field>

					<div v-if="isSearchAsset">
						<table v-if="assetList.length > 0" style="width:115%">
							<tr>
								<td>
									<md-field>
										<md-select v-model="chosenAsset"> 
											<template v-for="asset in assetList"> 
												<md-option :key="asset.id" :value="asset.id" >{{asset.name}}</md-option> 
											</template> 
										</md-select>
									</md-field>
								</td>
								<td>
									<md-button class="md-raised md-primary" @click="addAsset()">Add</md-button>
								</td>
							</tr>
						</table>
						<div v-else>
							Empty list
						</div>
					</div>

					<md-list>
						<md-list-item v-for="entity in chosenAssetList" :key="entity" style="border-bottom:solid #d9d9d9 1px;">
							<div class="md-list-item-text">
								<span>
									{{entity}}
								</span>
							</div>

							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteAsset(entity)">
								<md-icon>delete</md-icon>
							</md-button>
						</md-list-item>
					</md-list>
				</md-card-content>
			</md-card>

			<!-- Add User -->
			<md-card>
				<md-card-header>
					<div class="md-subheading"><b>Members of this group :</b></div>
				</md-card-header>

				<md-card-content>
					<md-field>
						<label>Search User</label>
						<md-input v-model="keywordUser" v-on:keyup.enter="searchUser()"></md-input>
						<md-button class="md-icon-button md-raised md-dense" @click="searchUserReset()">
							<md-icon>close</md-icon>
						</md-button>
						<md-button class="md-icon-button md-raised md-dense" @click="searchUser()">
							<md-icon>search</md-icon>
						</md-button>
					</md-field>

					<div v-if="isSearchUser">
						<table v-if="userList.length > 0" style="width:115%">
							<tr>
								<td>
									<md-field>
										<md-select v-model="chosenUser"> 
											<template v-for="user in userList"> 
												<md-option :key="user.id" :value="user.id">{{user.name}}</md-option> 
											</template> 
										</md-select> 
									</md-field>
								</td>
								<td>
									<md-button class="md-raised md-primary" @click="addUser()">Add</md-button>
								</td>
							</tr>
						</table>
						<div v-else>
							Empty list
						</div>
					</div>

					<md-list>
						<md-list-item v-for="entity in chosenUserList" :key="entity" style="border-bottom:solid #d9d9d9 1px;">
							<div class="md-list-item-text">
								<span>
									{{entity}}
								</span>
							</div>

							<md-button class="md-icon-button md-list-action md-raised md-dense" @click="deleteUser(entity)">
								<md-icon>delete</md-icon>
							</md-button>
						</md-list-item>
					</md-list>
				</md-card-content>
			</md-card>

			<md-dialog-actions>
				<md-button class="md-raised" @click="editClose">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
/* this has to be the same enum value of EntityStatus.Inactive */
.Inactive {
	color: #aaa !important;
}
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			keyword: "",
			dialogAdd: { show: false, title: "Add new user group" },
			dialogEdit: { show: false, title: "Edit user group" },
			dialogDelete: {
				show: false,
				title: "Delete user group",
				content: "Are you sure you want to delete this user group?"
			},
			entityAdd: {
				// dataMap: {},
				// addressDataList: []
			},
			entityEdit: { isActive: false, dataMap: {}, productDataList: [], assetDataList: [], userDataList: [] },
			entityList: [],
			id: 1,
			newAddress: {},
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },

			keywordProduct: "",
			productList: [],
			chosenProduct: 0,
			chosenProductList: [],
			isSearchProduct: false,

			keywordAsset: "",
			assetList: [],
			chosenAsset: 0,
			chosenAssetList: [],
			isSearchAsset: false,

			keywordUser: "",
			userList: [],
			chosenUser: 0,
			chosenUserList: [],
			isSearchUser: false,

		};
	},
	mounted: function() {
		this.list();
	},
	methods: {
		deleteUser(user) {
			let vm = this;

			vm.chosenUserList = vm.chosenUserList.filter(
				u => u !== user
			);

			vm.entityEdit.userDataList = vm.entityEdit.userDataList.filter(
				u => u.name !== user
			);

			vm.$forceUpdate();
		},
		deleteProduct(item) {
			let vm = this;

			vm.chosenProductList = vm.chosenProductList.filter(
				p => p !== item
			);

			vm.entityEdit.productDataList = vm.entityEdit.productDataList.filter(
				p => p.name !== item
			);

			vm.$forceUpdate();
		},
		deleteAsset(item) {
			let vm = this;

			vm.chosenAssetList = vm.chosenAssetList.filter(
				a => a !== item
			);

			vm.entityEdit.assetDataList = vm.entityEdit.assetDataList.filter(
				a => a.name !== item
			);

			vm.$forceUpdate();
		},
		addUser() {
			let vm = this;
			if (vm.entityEdit.userDataList == undefined ) {
				vm.entityEdit.userDataList = []
			} 
			let user = vm.userList.find(u => u.id == vm.chosenUser);
			
			// make sure no duplicate user
			if (vm.entityEdit.userDataList.find(u => u.id == user.id) == undefined) {

				vm.entityEdit.userDataList.push(user);
				vm.chosenUserList.push(user.name);

				console.log(vm.entityEdit.userDataList)
			}
			vm.chosenUser = new Map();
			vm.keywordUser = "";
			vm.userList = [];
			vm.isSearchUser = false;
		},
		addProduct() {
			let vm = this;
			if (vm.entityEdit.productDataList == undefined ) {
				vm.entityEdit.productDataList = []
			} 
			let product = vm.productList.find(p => p.id == vm.chosenProduct);

			// make sure no duplicate product
			if (vm.entityEdit.productDataList.find(p => p.id == product.id) == undefined) {
			
				vm.entityEdit.productDataList.push(product);
				vm.chosenProductList.push(product.name);
	
				console.log(vm.entityEdit.productDataList)
			
			}
			vm.chosenProduct = 0;
			vm.keywordProduct = "";
			vm.productList = [];
			vm.isSearchProduct = false;
		},
		addAsset() {
			let vm = this;
			if (vm.entityEdit.assetDataList == undefined ) {
				vm.entityEdit.assetDataList = []
			} 
			let asset = vm.assetList.find(a => a.id == vm.chosenAsset);

			// make sure no duplicate asset
			if (vm.entityEdit.assetDataList.find(a => a.id == asset.id) == undefined) {
			
				vm.entityEdit.assetDataList.push(asset);
				vm.chosenAssetList.push(asset.name);
	
				console.log(vm.entityEdit.assetDataList)
			
			}
			vm.chosenAsset = 0;
			vm.keywordAsset = "";
			vm.assetList = [];
			vm.isSearchAsset = false;
		},
		editClose() {
			let vm = this;
			vm.dialogEdit.show = false;

			vm.keywordProduct = "";
			vm.productList = [];
			vm.chosenProduct = 0;
			
			vm.keywordUser = "";
			vm.userList = [];
			vm.chosenUser = "";
		},
		list() {
			let vm = this;
			vm.keyword = "";
			ajax.get("/system/usergroup/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data;
					vm.keyword = "";
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		searchUser() {
			let vm = this;
			if (vm.keywordUser && vm.keywordUser.trim() != "") {
				vm.chosenBrand = vm.chosenBrand == -1 ? null : vm.chosenBrand;
				vm.chosenCategory = vm.chosenCategory == -1 ? null : vm.chosenCategory;
				ajax.post("/system/person/admin/search", {
					keyword: vm.keywordUser
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.userList = response.data;
						vm.chosenUser = vm.userList[0].id;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
				vm.isSearchUser = true
			}
		},
		searchUserReset() {
			let vm = this;
			vm.keywordUser = "";
			vm.isSearchUser = false
			vm.searchUser();
		},
		searchProduct() {
			let vm = this;
			if (vm.keywordProduct && vm.keywordProduct.trim() != "") {
				vm.chosenBrand = vm.chosenBrand == -1 ? null : vm.chosenBrand;
				vm.chosenCategory = vm.chosenCategory == -1 ? null : vm.chosenCategory;
				ajax.post("/system/product/search", {
					keyword: vm.keywordProduct
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.productList = response.data;
						vm.chosenProduct = vm.productList[0].id;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
				vm.isSearchProduct = true
			}
		},
		searchProductReset() {
			let vm = this;
			vm.keywordProduct = "";
			vm.isSearchProduct = false
			vm.searchProduct();
		},
		searchAsset() {
			let vm = this;
			if (vm.keywordAsset && vm.keywordAsset.trim() != "") {
				ajax.post("/system/category/search", {
					keyword: vm.keywordAsset
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.assetList = response.data;
						console.log(response.data);
						vm.chosenAsset = vm.assetList[0].id;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
				vm.isSearchAsset = true
			}
		},
		searchAssetReset() {
			let vm = this;
			vm.keywordAsset = "";
			vm.isSearchAsset = false
			vm.searchAsset();
		},
		search() {
			let vm = this;
			if (vm.keyword && vm.keyword.trim() == "") {
				vm.list();
			} else {
				ajax.post("/system/usergroup/search", {
					keyword: vm.keyword
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.entityList = response.data;
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		showAdd() {
			let vm = this;
			vm.entityAdd = {
				// dataMap: {},
				// addressDataList: []
			};
			vm.dialogAdd.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit = {
				id: item.id,
				name: item.name,
				isActive: item.isActive == null ? false : item.isActive,
				userDataList: item.userDataList,
				productDataList: item.productDataList,
				assetDataList: item.assetDataList,

				// isActive: "Active" === item.isActive,
				// phone: item.phone,
				// notifiable: item.notifiable,
				// addressDataList: item.addressDataList,
				// dataMap: {}
			};

			if (!vm.entityEdit.userDataList) {
				vm.entityEdit.userDataList = [];
			} else {
				vm.chosenUserList = vm.entityEdit.userDataList.map(user => user.name)
			}

			if (!vm.entityEdit.productDataList) {
				vm.entityEdit.productDataList = [];
			} else {
				vm.chosenProductList = vm.entityEdit.productDataList.map(product => product.name)
			}

			if (!vm.entityEdit.assetDataList) {
				vm.entityEdit.assetDataList = [];
			} else {
				vm.chosenAssetList = vm.entityEdit.assetDataList.map(asset => asset.name)
			}

			// if (!vm.entityEdit.addressDataList) {
			// 	vm.entityEdit.addressDataList = [];
			// } else {
			// 	for (let i in vm.entityEdit.addressDataList) {
			// 		vm.entityEdit.addressDataList[i].id = vm.id++;
			// 	}
			// }

			vm.dialogEdit.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			console.log(vm.entityEdit.id );
			ajax.post("/system/usergroup/delete", { id: vm.entityEdit.id })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		add() {
			let vm = this;
			vm.dialogAdd.show = false;
			ajax.post("/system/usergroup/add", {
				entity: vm.entityAdd
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			// vm.entityEdit.status = vm.entityEdit.active ? "Active" : "Inactive";
			
			console.log("<<<<< ", vm.entityEdit)
			
			ajax.post("/system/usergroup/edit", {
				entity: vm.entityEdit
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.keyword != "" ? vm.search() : vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
			});
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
	}
};
</script>
