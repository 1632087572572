<template>
	<div>
		<div class="md-title" style="margin:20px 0;">Cart</div>

		<md-table md-card>
			<md-table-row>
				<md-table-head></md-table-head>
				<md-table-head></md-table-head>
				<md-table-head>Type</md-table-head>
				<md-table-head>Name</md-table-head>
				<md-table-head>Variant</md-table-head>
				<md-table-head>Quantity</md-table-head>
				<md-table-head>Price</md-table-head>
				<md-table-head>Subtotal</md-table-head>
				<md-table-head>Note</md-table-head>
				<md-table-head></md-table-head>
			</md-table-row>

			<md-table-row v-for="entity in entityList" :key="entity.id">
				<md-table-cell><md-checkbox v-model="entity.selection" @change="checkCartIsOneType"></md-checkbox></md-table-cell>
				<md-table-cell>
					<md-avatar>
						<img :src="baseLocation + '/asset/file/medium/' + entity.imageName
							+ (entity.dataMap && entity.dataMap.menuImageDataMap ? '?randomId=' + randomId : '')" />
					</md-avatar>
				</md-table-cell>
				<md-table-cell>{{ entity.dataMap.productType }}</md-table-cell>
				<md-table-cell>
					<router-link :to="'/sales/' + (entity.dataMap.productType.toLowerCase()) + '/view/' + entity.productId
							+  (entity.dataMap.productType == 'MenuImage' ? '?variant=' + entity.variant : '')">{{entity.dataMap.productName}}</router-link>
				</md-table-cell>
				<md-table-cell>{{entity.variant}}</md-table-cell>
				<md-table-cell>{{entity.quantity}}</md-table-cell>
				<md-table-cell>$ {{entity.price}}</md-table-cell>
				<md-table-cell>$ {{entity.subtotal}}</md-table-cell>
				<md-table-cell>{{entity.note}}</md-table-cell>
				<md-table-cell>
					<md-button class="md-icon-button md-raised md-dense" @click="showEdit(entity)">
						<md-icon>edit</md-icon>
					</md-button>
					<md-button class="md-icon-button md-raised md-dense" @click="selectedId = entity.id;dialogDelete.show = true;">
						<md-icon>delete</md-icon>
					</md-button>
					<template v-if="!$root.tenantCustomisation('restrict-download')">
						<md-button class="md-icon-button md-raised md-dense" @click="$root.downloadImage(entity.imageName)">
							<md-icon>get_app</md-icon>
						</md-button>
					</template>
				</md-table-cell>
			</md-table-row>

			<md-table-row>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell>Total</md-table-cell>
				<md-table-cell>{{total}}</md-table-cell>
				<md-table-cell></md-table-cell>
				<md-table-cell></md-table-cell>
			</md-table-row>
		</md-table>

		<div style="margin-top:12px;margin-left:15px;">
			<fieldset>
				<legend>Budget</legend>
				Limit: $ {{me.budgetLimit}}
				<br />
				Spending: $ {{me.budgetSpend}}
				<br />
				Available: $ {{me.budgetAvailable}}
			</fieldset>
		</div>

		<md-card v-if="entityList.length > 0">
			<md-card-content>
				<md-field>
					<label>Deliver to</label>
					<md-select v-model="deliveryType" id="inputDeliveryType" @md-selected="changeDeliveryType()">
						<md-option value="company">Customer</md-option>
						<md-option value="self">Myself</md-option>
						<md-option value="custom">Custom</md-option>
					</md-select>
				</md-field>

				<table class="layout">
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>Attention to</label>
									<md-input v-model="deliveryAttentionTo"></md-input>
								</md-field>
							</td>
							<td>
								<md-field v-if="deliveryType == 'self'">
									<label>Phone Number</label>
									<md-input v-model="phoneNumberSelf" v-on:keypress="isPhone($event, phoneNumberSelf)"></md-input>
								</md-field>
								<md-field v-else-if="deliveryType == 'custom'">
									<label>Phone Number</label>
									<md-input v-model="phoneNumberCustom" v-on:keypress="isPhone($event, phoneNumberCustom)"></md-input>
								</md-field>
								<md-field v-else>
									<label>Phone Number</label>
									<md-input v-model="phoneNumberCompany" v-on:keypress="isPhone($event, phoneNumberCompany)"></md-input>
								</md-field>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<md-field>
									<label>Note</label>
									<md-input v-model="deliveryNote"></md-input>
								</md-field>
							</td>
						</tr>
					</table>
				</table>

				<!-- <md-field>
					<label>Attention to/Note</label>
					<md-input v-model="deliveryNote"></md-input>
				</md-field> -->

				<template v-if="deliveryType == 'self'">
					<div>
						<md-radio v-model="deliveryAddress" v-for="address in me.addressDataList" :key="address.address" :value="stringify(address)">{{stringify(address)}}</md-radio>
					</div>
				</template>

				<template v-else-if="deliveryType == 'custom'">
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>Address</label>
									<md-input v-model="deliveryAddress" id="inputDeliveryAddress"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>City</label>
									<md-input v-model="deliveryCity" id="inputDeliveryCity"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>State</label>
									<md-input v-model="deliveryState" id="inputDeliveryState"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Post Code</label>
									<md-input v-model="deliveryPostCode" id="inputDeliveryPostCode" v-on:keypress="isPostalCode($event, deliveryPostCode)"></md-input>
								</md-field>
							</td>
						</tr>
					</table>
				</template>

				<template v-else-if="deliveryType == 'company'">
					<template v-if="companyCount <= 50">
						<label>Customer</label>
						<br />
						<template v-for="company in companyList">
							<md-radio v-model="selectedCompanyId" :key="company.id" :value="company.id" v-on:change="resetAddress()">{{company.name}} ({{company.accountId}}) {{(company.addressDataList && company.addressDataList.length > 0) ? (' - ' + $root.coalesce(company.addressDataList[0].city, '') + ' ' + $root.coalesce(company.addressDataList[0].state, '')) : ''}}</md-radio>
							<br :key="'br-' + company.id" />
						</template>
					</template>
					<template v-else>
						<md-field>
							<label>Search customer</label>
							<md-input v-model="keywordCustomer" v-on:keyup.enter="searchCustomer()"></md-input>
							<md-button class="md-icon-button md-raised md-dense" @click="searchCustomer()">
								<md-icon>search</md-icon>
							</md-button>
						</md-field>
						<template v-for="company in companyList">
							<md-radio v-model="selectedCompanyId" :key="company.id" :value="company.id" v-on:change="resetAddress()">{{company.name}} ({{company.accountId}}) {{(company.addressDataList && company.addressDataList.length > 0) ? (' - ' + $root.coalesce(company.addressDataList[0].city, '') + ' ' + $root.coalesce(company.addressDataList[0].state, '')) : ''}}</md-radio>
							<br :key="'br-' + company.id" />
						</template>
						<div v-if="selectedCompanyId != null"> 
						</div>
					</template>
					<div v-if="selectedCompanyAddressList().length > 0">
						<label>Customer address</label>
						<br />
						<md-radio v-model="deliveryAddress" v-for="address in selectedCompanyAddressList()" :key="address.address" :value="stringify(address)">{{stringify(address)}}</md-radio>
					</div>
				</template>

				<template v-if="$root.tenantCustomisation('lion-charge-code') && (cartProductType === 'Merchandise' || (cartProductType === 'PointOfSale' && deliveryType != 'self'))">
					<h4>Charge Codes</h4>
					<table class="layout">
						<tr>
							<td>
								<md-field>
									<label>CCID/Conditional Contract (10 digits)</label>
									<md-input v-model="chargeCcid" maxlength="10"></md-input>
								</md-field>
							</td>
							<td>
								<md-field>
									<label>Customer Number</label>
									<md-input v-model="chargeReceiverCode"></md-input>
								</md-field>
							</td>
						</tr>
					</table>
				</template>

				<div style="text-align:right;margin-bottom:20px;">
					<md-button class="md-raised md-primary" @click="checkout()">Checkout</md-button>
				</div>
			</md-card-content>
		</md-card>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>New Quantity</label>
				<md-input v-model="entity.quantity" id="inputEditQuantity" number></md-input>
				<span class="md-helper-text">Must be lower</span>
			</md-field>

			<md-field>
				<label>Note</label>
				<md-textarea v-model="entity.note" id="inputNote" md-autogrow></md-textarea>
			</md-field>

			<md-switch v-if="$root.tenantCustomisation('print-2-sided') && entity.dataMap && entity.dataMap.productType == 'MenuImage'" v-model="entity.dataMap.printTwoSided" class="md-primary" id="inputPrintTwoSided">Print Two Sided</md-switch>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<md-dialog :md-active.sync="dialogNotification.show">
			<md-dialog-title>{{dialogNotification.title}}</md-dialog-title>
			{{dialogNotification.content}}
			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogNotification.show = false">Ok</md-button>
			</md-dialog-actions>
		</md-dialog>

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";

export default {
	components: {
		notification,
		snackbar
	},
	data() {
		return {
			dialogNotification: {
				show: false,
				title: "Notification",
				content: "Checkout successful"
			},
			dialogEdit: { show: false, title: "Edit" },
			dialogDelete: {
				show: false,
				title: "Delete",
				content: "Are you sure you want to delete this?"
			},
			me: {},
			companyList: [],
			companyCount: null,
			keywordCustomer: "",
			selectedCompanyId: null,
			deliveryType: null,
			deliveryCompanyId: null,
			deliveryAddress: "",
			deliveryCity: null,
			deliveryState: null,
			deliveryPostCode: null,
			deliveryAttentionTo: "",
			deliveryNote: "",
			entityList: [],
			entity: {},
			total: 0,
			baseLocation: ajax.defaults.baseURL,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" },
			randomId: new Date().valueOf(),
			phoneNumberSelf: "",
			phoneNumberCustom: "",
			phoneNumberCompany: "",
			chargeCcid: null,
			chargeReceiverCode: null,
			cartProductType: null
		};
	},
	mounted: function() {
		ajax.all([this.countCompany(), this.profile()]).then(() => {
			this.list();
		});
	},
	methods: {
		list() {
			let vm = this;
			ajax.get("/system/cartline/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.entityList = response.data.entityList;
					vm.total = response.data.total;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			ajax.post("/system/cartline/delete", { id: vm.selectedId })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.me = response.data;
					vm.phoneNumberSelf = vm.me.phone == null ? "" : vm.me.phone;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		countCompany() {
			let vm = this;
			ajax.get("/system/company/salesperson/count")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.companyCount = response.data;

					if (vm.companyCount && vm.companyCount <= 50) {
						vm.listCompany();
					}
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		listCompany() {
			let vm = this;
			ajax.get("/system/company/salesperson/list")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.companyList = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		stringify(address) {
			let text = "";
			if (address.note) {
				text += " " + address.note;
			}
			if (address.address) {
				text += " " + address.address;
			}
			if (address.city) {
				text += " " + address.city;
			}
			if (address.state) {
				text += " " + address.state;
			}
			if (address.postcode) {
				text += " " + address.postcode;
			}
			if (text.substring(0, 1) == " ") {
				text = text.substring(1);
			}
			return text;
		},
		selectedCompanyAddressList() {
			for (let i in this.companyList) {
				if (this.companyList[i].id == this.selectedCompanyId) {
					if (this.companyList[i].addressDataList != null) {
						return this.companyList[i].addressDataList;
					} else {
						break;
					}
					
				}
			}
			return [];
		},
		checkout() {
			let vm = this;
			let deliveryPhone = vm.deliveryType == 'self' ? vm.phoneNumberSelf : vm.deliveryType == 'company' ? vm.phoneNumberCompany : vm.phoneNumberCustom;
			ajax.post("/system/productorder/add", {
				deliveryType: vm.deliveryType,
				companyId: vm.selectedCompanyId,
				deliveryAddress: vm.deliveryAddress,
				deliveryCity: vm.deliveryCity,
				deliveryState: vm.deliveryState,
				deliveryPostCode: vm.deliveryPostCode,
				deliveryAttentionTo: vm.deliveryAttentionTo,
				deliveryNote: vm.deliveryNote,
				deliveryPhone: deliveryPhone,
				chargeCcid: vm.chargeCcid,
				chargeReceiverCode: vm.chargeReceiverCode, 
				entityList: vm.entityList
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.dialogNotification.show = true;
					vm.profile();
					vm.list();
					vm.$emit("profile-update");
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showEdit(item) {
			let vm = this;
			vm.entity = {
				id: item.id,
				quantity: item.quantity,
				note: item.note,
				variant: item.variant,
				dataMap: item.dataMap
			};
			vm.dialogEdit.show = true;
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			ajax.post("/system/cartline/edit", {
				entity: vm.entity
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		searchCustomer() {
			let vm = this;
			if (vm.keywordCustomer && vm.keywordCustomer.trim() != "") {
				ajax.post("/system/company/search?type=Customer", {
					keyword: vm.keywordCustomer
				})
					.then(response => {
						vm.$root.logSuccess(response);
						vm.companyList = response.data;
						if (vm.companyList.phone != null) {
							vm.phoneNumberCompany = vm.companyList.phone;
						}
					})
					.catch(error => {
						vm.$root.logError(error);
						vm.$root.showNotification(vm.notification, error.response);
					});
			}
		},
		isPostalCode(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
			
			if (event.target.value.length > 4) // postal code digit is 5
			return event.preventDefault();
		},
		isPhone(event, message) {
			if (!/^\d*$/.test(event.key) ||  
				(event.key == "." || /\./.test(message))  // only accept number
			)  
			return event.preventDefault();
		},
		changeDeliveryType() {
			this.deliveryAddress = "";
			this.deliveryAttentionTo = "";
			this.deliveryNote = "";

			this.phoneNumberCustom = "";
			this.phoneNumberCompany = "";
			this.phoneNumberSelf = "";

			this.deliveryCity = "";
			this.deliveryState = "";
			this.deliveryPostCode = "";

			this.keywordCustomer = "";
		},
		resetAddress() {
			this.deliveryAddress = "";
			for (let i in this.companyList) {
				if (this.companyList[i].id == this.selectedCompanyId) {
					this.chargeReceiverCode = this.companyList[i].accountId;
				}
			}
		},
		checkCartIsOneType() {
			let productType = null;
			for (let entity of this.entityList) {
				if (entity.selection) {
					if (entity.dataMap && entity.dataMap.productType) {
						if (productType === null) {
							productType = entity.dataMap.productType;
						} else {
							if (productType !== entity.dataMap.productType) {
								this.$root.showNotification(this.notification, { status:400, data: { type: 'NOTIFICATION', noticeList: ['Please note you cannot mix Merchandise and Point of Sale items in 1 order'] }});
							}
						}
					}
				}
			}
			this.cartProductType = productType;
		}
	}
};
</script>