<template>
	<div>
		<div class="md-title">Media</div>
		{{path}}
		<br />
		<br />

		<div>
			<md-button class="md-icon-button md-raised md-dense" @click="upFolder()">
				<md-icon>keyboard_arrow_up</md-icon>
			</md-button>
			<md-button class="md-icon-button md-raised md-dense" @click="showSearch()">
				<md-icon>search</md-icon>
			</md-button>
			<md-button v-if="me.role == 'Administrator'" class="md-icon-button md-raised md-dense" @click="showAddFolder()">
				<md-icon>add_circle</md-icon>
			</md-button>
			<md-button v-if="me.role == 'Administrator'" class="md-icon-button md-raised md-dense" @click="showAddFile()">
				<md-icon>add</md-icon>
			</md-button>
		</div>

		<md-list>
			<md-list-item v-for="entity in entityList" :key="entity.id" style="border-bottom:solid #d9d9d9 1px;">
				<md-avatar
					v-if="!entity.folder && entity.name && (entity.name.toLowerCase().endsWith('.jpg') || entity.name.toLowerCase().endsWith('.jpeg') || entity.name.toLowerCase().endsWith('.png') || entity.name.toLowerCase().endsWith('.gif') || entity.name.toLowerCase().endsWith('.bmp') || entity.name.toLowerCase().endsWith('.svg'))"
				>
					<img :src="baseLocation + '/asset' + $root.encodeUrl(entity.pathName) + '?cache&size=medium'" />
				</md-avatar>
				<md-avatar class="square" v-else-if="entity.folder">
					<md-icon>folder</md-icon>
				</md-avatar>
				<md-avatar class="square" v-else>
					<md-icon>attachment</md-icon>
				</md-avatar>

				<div class="md-list-item-text">
					<span v-if="entity.folder" @click="changeFolder(entity.name)" style="text-decoration:underline;cursor:pointer;color:#448aff;">{{entity.name}}</span>

					<span v-else>{{entity.name}}</span>
				</div>

				<div class="md-list-item-text" v-if="!entity.folder">
					<mediasize :size="entity.size" />
					<p>{{ entity.modifiedText }}</p>
				</div>

				<md-button class="md-icon-button md-list-action md-raised md-dense" @click="download(entity.pathName)" v-if="!entity.folder">
					<md-icon>get_app</md-icon>
				</md-button>
				
				<md-button v-if="me.role == 'Administrator'" class="md-icon-button md-list-action md-raised md-dense" @click="showEdit(entity)">
					<md-icon>edit</md-icon>
				</md-button>
			</md-list-item>
		</md-list>

		<md-dialog :md-active.sync="dialogAddFolder.show">
			<md-dialog-title>{{dialogAddFolder.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityAddFolder.name" id="inputAddFolderName" v-on:keyup.enter="addFolder()"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAddFolder.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="addFolder()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogAddFile.show">
			<md-dialog-title>{{dialogAddFile.title}}</md-dialog-title>

			<md-field>
				<label>File</label>
				<md-file @md-change="handleFileUpload" id="inputAttachment" />
			</md-field>

			<md-switch v-model="entityAdd.extract" class="md-primary" id="inputExtract">Extract zip</md-switch>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogAddFile.show = false">Close</md-button>
				<md-button class="md-raised md-primary" @click="addFile()">Add</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog :md-active.sync="dialogEdit.show">
			<md-dialog-title>{{dialogEdit.title}}</md-dialog-title>

			<md-field>
				<label>Name</label>
				<md-input v-model="entityEdit.newName" id="inputEditName"></md-input>
			</md-field>

			<md-dialog-actions>
				<md-button class="md-raised" @click="dialogEdit.show = false">Close</md-button>
				<md-button class="md-raised md-accent" @click="dialogDelete.show = true">Delete</md-button>
				<md-button class="md-raised md-primary" @click="edit()">Save</md-button>
			</md-dialog-actions>
		</md-dialog>

		<md-dialog-confirm class="confirm" :md-active.sync="dialogDelete.show" :md-title="dialogDelete.title" :md-content="dialogDelete.content" md-confirm-text="Yes, delete" md-cancel-text="No" @md-cancel="dialogDelete.show = false" @md-confirm="remove" />

		<span v-if="'false'"></span>
		<notification :notification="notification"></notification>
		<snackbar :snack="snack"></snackbar>
	</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
import ajax from "axios";
import notification from "./notification.vue";
import snackbar from "./snackbar.vue";
import mediasize from "./mediasize.vue";

export default {
	components: {
		notification,
		snackbar,
		mediasize
	},
	data() {
		return {
			keyword: "",
			brand: {},
			dialogAddFolder: { show: false, title: "Add new folder" },
			dialogAddFile: { show: false, title: "Add new file" },
			dialogEdit: { show: false, title: "Rename" },
			dialogDelete: {
				show: false,
				title: "Delete",
				content: "Are you sure you want to delete this file/folder?"
			},
			entityAddFolder: {},
			entityAdd: { extract: false },
			entityEdit: { active: false },
			entityList: [],
			me: {},
			path: "/",
			baseLocation: ajax.defaults.baseURL,
			attachment: null,
			notification: { show: false, title: "", content: "" },
			snack: { show: false, content: "" }
		};
	},
	mounted: function() {
		this.profile();
		this.getBrand();
	},
	watch: {
		"$route.params.path": function() {
			this.list();
		}
	},
	methods: {
		profile() {
			let vm = this;
			ajax.get("/system/person/me")
				.then(response => {
					vm.$root.logSuccess(response);
					vm.me = response.data;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		getBrand() {
			let vm = this;
			ajax.get("/system/brand?id=" + this.$route.params.brandId)
				.then(response => {
					vm.$root.logSuccess(response);
					vm.brand = response.data;
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		list() {
			let vm = this;
			ajax.post("/system/drive/list", { code: vm.brand.code, path: vm.$route.params.path })
				.then(response => {
					vm.$root.logSuccess(response);
					vm.path = response.data.path;
					vm.entityList = response.data.list;
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		showAddFolder() {
			let vm = this;
			vm.entityAddFolder.name = "";
			vm.dialogAddFolder.show = true;
		},
		showAddFile() {
			let vm = this;
			vm.attachment = null;
			vm.entityAdd.name = "";
			vm.dialogAddFile.show = true;
		},
		showEdit(item) {
			let vm = this;
			vm.entityEdit.name = item.name;
			vm.entityEdit.newName = item.name;
			vm.dialogEdit.show = true;
		},
		remove() {
			let vm = this;
			vm.dialogDelete.show = false;
			vm.dialogEdit.show = false;
			ajax.post("/system/drive/delete", {
				code: vm.brand.code,
				path: vm.$route.params.path,
				entity: vm.entityEdit
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		handleFileUpload(fileList) {
			this.attachment = fileList[0];
		},
		addFile() {
			let vm = this;
			vm.dialogAddFile.show = false;
			let formData = new FormData();
			if (vm.attachment) {
				formData.append("attachment", vm.attachment);
			
			formData.append("code", JSON.stringify(vm.brand.code));}
			formData.append("path", JSON.stringify(vm.$route.params.path));
			formData.append("extract", JSON.stringify(vm.entityAdd.extract));
			ajax.post("/system/drive/add/file", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		addFolder() {
			let vm = this;
			vm.dialogAddFolder.show = false;
			ajax.post("/system/drive/add/folder", {
				code: vm.brand.code,
				path: vm.$route.params.path,
				entity: vm.entityAddFolder
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		edit() {
			let vm = this;
			vm.dialogEdit.show = false;
			ajax.post("/system/drive/edit", {
				code: vm.brand.code,
				path: vm.$route.params.path,
				entity: vm.entityEdit,
				name: vm.entityEdit.newName
			})
				.then(response => {
					vm.$root.logSuccess(response);
					vm.list();
				})
				.catch(error => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		download(name) {
			let vm = this;
			location.href = vm.baseLocation + "/asset/" + vm.$root.encodeUrl(name) + "?download&time=" + new Date();
		},
		changeFolder(name) {
			let vm = this;
			vm.$router.push({
				path: "/sales/drive/sw/" + vm.brand.id + "/" + vm.$route.params.path + vm.$root.encodePathName(name) + ":"
			});
			// vm.$router.go();
		},
		upFolder() {
			let vm = this;
			let path = vm.$route.params.path;
			path = path.substring(0, path.lastIndexOf(":"));
			path = path.substring(0, path.lastIndexOf(":"));
			vm.$router.push({ path: "/sales/drive/sw/" + vm.brand.id + "/" + path + ":" });
			// vm.$router.go();
		},
		showSearch() {
			let vm = this;
			vm.$router.push({
				path: "/sales/search/drive/sw/" + vm.brand.id
			});
		}
	}
};
</script>